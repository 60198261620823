/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { HandleErrorApi, onCheckType, replaceAll, stringifyObjectValidateYup } from 'common';
import {
  CODE_TIME_OUT,
  ERROR_NETWORK_CODE,
  RESULT_CODE_PUSH_OUT,
  STATUS_TIME_OUT,
} from 'config/api';
import { ParamsNetwork, ResponseBase } from 'config/type';

import { translate } from '../utils';

const responseDefault: ResponseBase<any> = {
  code: -500,
  status: false,
  message: translate('error:have_error'),
  data: {},
  errorTx: stringifyObjectValidateYup({ keyT: 'error:have_error' }),
};

/**
 * Convert error api to error string (JSON.stringify). Then use useMessageYupTranslation hook to show message
 * {
  "success": false,
  "errors": "MSG_024",
  "message": "Bad Request",
  "optionFields": {
    "fieldName1": "phone_number",
    "fieldName2": "password"
  }
}
 */
const handleOptionFieldsToErrorTx = (error: Record<string, unknown>) => {
  if (typeof error.errors !== 'string') {
    return stringifyObjectValidateYup({
      keyT: (error?.message as string) ?? '',
    });
  }
  const optionsTx: Record<string, unknown> = {};
  if (
    error.optionFields &&
    typeof error.optionFields === 'object' &&
    Object.keys(error.optionFields).length > 0
  ) {
    Object.keys(error.optionFields).forEach(key => {
      optionsTx[key] = `field:${(error.optionFields as Record<string, unknown>)[key]}`;
    });
  }
  return stringifyObjectValidateYup({
    keyT: `msg:${error.errors}`,
    optionsTx: optionsTx,
  });
};

export const handleResponseAxios = <T = any>(res: AxiosResponse<any>): ResponseBase<T> => {
  if (res.data) {
    return {
      code: res.status,
      status: res.data.success,
      data: res?.data?.data ?? undefined,
      message: res.data.message,
      errorTx: handleOptionFieldsToErrorTx(res.data),
      optionFields: res.data.optionFields,
      msgCode: res.data.errors,
    };
  }
  return responseDefault;
};

const checkErrorResponse = (responseData: any) => {
  return (
    onCheckType(responseData.success, 'boolean') && onCheckType(responseData.message, 'string')
  );
};

export const handleErrorAxios = (error: AxiosError): ResponseBase<any> => {
  if (error.code === STATUS_TIME_OUT) {
    // timeout
    return HandleErrorApi(CODE_TIME_OUT);
  }
  if (error.response) {
    // if (error.response.status === 404 || error.response.status === 405)
    //   window.location.href = '/error';
    if (error.response.status === RESULT_CODE_PUSH_OUT) {
      return HandleErrorApi(RESULT_CODE_PUSH_OUT);
    } else {
      if (error.response.data && checkErrorResponse(error.response.data)) {
        return handleResponseAxios(error.response);
      }
      return HandleErrorApi(error.response.status);
    }
  }
  return HandleErrorApi(ERROR_NETWORK_CODE);
};

export const handlePath = (url: string, path: { [key: string]: string | number }) => {
  if (!path || Object.keys(path).length <= 0) {
    return url;
  }
  let resUrl = url;
  Object.keys(path).forEach(k => {
    resUrl = replaceAll(resUrl, `{${k}}`, String(path[k]));
    resUrl = replaceAll(resUrl, `:${k}`, String(path[k]));
  });
  return resUrl;
};

export const handleParameter = <T extends ParamsNetwork>(
  props: T,
  method: Method,
): AxiosRequestConfig => {
  const { url, body, path, params } = props;
  return {
    ...props,
    method,
    url: handlePath(url, path),
    data: body,
    params,
  };
};
