/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import { RegisterOptions } from 'react-hook-form';

import { AxiosRequestConfig } from 'axios';
import { TFunctionDetailedResult } from 'i18next';

import { ProviderLogin } from './api';
export interface ResponseBase<T = any> {
  code: number;

  message?: string | TFunctionDetailedResult<Object> | null;

  data?: T;

  status: boolean;

  errorTx?: string | Array<any>;

  msgCode?: string;

  optionFields?: Record<string, string>;
}

export interface ParamsNetwork {
  url: string;
  params?: any;
  path?: any;
  body?: any;
  headers?: AxiosRequestConfig['headers'];
}
export type HookFormRules = Exclude<
  RegisterOptions,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs'
>;
export type ValidationMap<T = any, Keys extends keyof T = keyof T> = {
  [K in Keys]-?: RegisterOptions;
};

export enum SLICE_NAME {
  APP = 'APP_',
  CRM = 'CRM_',
  LOGIN = 'LOGIN_',
  REGISTER = 'REGISTER_',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD_',
  HOME = 'HOME_',
  USER = 'USER_',
  MESSAGE = 'MESSAGE_',
  ROOM = 'ROOM_',
  NOTIFICATION = 'NOTIFICATION_',
}

export type ValidateMessageObject = {
  keyT: string;
  optionsTx?: any;
  options?: any;
};
export type TokenInformation = {
  token: string;
  refreshToken: string;
  providerLogin: ProviderLogin;
};

export type PaginationParams = {
  page: number;
  perPage: number;
};
