import { call, execFunc, getState, put, showErrorApi, takeLatest } from 'common';
import { ApiConstants, NetWorkResponseType, NetWorkService } from 'library/networking';
import { UserInfo } from 'model/home';
import { Unread } from 'model/messages';
import { Action } from 'redux';
import { appActions, userActions } from 'redux/action-slice';

export function* userSaga() {
  yield* takeLatest(userActions.onCreateUserAddress.type, onCreateUserAddress);
  yield* takeLatest(
    userActions.onCreateOrUpdateWelcomeMessage.type,
    onCreateOrUpdateWelcomeMessage,
  );
  yield* takeLatest(userActions.onGetWelcomeMessages.type, onGetWelcomeMessages);
  yield* takeLatest(userActions.onGetFriends.type, onGetFriendList);
  yield* takeLatest(userActions.onGetFriendsRequest.type, onGetFriendRequests);
  yield* takeLatest(userActions.onSendFriendRequest.type, onSendFriendRequest);
  yield* takeLatest(userActions.onRejectFriendRequest.type, onRejectFriendRequest);
  yield* takeLatest(userActions.onAcceptFriendRequest.type, onAcceptFriendRequest);
  yield* takeLatest(userActions.onUnFriendRequest.type, onUnFriendRequest);
  yield* takeLatest(userActions.onGetTotalUnread.type, onGetTotalUnread);
  yield* takeLatest(userActions.onAddOfficialAccount.type, onAddOfficialAccount);
  yield* takeLatest(userActions.onUpdateUserWalletBalances.type, onUpdateUserWalletBalances);
}

function* onCreateUserAddress(action: Action) {
  if (userActions.onCreateUserAddress.match(action)) {
    const { headers, onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.CREATE_USER_ADDRESS,
      body,
      headers,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    const { userInfo } = getState('app');
    if (!userInfo) {
      yield* put(appActions.onSetAddress(response.data?.wallet_address));
      yield* put(appActions.onSetUserInfo(response.data));
    }

    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onSendFriendRequest(action: Action) {
  if (userActions.onSendFriendRequest.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.SEND_FRIEND_REQUEST,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onAcceptFriendRequest(action: Action) {
  if (userActions.onAcceptFriendRequest.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.ACCEPT_FRIEND_REQUEST,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onUnFriendRequest(action: Action) {
  if (userActions.onUnFriendRequest.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.UNFRIEND_REQUEST,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onDeleteFriendRequest(action: Action) {
  if (userActions.onDeleteFriendRequest.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.UNFRIEND_REQUEST,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onRejectFriendRequest(action: Action) {
  if (userActions.onRejectFriendRequest.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.REJECT_FRIEND_REQUEST,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onGetFriendList(action: Action) {
  console.log(action, 'onGetFriendList=>action');
  if (userActions.onGetFriends.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo | UserInfo[]>>(NetWorkService.Get, {
      url: ApiConstants.GET_FRIEND_LIST,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onGetFriendRequests(action: Action) {
  if (userActions.onGetFriendsRequest.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Get, {
      url: ApiConstants.GET_FRIEND_REQUESTS,
    });
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onGetTotalUnread(action: Action) {
  if (userActions.onGetTotalUnread.match(action)) {
    const { params, onSucceeded, onFailed } = action.payload;
    const response = yield* call<NetWorkResponseType<Unread[]>>(NetWorkService.Get, {
      url: ApiConstants.GET_TOTAL_UNREAD,
      params,
    });
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Unread[]);
  }
}

function* onCreateOrUpdateWelcomeMessage(action: Action) {
  if (userActions.onCreateOrUpdateWelcomeMessage.match(action)) {
    const { headers, onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.CREATE_OR_UPDATE_WELCOME_MESSAGES,
      body,
      headers,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    const { userInfo } = getState('app');
    if (!userInfo) {
      yield* put(appActions.onSetAddress(response.data?.wallet_address));
      yield* put(appActions.onSetUserInfo(response.data));
    }

    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onUpdateUserWalletBalances(action: Action) {
  if (userActions.onUpdateUserWalletBalances.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.UPDATE_USER_WALLET_BALANCES,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }

    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onAddOfficialAccount(action: Action) {
  if (userActions.onAddOfficialAccount.match(action)) {
    const { headers, onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Post, {
      url: ApiConstants.ADD_OFFICIAL_ACCOUNT,
      body,
      headers,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }

    execFunc(onSucceeded, response.data as UserInfo);
  }
}

function* onGetWelcomeMessages(action: Action) {
  if (userActions.onGetWelcomeMessages.match(action)) {
    const { params, onSucceeded, onFailed } = action.payload;
    yield put(appActions.onStartProcess());

    const response = yield* call<NetWorkResponseType<Array<string>>>(NetWorkService.Get, {
      url: ApiConstants.GET_WELCOME_MESSAGES,
      params,
    });

    if (!response || !response.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? '');
      return;
    }
    console.log('API Response:', response);

    execFunc(onSucceeded, response.data as Array<string>);
  } else {
    console.log('error');
  }
}
