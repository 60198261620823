import { NetWorkService } from 'library/networking/service-dmtp-internal';
//import { NetWorkService as KeyService } from 'library/networking/service-key';

const URL = process.env.REACT_APP_API_URL || 'https://dmtp-backend-new.vinaweb.app';

const handleResponse = response => {
  return response;
};

const getXSocialAuthen = async params => {
  const res = await NetWorkService.Get({
    url: `${URL}/twitter/login-social`,
    params: params,
  });
  return handleResponse(res);
};
const getXSocialPoints = async () => {
  const res = await NetWorkService.Get({
    url: `${URL}/twitter/points`,
  });
  return handleResponse(res);
};
const logoutXSocial = async () => {
  const res = await NetWorkService.Get({
    url: `${URL}/twitter/logout-social`,
  });
  return handleResponse(res);
};
const func = {
  getXSocialAuthen,
  getXSocialPoints,
  logoutXSocial,
};

export default func;
