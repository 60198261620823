export const chainIdToChainName = (chainId: string) => {
  switch (chainId) {
    case '0x1':
      return 'Ethereum';
      break;
    case '0x89':
      return 'Polygon';
      break;
  }
  return;
};

export const getChainImageFromChainId = (chainId: string) => {
  switch (chainId) {
    case '0x1':
      return 'images/ethereum.png';
    case '0x89':
      return 'path/to/polygon.png';
    // Add paths for other chain images
    default:
      return 'path/to/default-chain.png';
  }
};
