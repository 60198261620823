import { createAction } from '@reduxjs/toolkit';
import { Notification, NotificationWithCount, ReadNotification } from 'model/notification';
import { IErrorResponse } from 'model/response';

import * as Actions from '../action-type';

const onGetList = createAction(
  Actions.GET_LIST_NOTIFICATION,
  (
    params: {
      page: number;
      numberPerPage: number;
      type: string;
    },
    onSucceeded: (data: NotificationWithCount) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);

const onRead = createAction(
  Actions.READ_NOTIFICATION,
  (
    body: ReadNotification,
    onSucceeded: (data: unknown) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onReadAll = createAction(
  Actions.READ_ALL_NOTIFICATION,
  (onSucceeded: (data: unknown) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

export const notificationActions = {
  onGetList,
  onRead,
  onReadAll,
};
