/* eslint-disable @typescript-eslint/no-empty-function */
import { initReactI18next } from 'react-i18next';

import { ENVConfig } from 'config/env';
import i18n, { InitOptions, LanguageDetectorAsyncModule, Resource } from 'i18next';

import { resources } from './locales';

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: (callback: (lng: string) => void) => {
    const userLanguage = window.localStorage.getItem('userLanguage');
    const browserLanguage = window.navigator.language;
    const language = userLanguage || browserLanguage.substring(0, 2);
    callback(language);
  },
  init: () => {},
  cacheUserLanguage: (lng: string) => {
    window.localStorage.setItem('userLanguage', lng);
  },
};

export const initOptionsI18n = (source: Resource): InitOptions => {
  return {
    fallbackLng: ENVConfig.DEFAULT_FALLBACK_LNG_I18n,
    resources: source,
    lng: window.localStorage.getItem('userLanguage') || ENVConfig.DEFAULT_LANG,
    ns: ['common'],
    defaultNS: 'common',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  };
};

export const languages = {
  en: { name: 'English', flag: 'https://flagcdn.com/us.svg' },
  ja: { name: '日本語', flag: 'https://flagcdn.com/jp.svg' },
};

export const getCurrentLanguage = (): string => {
  return window.localStorage.getItem('userLanguage') || 'en';
};

export const getMomentLocale = (): string => {
  const lang = getCurrentLanguage();
  return lang == 'en' ? 'en-au' : lang;
};

i18n.use(languageDetector).use(initReactI18next).init(initOptionsI18n(resources));

export default i18n;
