import { createAction } from '@reduxjs/toolkit';
import { Sticker, StickerPool } from 'model/create-stickers';
import {
  AuthorizationLink,
  InviteServer,
  MessageSign,
  Token,
  UploadMetaDataRequest,
  UserInfo,
} from 'model/home';
import { IErrorResponse } from 'model/response';
import { IStickerMarket } from 'model/sticker-market';
import { IStickerOwner } from 'model/sticker-owner';

import * as Actions from '../action-type/home';

const onGetUserInfo = createAction(
  Actions.GET_USER_INFO,
  (onSucceeded?: (data: UserInfo) => void) => ({
    payload: {
      onSucceeded,
    },
  }),
);
const onDiscordAuthorization = createAction(
  Actions.DISCORD_AUTHORIZATION,
  (
    params: { redirect_uri: string },
    onSucceeded: (data: AuthorizationLink) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      params,
      onFailed,
    },
  }),
);
const onUserCodeDiscord = createAction(
  Actions.USER_CODE_DISCORD,
  (params, onSucceeded: (data: string) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);
const onDiscordInviteServer = createAction(
  Actions.DISCORD_INVITE_SERVER,
  (onSucceeded: (data: InviteServer) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);
const onUploadMetaData = createAction(
  Actions.UPLOAD_METADATA,
  (params: UploadMetaDataRequest, onSucceeded: (data: string) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);
const onAddToPool = createAction(
  Actions.ADD_TO_POOL,
  (params: Sticker, onSucceeded: (data: Sticker) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);
const onUpdateToPool = createAction(
  Actions.UPDATE_TO_POOL,
  (params: Sticker, onSucceeded: (data: Sticker) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);
const onRemoveToPool = createAction(
  Actions.REMOVE_TO_POOL,
  (params: { cids: Array<string> }, onSucceeded: (data: string) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);
const onClearAllPool = createAction(
  Actions.UPLOAD_METADATA,
  (onSucceeded: (data: string) => void) => ({
    payload: {
      onSucceeded,
    },
  }),
);
const onGetPoolList = createAction(
  Actions.GET_POOL_LIST,
  (onSucceeded: (data: Array<StickerPool>) => void) => ({
    payload: {
      onSucceeded,
    },
  }),
);

const onGetListStickers = createAction(
  Actions.GET_STICKER_LIST,
  (
    onSucceeded: (data: Array<IStickerMarket>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetListOwnerStickers = createAction(
  Actions.GET_STICKER_OWNER_LIST,
  (
    onSucceeded: (data: Array<IStickerOwner>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetMessageSign = createAction(
  Actions.GET_MESSAGE_SIGN,
  (params: { address: string }, onSucceeded: (data: MessageSign) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);
const onGetTokens = createAction(
  Actions.GET_TOKENS,
  (onSucceeded: (data: Array<Token>) => void) => ({
    payload: {
      onSucceeded,
    },
  }),
);

const onDownload = createAction(
  Actions.DOWNLOAD_FILE,
  (params: { cid: string }, onSucceeded: (data: unknown) => void) => ({
    payload: {
      params,
      onSucceeded,
    },
  }),
);

export const homeActions = {
  onGetUserInfo,
  onDiscordAuthorization,
  onUserCodeDiscord,
  onDiscordInviteServer,
  onUploadMetaData,
  onAddToPool,
  onUpdateToPool,
  onRemoveToPool,
  onClearAllPool,
  onGetPoolList,
  onGetListStickers,
  onGetMessageSign,
  onGetTokens,
  onGetListOwnerStickers,
  onDownload,
};
