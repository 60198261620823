import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveToken } from 'common';
import { SLICE_NAME } from 'config/type';
import { remove } from 'lodash';
import { AppState, ITheme, PayloadSetToken, WalletWithPrivateKey } from 'model/app';
import { AuthenKey, UserInfo } from 'model/home';
import { WHITELIST_TYPE } from 'model/room.interface';

const initialAppState: AppState = {
  internetState: true,
  token: undefined,
  refreshToken: undefined,
  userInfo: undefined,
  authenKey: undefined,
  addressAccount: undefined,
  /**
   * default true to load app
   */
  loadingApp: true,
  showDialog: false,
  registrationAddress: undefined,
  discordCode: undefined,
  discordInviteLink: undefined,
  discordAuthorizeLink: undefined,
  isRegisted: false,
  connectedAccounts: [],
  chatSelectedAccount: undefined,
  reloadRoom: false,
  sessionExpired: false,
  reloadAccountList: false,
  privateKeys: [],
  dtmpPublicKey: '',
  reloadRoomInfo: false,
  isConnected: false, // maybe it false from init
  theme: 'dark',
  walletList: undefined,
  isWhitelist: false,
  whitelistType: WHITELIST_TYPE.ACCESS,
  isAgreeGenerateKey: false,
  maticPrice: 0,
};
const appSlice = createSlice({
  name: SLICE_NAME.APP,
  initialState: initialAppState,
  reducers: {
    onSetWalletList: (state, { payload }: PayloadAction<string | undefined>) => {
      state.walletList = payload;
    },
    setTheme: (state, { payload }: PayloadAction<ITheme>) => {
      state.theme = payload;
    },
    onSetInternet: (state, { payload }: PayloadAction<boolean>) => {
      state.internetState = payload;
    },
    onSetToken: (state, { payload }: PayloadAction<PayloadSetToken>) => {
      state.token = payload.access_token;
      saveToken({
        access_token: payload.access_token,
      });
    },
    onSetUserInfo: (state, { payload }: PayloadAction<UserInfo | undefined>) => {
      state.userInfo = payload;
      state.isConnected = true;
    },
    onSetAuthenKey: (state, { payload }: PayloadAction<AuthenKey | undefined>) => {
      state.authenKey = payload;
      state.isConnected = true;
    },
    onSetAddress: (state, { payload }: PayloadAction<string | undefined>) => {
      state.addressAccount = payload;
    },
    onSetRegistrationAddress: (state, { payload }: PayloadAction<string | undefined>) => {
      state.registrationAddress = payload;
    },
    onLoadApp: state => {
      state.loadingApp = true;
    },
    onGetProfile: () => {
      console.log('Trigger get profile');
    },
    onLoadAppEnd: state => {
      state.loadingApp = false;
    },
    onStartProcess: state => {
      state.showDialog = true;
    },
    onEndProcess: state => {
      state.showDialog = false;
    },
    onSetDiscordCode: (state, { payload }: PayloadAction<string | undefined>) => {
      state.discordCode = payload;
    },
    onSetDiscordInviteLink: (state, { payload }: PayloadAction<string | undefined>) => {
      state.discordInviteLink = payload;
    },
    onSetDiscordAuthorizeLink: (state, { payload }: PayloadAction<string | undefined>) => {
      state.discordAuthorizeLink = payload;
    },
    onSetRegisted: (state, { payload }: PayloadAction<boolean>) => {
      state.isRegisted = payload;
    },
    onSetConnectedAccounts: (state, { payload }: PayloadAction<{ accounts: string[] }>) => {
      state.connectedAccounts = payload.accounts;
      state.action = undefined;
    },
    onSetChatSelectedAccount: (state, { payload }: PayloadAction<string | undefined>) => {
      state.chatSelectedAccount = payload;
    },
    onSetReloadRoom: (state, { payload }: PayloadAction<boolean>) => {
      state.reloadRoom = payload;
    },
    onSetReloadRoomInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.reloadRoomInfo = payload;
    },
    onSetReloadAccountList: (state, { payload }: PayloadAction<boolean>) => {
      state.reloadAccountList = payload;
    },
    onShowSessionExpired: (state, { payload }: PayloadAction<boolean>) => {
      state.sessionExpired = payload;
    },
    setPrivateKey: (state, { payload }: PayloadAction<WalletWithPrivateKey>) => {
      const currentPrivateKeys = state.privateKeys;
      const removes = remove(
        currentPrivateKeys,
        pair => pair.wallet_address !== payload.wallet_address,
      );

      state.privateKeys = state.privateKeys ? removes.concat(payload) : [{ ...payload }];
    },
    setDtmpPublicKey: (state, { payload }: PayloadAction<string>) => {
      state.dtmpPublicKey = payload;
    },
    setTriggerAction: (state, { payload }: PayloadAction<string | undefined>) => {
      state.action = payload;
    },
    setIsConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnected = payload;
    },
    setIsWhitelist: (state, { payload }: PayloadAction<boolean>) => {
      state.isWhitelist = payload;
    },
    setWhitelistType: (state, { payload }: PayloadAction<WHITELIST_TYPE>) => {
      state.whitelistType = payload;
    },
    setIsAgreeGenerateKey: (state, { payload }: PayloadAction<boolean>) => {
      state.isAgreeGenerateKey = payload;
    },
    setMaticPrice: (state, { payload }: PayloadAction<number>) => {
      state.maticPrice = payload;
    },
    resetKeepAccount: state => {
      return {
        ...initialAppState,
        addressAccount: state.addressAccount,
        discordInviteLink: state.discordInviteLink,
        discordAuthorizeLink: state.discordAuthorizeLink,
        privateKeys: state.privateKeys || [],
      };
    },
    reset: state => {
      return {
        ...initialAppState,
        discordInviteLink: state.discordInviteLink,
        discordAuthorizeLink: state.discordAuthorizeLink,
        privateKeys: state.privateKeys || [],
      };
    },
  },
});
export const { reducer: appReducer, actions: appActions } = appSlice;
