import { SLICE_NAME } from 'config/type';

export const GET_USER_INFO = SLICE_NAME.HOME + 'GET_USER_INFO';
export const DISCORD_AUTHORIZATION = SLICE_NAME.HOME + 'DISCORD_AUTHORIZATION';
export const USER_CODE_DISCORD = SLICE_NAME.HOME + 'USER_CODE_DISCORD';
export const DISCORD_INVITE_SERVER = SLICE_NAME.HOME + 'DISCORD_INVITE_SERVER';
export const UPLOAD_METADATA = SLICE_NAME.HOME + 'UPLOAD_METADATA';
export const GET_POOL_LIST = SLICE_NAME.HOME + 'GET_POOL_LIST';
export const CLEAR_ALL_POOL = SLICE_NAME.HOME + 'CLEAR_ALL_POOL';
export const REMOVE_TO_POOL = SLICE_NAME.HOME + 'REMOVE_TO_POOL';
export const UPDATE_TO_POOL = SLICE_NAME.HOME + 'UPDATE_TO_POOL';
export const ADD_TO_POOL = SLICE_NAME.HOME + 'ADD_TO_POOL';
export const GET_STICKER_LIST = SLICE_NAME.HOME + 'GET_STICKER_LIST';
export const GET_MESSAGE_SIGN = SLICE_NAME.HOME + 'GET_MESSAGE_SIGN';
export const GET_TOKENS = SLICE_NAME.HOME + 'GET_TOKENS';
export const GET_STICKER_OWNER_LIST = SLICE_NAME.HOME + 'GET_STICKER_OWNER_LIST';
export const DOWNLOAD_FILE = SLICE_NAME.HOME + 'DOWNLOAD_FILE';
