import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckIcon, CloseIcon, EmailIcon, WarningIcon } from '@chakra-ui/icons';
/* eslint-disable import/order */
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
/* eslint-enable import/order */

import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import { dispatch, MAX_ACCEPT_FILE_SIZE, useSelector } from 'common';
import { encryptMessage, resizeImageAsync } from 'common/utils/core';
import { TOKEN_KEY_HEADER } from 'config/api';
import { MSG_CRM_LENGTH, MSG_CRM_SHOW_ERROR_LENGTH } from 'config/field-length';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t, translate } from 'library/utils';
import { flatten, isEqual, filter as uniqBy } from 'lodash';
import { Conditiontypes, Content, CrmMessages, ErrorType, MessageRoom } from 'model/messages';
import { MdAttachFile } from 'react-icons/md';
import { crmActions, roomActions, userActions } from 'redux/action-slice';
import ROUTES from 'routes/constant';
import { getSharedKey } from 'services/crypto.service';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import {
  DefaultLeaf,
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { useAccount } from 'wagmi';

import { ChainType, FriendWithRoomAndKey } from '../../../model/user.interface';
import {
  clearEditor,
  decorator,
  imageSrcRegex,
  removeImageTag,
} from '../../messages/message-content/editor';
import { replaceBrAtEndOfString, serialize } from '../../messages/message-content/handler';
import { insertImage, withImages } from '../../messages/message-content/images';
import WithSubnavigation from '../navbar';

type CustomUploadProps = UploadProps & {
  onSuccess: (result: { url: string; file: File }) => void;
};

enum DataType {
  TOTAL_TX = 'totalTx',
  MONTHLY_TX = 'monthlyTx',
  LOGIN_TIMES = 'loginTimes',
  LAST_LOGIN = 'lastLogin',
  VALUES = 'values',
}

interface FriendsWithRoomAndSharedKey extends FriendWithRoomAndKey {
  sharedKey: string;
}

const FriendList = () => {
  const INIT_EDITOR_CONTENT: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }];
  const [fileList, setFileList] = useState<File[]>([]);
  const [editor] = useState(() => withImages(withReact(withHistory(createEditor()))));
  const [friends, setFriends] = useState<FriendWithRoomAndKey[]>([]);
  const [friendsWithKeys, setFriendsWithKeys] = useState<FriendsWithRoomAndSharedKey[]>([]);
  const { address } = useAccount();
  const [selected, setSelected] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '', chain: '' });
  const [messages, setMessages] = useState<Array<Descendant[]>>([INIT_EDITOR_CONTENT]);
  const editors = useMemo(() => messages.map(() => withReact(createEditor())), [messages.length]);
  const [messageTag, setMessageTag] = useState('');
  const [activeModal, setActiveModal] = useState<number>(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [isValidateError, setIsValidateError] = useState<boolean>(false);
  const [indexError, setIndexError] = useState<number[]>([]);
  const [errorType, setErrorType] = useState<ErrorType>(ErrorType.MESSAGE_TAG);
  // const [loginInfo, setLoginInfo] = useState<any>({});

  const { privateKeys, isConnected: isUserConnected, token } = useSelector(x => x.app);

  const getAllFrineds = useCallback(() => {
    dispatch(userActions.onGetFriends({ crm: true }, data => onGetUserFriendsSuccess(data)));
  }, []);
  useEffect(() => {
    if (address) {
      getAllFrineds();
    }
  }, [address, getAllFrineds]);

  // useEffect(() => {
  //   if (address) {
  //     getLoginInfo();
  //   }
  // }, [address]);

  const updateUserInfo = useCallback(async () => {
    const walletAddresses: string[] = friends.map(friend => friend.wallet_address);
    dispatch(userActions.onUpdateUserWalletBalances(onSuccess));
  }, [friends]);
  useEffect(() => {
    if (friends.length > 0) {
      updateUserInfo();
    }
  }, [friends, updateUserInfo]);

  const getAllSharedKeys = useCallback(() => {
    if (!address) return;
    const walletPrivateKey = privateKeys.find(p =>
      isEqual(p.wallet_address, address.toLowerCase()),
    );
    if (!walletPrivateKey) {
      return;
    }
    const data: FriendsWithRoomAndSharedKey[] = [];
    friends.forEach(friend => {
      const sharedKey = getSharedKey(walletPrivateKey.privateKey, friend.dmtp_pub_key);
      data.push({ ...friend, sharedKey });
    });
    setFriendsWithKeys(data);
  }, [address, friends, privateKeys]);
  useEffect(() => {
    if (friends.length > 0) {
      getAllSharedKeys();
    }
  }, [friends, getAllSharedKeys]);

  const handleCheck = (wallet_address: string) => {
    if (selected.includes(wallet_address)) {
      setSelected(selected.filter(addr => addr !== wallet_address));
    } else {
      setSelected([...selected, wallet_address]);
    }
  };

  const handleCheckAll = () => {
    if (selected.length === friends.length) {
      setSelected([]);
    } else {
      setSelected(friends.map(friend => friend.wallet_address));
    }
  };

  const handleMessageTagChange = (e: any) => {
    const inputValue = e.target.value;
    setMessageTag(inputValue);
  };

  // const getLoginInfo = async () => {
  //   const res = await NetWorkService.Get({
  //     url: ApiConstants.GET_LOGIN_INFO,
  //   });
  //   if (!res) return;
  //   setLoginInfo(res.data);
  // };

  const onSuccess = (data: any) => {
    console.log(data, 'data');
  };

  const convertErrorMessage = () => {
    switch (errorType) {
      case ErrorType.MESSAGE_TAG:
        return translate('app:message_tag_alert').toString();
      case ErrorType.MESSAGE:
        return translate('app:message_alert').toString();
      case ErrorType.DESTINATION:
        return translate('app:destination_alert').toString();
      case ErrorType.MESSAGE_LONG:
        return translate('app:message_too_long_alert', {
          number: MSG_CRM_SHOW_ERROR_LENGTH,
        }).toString();
    }
  };

  const renderLeaf = (props: RenderLeafProps) => {
    const { leaf, children, attributes } = props;
    let childrenLeaf = children;
    if (leaf.decoration === 'link') {
      childrenLeaf = (
        <a
          {...attributes}
          className="text-blue-400 cursor-pointer"
          href={leaf.text}
          onClick={() => {
            window.open(leaf.text, '_blank', 'noopener,noreferrer');
          }}>
          {children}
        </a>
      );
    }
    return <DefaultLeaf {...props}>{childrenLeaf}</DefaultLeaf>;
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    ReactEditor.focus(editor);
    for (const file of event.dataTransfer.files) {
      setFileList((fileList: any) => uniqBy(fileList.concat(file), f => f.name));
    }
    return false;
  };

  const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const { files } = event.clipboardData;

    const newFiles: File[] = [];

    if (files && files.length > 0) {
      for (const file of files) {
        newFiles.push(file);
      }

      setFileList((fileList: any) => uniqBy(fileList.concat(newFiles), f => f.name));
    }
  };

  const renderElement = (props: RenderElementProps) => {
    switch (props.element.type) {
      case 'image':
        return props.element.url.length > 0 ? (
          <div {...props.attributes}>
            <div>
              <div>
                <img
                  width={40}
                  height={40}
                  {...props.attributes}
                  src={(props.element as any).url}
                  alt={props.element.name}
                  style={{ height: 40, objectFit: 'contain' }}
                />
              </div>
              {props.children}
            </div>
          </div>
        ) : (
          <></>
        );
      default:
        return (
          <div className="break-all" {...props.attributes}>
            {props.children}
          </div>
        );
    }
  };

  const addMessage = () => {
    setMessages([...messages, INIT_EDITOR_CONTENT]);
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    console.log(event);
    return false;
  };

  const serializeNode = (nodes: Descendant[]) => {
    return nodes.map(n => serialize(n)).join('');
  };

  const onSendFailed = (error: unknown) => {
    setActiveModal(4);
    console.log(error);
  };

  const onGetUserFriendsSuccess = (data: any) => {
    setFriends(data);
  };

  const validateMessage = () => {
    setIsValidateError(false);
    if (!messageTag) {
      setErrorType(ErrorType.MESSAGE_TAG);
      setIsValidateError(true);
      return;
    }
    const texts = messages.map(m => {
      const message = serializeNode(m);
      return replaceBrAtEndOfString(message);
    });
    const tmp_arr: number[] = [];
    for (let i = 0; i < texts.length; i++) {
      if (!texts[i]) {
        tmp_arr.push(i);
      }
    }
    if (tmp_arr?.length) {
      setErrorType(ErrorType.MESSAGE);
      setIsValidateError(true);
      setIndexError(tmp_arr);
      return;
    }

    for (let i = 0; i < texts.length; i++) {
      const text = texts[i];
      if (text && text.length > MSG_CRM_LENGTH) {
        tmp_arr.push(i);
      }
    }
    if (tmp_arr?.length) {
      setErrorType(ErrorType.MESSAGE_LONG);
      setIsValidateError(true);
      setIndexError(tmp_arr);
      return;
    }
    if (!selected) {
      setErrorType(ErrorType.DESTINATION);
      setIsValidateError(true);
      return;
    }
    onOpen();
  };

  const onSendToCheckedAddressNew = async () => {
    setActiveModal(2);
    try {
      messages.forEach((value, msgIndex) => {
        let message = serializeNode(value);
        message = replaceBrAtEndOfString(message);
        let urls: string[] = [];

        const associatedFile = fileList[msgIndex];
        if (associatedFile) {
          const srcs = message.match(imageSrcRegex) || [];
          const regexSrc = /"(.*?)"/gim;

          const maps = srcs.map(str => str.match(regexSrc));

          urls = (flatten(maps) as string[]).map(url => url.replace(/"/g, ''));
        }

        const selectedFriends: FriendsWithRoomAndSharedKey[] = [];

        selected.forEach(wallet_address => {
          const friend = friendsWithKeys.find(friend => friend.wallet_address === wallet_address);
          if (friend) {
            selectedFriends.push(friend);
          }
        });

        message = removeImageTag(message);

        const roomIds = selectedFriends.map(friend => friend.room_id);
        const messageDatas = selectedFriends.map(friend =>
          encryptMessage(
            {
              content: message,
              images: urls,
            },
            friend.sharedKey,
          ),
        );
        const batchMessageSend = {
          is_forwarded: false,
          message_id_reply: '',
          room_ids: roomIds,
          message_datas: messageDatas,
        };
        dispatch(
          roomActions.onSendBatchMessage(
            { ...batchMessageSend, is_promotion: false, crm: true },
            onSendBatchSuccess,
            onSendFailed,
          ),
        );
      });
    } catch (error) {
      setActiveModal(4);
    }
  };

  const onSendBatchSuccess = (data: MessageRoom[]) => {
    const messageIds = data.map(d => d._id);
    setActiveModal(3);
    onCreateCRMMessage(messageIds);
    return data;
  };

  const onCreateCRMMessage = async (messageIds: string[]) => {
    const contents = messages.map((value, msgIndex): Content => {
      let message = serializeNode(value);
      message = replaceBrAtEndOfString(message);

      let urls: string[] = [];
      const associatedFile = fileList[msgIndex];
      if (associatedFile) {
        const srcs = message.match(imageSrcRegex) || [];
        const regexSrc = /"(.*?)"/gim;
        const maps = srcs.map(str => str.match(regexSrc));
        urls = (flatten(maps) as string[]).map(url => url.replace(/"/g, ''));
      }
      message = removeImageTag(message);
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const text = doc.body.textContent || '';

      return {
        text,
        images: urls,
      };
    });
    const conditions = [{ type: Conditiontypes.SELECT, value: '', chain: '0', range: 0 }];
    const createCRMMessage = {
      tag: messageTag,
      content: contents,
      conditions: conditions,
      destination_address_list: selected,
      message_ids: messageIds,
    };
    dispatch(
      crmActions.onCreateCrmMessages({ ...createCRMMessage }, onCreateSuccess, onCreateFailed),
    );
  };

  const onCreateSuccess = (data: CrmMessages) => {
    setFileList([]);
    clearEditor(editor);
    setMessages([INIT_EDITOR_CONTENT]);
  };

  const onCreateFailed = (error: unknown) => {
    console.log(error);
  };

  const handleSort = (columnKey: string, chain: string) => {
    let direction = 'descending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key: columnKey, direction, chain });
  };

  const sortedFriends = useMemo(() => {
    const sortableFriends = [...friends];
    if (sortConfig !== null) {
      sortableFriends.sort((a, b) => {
        const totalTxA =
          a.blockchain?.ethereum?.transactions?.gross + a.blockchain?.polygon?.transactions?.gross;
        const totalTxB =
          b.blockchain?.ethereum?.transactions?.gross + b.blockchain?.polygon?.transactions?.gross;
        const monthlyTxA =
          a.blockchain?.ethereum?.transactions?.month + a.blockchain?.polygon?.transactions?.month;
        const monthlyTxB =
          b.blockchain?.ethereum?.transactions?.month + b.blockchain?.polygon?.transactions?.month;
        const grossBalanceA = a.blockchain?.values?.value || 0;
        const grossBalanceB = b.blockchain?.values?.value || 0;

        let aValue;
        let bValue;

        if (sortConfig.key === DataType.TOTAL_TX) {
          switch (sortConfig.chain) {
            case ChainType.ALL:
              aValue = totalTxA;
              bValue = totalTxB;
              break;
            case ChainType.ETHEREUM:
              aValue = a.blockchain?.ethereum?.transactions?.gross;
              bValue = b.blockchain?.ethereum?.transactions?.gross;
              break;
            case ChainType.POLYGON:
              aValue = a.blockchain?.polygon?.transactions?.gross;
              bValue = b.blockchain?.polygon?.transactions?.gross;
              break;
          }
        } else if (sortConfig.key === DataType.MONTHLY_TX) {
          switch (sortConfig.chain) {
            case ChainType.ALL:
              aValue = monthlyTxA;
              bValue = monthlyTxB;
              break;
            case ChainType.ETHEREUM:
              aValue = a.blockchain?.ethereum?.transactions?.month;
              bValue = b.blockchain?.ethereum?.transactions?.month;
              break;
            case ChainType.POLYGON:
              aValue = a.blockchain?.polygon?.transactions?.month;
              bValue = b.blockchain?.polygon?.transactions?.month;
              break;
          }
          // } else if (sortConfig.key === DataType.LOGIN_TIMES) {
          //   aValue = loginInfo[a.wallet_address]?.login_times || 0;
          //   bValue = loginInfo[b.wallet_address]?.login_times || 0;
          // } else if (sortConfig.key === DataType.LAST_LOGIN) {
          //   aValue = new Date(loginInfo[a.wallet_address]?.last_login || 0).getTime();
          //   bValue = new Date(loginInfo[b.wallet_address]?.last_login || 0).getTime();
        } else if (sortConfig.key === DataType.VALUES) {
          switch (sortConfig.chain) {
            case ChainType.ALL:
              aValue = grossBalanceA;
              bValue = grossBalanceB;
              break;
            case ChainType.ETHEREUM:
              aValue = a.blockchain?.ethereum?.values;
              bValue = b.blockchain?.ethereum?.values;
              break;
            case ChainType.POLYGON:
              aValue = a.blockchain?.polygon?.values;
              bValue = b.blockchain?.polygon?.values;
              break;
          }
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableFriends;
  }, [friends, sortConfig]);

  const removeMessage = (index: number) => {
    if (messages.length > 1) {
      const newMessages = [...messages];
      newMessages.splice(index, 1);
      setMessages(newMessages);
    } else {
      setMessages([INIT_EDITOR_CONTENT]);
    }
  };

  const handleMessageChange = (index: number, newValue: Descendant[]) => {
    const newMessages = [...messages];
    newMessages[index] = newValue;
    setMessages(newMessages);
  };

  const hasImageElement = (nodes: Descendant[]): boolean => {
    return nodes.some(node => (node as any).type === 'image');
  };

  const getUploadProps = (message: Descendant[], index: number): CustomUploadProps => ({
    name: 'imageFiles',
    fileList: [],
    headers: {
      [TOKEN_KEY_HEADER]: token ?? '',
      address: '',
    },
    showUploadList: false,
    multiple: true,
    onSuccess: res => {
      const editorInstance = editors[index];
      insertImage(editorInstance, res.url, res.file.name);
      const newValue = editorInstance.children;
      const newMessages = [...messages];
      newMessages[index] = newValue;
      setMessages(newMessages);
      setFileList((fileList: any) => uniqBy(fileList.concat(res.file), f => f.name));
    },
    customRequest: async ({ onSuccess, file }) => {
      const formData = new FormData();
      let fileHandle = file as File;
      if (fileHandle.size > MAX_ACCEPT_FILE_SIZE) {
        fileHandle = await resizeImageAsync(fileHandle);
      }
      formData.append('imageFiles', fileHandle);
      const response = await NetWorkService.PostFormData<string[]>({
        url: ApiConstants.UPLOAD_IMAGES,
        body: formData,
      });

      if (response?.data) {
        onSuccess?.({
          url: response.data[0],
          file,
        });
      }
    },
    accept: 'image/*',
    beforeUpload: file => {
      const serializedMessage = serializeNode(message);
      return !serializedMessage.includes(file.name);
    },
  });

  const MessageEditor = ({ editor, message, index, onMessageChange, children }: any) => {
    return (
      <Box
        style={{ border: indexError?.includes(index) ? '3px solid #ff0000' : '' }}
        textAlign={'left'}
        mx={10}
        my={4}
        borderRadius="md"
        boxShadow="0 0 5px rgba(50, 20, 100, 0.8)"
        overflow="hidden">
        <Box
          bgGradient="linear(to-l, #5497cc, #7c4998)"
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          h={'40px'}>
          <Upload {...getUploadProps(message, index)}>
            <IconButton
              aria-label="Attach file"
              icon={<MdAttachFile size={20} />}
              variant="ghost"
              color="white"
              colorScheme="purple"
              _hover={{
                bg: 'purple.700',
              }}
            />
          </Upload>
          {children}
          <IconButton
            size={'sm'}
            aria-label="Remove message"
            icon={<CloseIcon />}
            variant="ghost"
            color="white"
            _hover={{
              bg: '#5497cc',
            }}
            onClick={() => removeMessage(index)}
          />
        </Box>

        <Divider orientation="horizontal" />

        <Slate
          editor={editor}
          initialValue={message}
          onChange={newValue => {
            const newMessages = [...messages];
            newMessages[index] = newValue;
            setMessages(newMessages);
          }}>
          <Editable
            readOnly={hasImageElement(message)}
            decorate={decorator}
            onPaste={e => onPaste(e)}
            renderLeaf={renderLeaf}
            onDragStart={onDragStart}
            renderElement={renderElement}
            onDrop={e => onDrop(e)}
            placeholder={hasImageElement(message) ? '' : $t('messages:placeholder_message')}
            className="border-0 w-full !min-h-[100px] text-black max-h-[500px] mt-4 pl-4 text-[13px] font-normal leading-5 tracking-[0.04em] overflow-y-auto overflow-x-hidden focus:outline-none"
          />
        </Slate>
      </Box>
    );
  };

  return (
    <section className={'w-full relative'}>
      <Box className={'w-full min-h-screen bg-white p-5'}>
        <WithSubnavigation />
        <Stack gap={8} className="pb-6" textAlign={'center'} justifyContent={'center'}>
          <Heading
            py={5}
            bgClip="text"
            bgGradient="linear(to-l, #7c4998, #5497cc)"
            fontSize={['4xl', '5xl']}
            fontWeight="extrabold"
            textTransform="uppercase"
            textAlign="center"
            transition="all 0.2s ease-in-out">
            DMTP for Biz - {translate('app:friend_list').toString()}
          </Heading>
          <Tabs size="md" colorScheme="purple">
            <TabList>
              <Tab>All</Tab>
              <Tab>Ethereum</Tab>
              <Tab>Polygon</Tab>
              <Tab isDisabled>BSC</Tab>
              <Tab isDisabled>Astar</Tab>
              <Tab isDisabled>Oasys</Tab>
            </TabList>
            <TabPanels bg="white">
              <TabPanel>
                <TableContainer>
                  <Flex justifyContent="flex-end">
                    <Button w={'100px'} size={'sm'} onClick={handleCheckAll}>
                      {translate('app:select_all').toString()}
                    </Button>
                  </Flex>
                  {friends.length > 0 ? (
                    <Table size="sm" variant="simple" mt={5} colorScheme="purple">
                      <Thead fontSize={'20px'}>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Wallet Addresses</Th>
                          <Th
                            onClick={() => handleSort(DataType.TOTAL_TX, ChainType.ALL)}
                            cursor={'pointer'}>
                            Total Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.MONTHLY_TX, ChainType.ALL)}
                            cursor={'pointer'}>
                            Monthly Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.VALUES, ChainType.ALL)}
                            cursor={'pointer'}>
                            Wallet Balance
                          </Th>
                          {/* <Th onClick={() => handleSort('loginTimes')} cursor={'pointer'}>
                            Login Times
                          </Th>
                          <Th onClick={() => handleSort('lastLogin')} cursor={'pointer'}>
                            Last Login
                          </Th> */}
                          <Th>Check</Th>
                        </Tr>
                      </Thead>
                      {sortedFriends.map((friend, index) => (
                        <Tbody>
                          <Tr key={index}>
                            <Td>{friend.name}</Td>
                            <Td>{friend.wallet_address}</Td>
                            <Td>
                              {friend.blockchain?.ethereum?.transactions?.gross +
                                friend.blockchain?.polygon?.transactions?.gross}
                            </Td>
                            <Td>
                              {friend.blockchain?.ethereum?.transactions?.month +
                                friend.blockchain?.polygon?.transactions?.month}
                            </Td>
                            <Td>
                              ${' '}
                              {new Intl.NumberFormat('en-US').format(
                                Number(friend.blockchain?.values?.value.toFixed(2) || 0),
                              ) || 0}
                            </Td>
                            {/* <Td>
                              {loginInfo[friend.wallet_address]
                                ? loginInfo[friend.wallet_address].login_times
                                : 0}
                            </Td>
                            <Td>
                              {loginInfo[friend.wallet_address]
                                ? formatDateString(loginInfo[friend.wallet_address].last_login)
                                : 0}
                            </Td> */}
                            <Td>
                              <Checkbox
                                colorScheme="purple"
                                onChange={() => handleCheck(friend.wallet_address)}
                                isChecked={selected.includes(friend.wallet_address)}
                                _focusVisible={{
                                  outline: 'none',
                                }}
                              />
                            </Td>
                          </Tr>
                        </Tbody>
                      ))}
                    </Table>
                  ) : (
                    <p>No friends available</p>
                  )}
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <Flex justifyContent="flex-end">
                    <Button w={'100px'} size={'sm'} onClick={handleCheckAll}>
                      {translate('app:select_all').toString()}
                    </Button>
                  </Flex>
                  {friends.length > 0 ? (
                    <Table size="sm" variant="simple" mt={5} colorScheme="purple">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Wallet Addresses</Th>
                          <Th
                            onClick={() => handleSort(DataType.TOTAL_TX, ChainType.ETHEREUM)}
                            cursor={'pointer'}>
                            Total Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.MONTHLY_TX, ChainType.ETHEREUM)}
                            cursor={'pointer'}>
                            Monthly Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.VALUES, ChainType.ETHEREUM)}
                            cursor={'pointer'}>
                            Wallet Balance
                          </Th>
                          {/* <Th onClick={() => handleSort('loginTimes')} cursor={'pointer'}>
                            Login Times
                          </Th>
                          <Th onClick={() => handleSort('lastLogin')} cursor={'pointer'}>
                            Last Login
                          </Th> */}
                          <Th>Check</Th>
                        </Tr>
                      </Thead>
                      {sortedFriends.map((friend, index) => (
                        <Tbody>
                          <Tr key={index}>
                            <Td>{friend.name}</Td>
                            <Td>{friend.wallet_address}</Td>
                            <Td>{friend.blockchain?.ethereum?.transactions?.gross}</Td>
                            <Td>{friend.blockchain?.ethereum?.transactions?.month}</Td>
                            <Td>
                              ${' '}
                              {new Intl.NumberFormat('en-US').format(
                                Number(friend.blockchain?.ethereum?.values.toFixed(2) || 0),
                              ) || 0}
                            </Td>
                            {/* <Td>
                              {loginInfo[friend.wallet_address]
                                ? loginInfo[friend.wallet_address].login_times
                                : 0}
                            </Td>
                            <Td>
                              {loginInfo[friend.wallet_address]
                                ? formatDateString(loginInfo[friend.wallet_address].last_login)
                                : 0}
                            </Td> */}
                            <Td>
                              <Checkbox
                                colorScheme="purple"
                                onChange={() => handleCheck(friend.wallet_address)}
                                isChecked={selected.includes(friend.wallet_address)}
                                _focusVisible={{
                                  outline: 'none',
                                }}
                              />
                            </Td>
                          </Tr>
                        </Tbody>
                      ))}
                    </Table>
                  ) : (
                    <p>No friends available</p>
                  )}
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <Flex justifyContent="flex-end">
                    <Button w={'100px'} size={'sm'} onClick={handleCheckAll}>
                      {translate('app:select_all').toString()}
                    </Button>
                  </Flex>
                  {friends.length > 0 ? (
                    <Table size="sm" variant="simple" mt={5} colorScheme="purple">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Wallet Addresses</Th>
                          <Th
                            onClick={() => handleSort(DataType.TOTAL_TX, ChainType.POLYGON)}
                            cursor={'pointer'}>
                            Total Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.MONTHLY_TX, ChainType.POLYGON)}
                            cursor={'pointer'}>
                            Monthly Tx
                          </Th>
                          <Th
                            onClick={() => handleSort(DataType.VALUES, ChainType.POLYGON)}
                            cursor={'pointer'}>
                            Wallet Balance
                          </Th>
                          {/* <Th onClick={() => handleSort('loginTimes')} cursor={'pointer'}>
                            Login Times
                          </Th>
                          <Th onClick={() => handleSort('lastLogin')} cursor={'pointer'}>
                            Last Login
                          </Th> */}
                          <Th>Check</Th>
                        </Tr>
                      </Thead>
                      {sortedFriends.map((friend, index) => (
                        <Tbody>
                          <Tr key={index}>
                            <Td>{friend.name}</Td>
                            <Td>{friend.wallet_address}</Td>
                            <Td>{friend.blockchain?.polygon?.transactions?.gross}</Td>
                            <Td>{friend.blockchain?.polygon?.transactions?.month}</Td>
                            <Td>
                              ${' '}
                              {new Intl.NumberFormat('en-US').format(
                                Number(friend.blockchain?.polygon?.values.toFixed(2) || 0),
                              ) || 0}
                            </Td>
                            {/* <Td>
                              {loginInfo[friend.wallet_address]
                                ? loginInfo[friend.wallet_address].login_times
                                : 0}
                            </Td>
                            <Td>
                              {loginInfo[friend.wallet_address]
                                ? formatDateString(loginInfo[friend.wallet_address].last_login)
                                : 0}
                            </Td> */}
                            <Td>
                              <Checkbox
                                colorScheme="purple"
                                onChange={() => handleCheck(friend.wallet_address)}
                                isChecked={selected.includes(friend.wallet_address)}
                                _focusVisible={{
                                  outline: 'none',
                                }}
                              />
                            </Td>
                          </Tr>
                        </Tbody>
                      ))}
                    </Table>
                  ) : (
                    <p>No friends available</p>
                  )}
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Center>
            <Stack w="800px">
              <Text mt={4} textAlign={'left'}>
                {translate('app:message_tag').toString()} ※
                {translate('app:message_tag_asterisk').toString()}
              </Text>
              <Input
                mt={4}
                mb={10}
                placeholder={translate('app:message_tag_example').toString()}
                onChange={handleMessageTagChange}
                _focusVisible={{
                  borderColor: 'purple.800',
                }}></Input>
              <div>
                {messages.map((message, index) => (
                  <MessageEditor
                    key={index}
                    editor={editors[index]}
                    message={message}
                    index={index}
                    onMessageChange={handleMessageChange}
                  />
                ))}
                {messages.length < 3 && (
                  <Box textAlign={'left'}>
                    <Button
                      mt={4}
                      color="purple.400"
                      backgroundColor="white"
                      border="1px"
                      borderColor="purple.500"
                      ml="40px"
                      w={150}
                      size="sm"
                      onClick={addMessage}
                      _hover={{
                        bg: 'purple.400',
                        color: 'white',
                      }}
                      _active={{
                        bg: 'purple.200',
                      }}
                      transition="all 0.3s ease-in-out">
                      {translate('app:add_message').toString()} +
                    </Button>
                  </Box>
                )}
              </div>
            </Stack>
          </Center>

          {isValidateError && (
            <Center>
              <Text mt={2} color="red" fontWeight={'bold'} textAlign={'left'}>
                {convertErrorMessage()}
              </Text>
            </Center>
          )}

          <Center>
            <Button
              mt={8}
              w="200px"
              onClick={validateMessage}
              bgGradient="linear(to-l, #7c4998, #5497cc)"
              color="white"
              _hover={{
                bgGradient: 'linear(to-r, #5497cc, #7c4998)',
                boxShadow: '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
              }}
              _active={{
                bgGradient: 'linear(to-r, #7c4998, #5497cc)',
                boxShadow:
                  'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
              }}
              boxShadow="0 0 8px rgba(124, 73, 152, 0.6), 0 0 12px rgba(84, 151, 204, 0.6)"
              borderRadius="md"
              fontWeight="bold"
              size="md"
              // rightIcon={<SendIcon />}
              transition="all 0.2s ease-in-out">
              {translate('app:send_message').toString()}
            </Button>
          </Center>
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />

            {activeModal === 1 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:confirmation').toString()}
                </Heading>
                <ModalBody>
                  <Text>
                    {/* <Text as="span" mx={2} fontSize="md" fontWeight="bold" color="purple.600">
                      {selected.length}
                    </Text> */}
                    {translate('app:sending_confirmation_message', {
                      number: selected.length,
                    }).toString()}
                  </Text>
                  <Center mt={10}>
                    <Flex>
                      <Button mr={3} onClick={onClose}>
                        {translate('app:back').toString()}
                      </Button>
                      <Button
                        onClick={onSendToCheckedAddressNew}
                        bgGradient="linear(to-l, #7c4998, #5497cc)"
                        color="white"
                        _hover={{
                          bgGradient: 'linear(to-r, #5497cc, #7c4998)',
                          boxShadow:
                            '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
                        }}
                        _active={{
                          bgGradient: 'linear(to-r, #7c4998, #5497cc)',
                          boxShadow:
                            'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
                        }}
                        borderRadius="md"
                        fontWeight="bold"
                        size="md"
                        transition="all 0.2s ease-in-out">
                        {translate('app:send').toString()}
                      </Button>
                    </Flex>
                  </Center>
                </ModalBody>
              </Center>
            )}

            {activeModal === 2 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending').toString()}
                </Heading>
                <EmailIcon color="purple" w={8} h={8} />
              </Center>
            )}

            {activeModal === 3 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending_complete').toString()}
                </Heading>
                <CheckIcon color="green" w={8} h={8} mb={4} />
                <Button
                  onClick={() => {
                    onClose();
                    setActiveModal(1);
                    navigate(ROUTES.crm);
                  }}>
                  {translate('app:close').toString()}
                </Button>
              </Center>
            )}

            {activeModal === 4 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending_failed').toString()}
                </Heading>
                <WarningIcon color="red" w={8} h={8} mb={4} />
                <Button
                  onClick={() => {
                    onClose();
                    setActiveModal(1);
                  }}>
                  {translate('app:close').toString()}
                </Button>
              </Center>
            )}
          </ModalContent>
        </Modal>
      </Box>
    </section>
  );
};

export default FriendList;
