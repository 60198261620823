export const EMAIL_MAX_LENGTH = 320;
export const PHONE_NUMBER_MAX_LENGTH = 15;
export const PASSWORD_MAX_LENGTH = 127;
export const PASSWORD_MIN_LENGTH = 8;
export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;
export const FIRST_NAME_KANA_MAX_LENGTH = 50;
export const LAST_NAME_KANA_MAX_LENGTH = 50;
export const OTP_CODE_MAX_LENGTH = 6;
export const DATE_OF_BIRTH_LENGTH = 10; // DD/MM/YYYY
export const MSG_CRM_LENGTH = 2500;
export const MSG_CRM_SHOW_ERROR_LENGTH = 2500;
export const MSG_USER_LENGTH = 80000;
export const MSG_USER_SHOW_ERROR_LENGTH = 75000;
