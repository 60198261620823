import { useCallback, useEffect, useState } from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useLocation } from 'react-router';

import { LoadingOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { dispatch } from 'common';
import { copyHandler, getRedirectUri } from 'common/utils/core';
import { DisconnectConfirmModal } from 'containers/layouts/navbar/disconnect-confirm-modal.component';
import { Icon } from 'library/components/icon';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t, translate } from 'library/utils';
import backend from 'library/utils/backend';
import { debounce, isEmpty, last } from 'lodash';
import { AuthorizationLink, UserInfo } from 'model/home';
import { IUpdateUserInfo } from 'model/user.interface';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdOutlineContentCopy } from 'react-icons/md';
import { appActions, homeActions } from 'redux/action-slice';
interface ProfileModalProps {
  open: boolean;
  onClose: () => void;
  userInfo: UserInfo;
  isXSocialModal: boolean;
  updateUserInfo: ({ name, description, avatar }: IUpdateUserInfo, cbSuccess: () => void) => void;
}

export const ProfileModal = ({
  open,
  onClose,
  userInfo: userInfoRx,
  isXSocialModal,
  updateUserInfo,
}: ProfileModalProps) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState<string>('');
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // x-social, telegram, discord
  const [modalTitle, setModalTitle] = useState('');
  const domain =
    window.location.hostname === 'localhost'
      ? `http://${window.location.hostname}:${window.location.port}`
      : window.location.hostname;

  useEffect(() => {
    getUserInfo();
  }, []);

  const renderAvatar = useCallback(() => {
    if (url)
      return (
        <Avatar
          src={
            url.startsWith('http') ? url : `${process.env.REACT_APP_API_URL + url}&t=${Date.now()}`
          }
          size={100}
          className="relative"
        />
      );
    if (userInfo) {
      return (
        <Avatar size={100} className="bg-transparent border-none">
          <Jazzicon
            paperStyles={{ width: 100, height: 100 }}
            seed={jsNumberForAddress(userInfo.wallet_address)}
          />
        </Avatar>
      );
    }

    return null;
  }, [url, userInfo]);

  const getUserInfo = () => {
    dispatch(
      homeActions.onGetUserInfo(data => {
        setUserInfo(data);
        setUrl(data.avatar);
        dispatch(appActions.onSetUserInfo(data));
      }),
    );
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append('imageFiles', e.target.files[0]);

      try {
        const response = await NetWorkService.PostFormData<string[]>({
          url: ApiConstants.UPLOAD_IMAGES,
          body: formData,
        });

        if (response && response.data) {
          const [url] = response.data;
          const arr = url.split('=');
          setUrl(url);
          form.setFieldValue('avatar', last(arr) as string);
        }
      } catch (e) {
        console.log(e, 'upload');
      }
    }
  };

  const openTelegramDeepLink = () => {
    window.open(process.env.REACT_APP_TELEGRAM_BOT, '_self', 'noopener,noreferrer');
  };

  const debounceTelegram = debounce(() => openTelegramDeepLink(), 1000);

  const generateAPIKey = async () => {
    const response = await NetWorkService.Post<string>({
      url: ApiConstants.GENERATE_API_KEY,
    });

    if (response?.data) {
      if (userInfo) {
        setUserInfo({ ...userInfo, api_key: response.data });
        dispatch(appActions.onSetUserInfo({ ...userInfo, api_key: response.data }));
      }
      message.success($t('messages:noti_generate_api_key_successfully'));
    }
  };

  const debounceGenerate = debounce(() => generateAPIKey(), 1000);

  // const openDiscordInvitationDeepLink = () => {
  //   window.open(
  //     discordInviteLink || process.env.REACT_APP_DISCORD_ROOM_INVITE_LINK,
  //     '_blank',
  //     'noopener,noreferrer',
  //   );
  // };

  const openAuthorizedDiscord = async () => {
    const redirect_uri = getRedirectUri(pathname.includes('messages') ? 'messages' : '');
    const response = await NetWorkService.Get<AuthorizationLink>({
      url: ApiConstants.DISCORD_AUTHORIZATION,
      params: {
        redirect_uri,
      },
    });

    if (response?.data) {
      window.open(response.data.authorization_link, '_self', 'noopener,noreferrer');
    }
  };

  const debounceAuthorized = debounce(() => openAuthorizedDiscord(), 1000);

  const openAuthorized_X_Social = async () => {
    console.log('openAuthorized_X_Social');

    try {
      const redirect_url =
        location.protocol + '//' + location.host + location.pathname + '?x-social-redirect=true';
      console.log(redirect_url, 'redirect_url');
      const params = {
        redirect_url: redirect_url,
      };
      const response = await backend.twitter.getXSocialAuthen(params);
      console.log(response, 'response');
      if (response?.data) {
        window.open(response.data.url, '_self', 'noopener,noreferrer');
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const disConnected_X_Social = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await backend.twitter.logoutXSocial();
      console.log(response, 'response=>disConnected_X_Social');
      await getUserInfo();
      setIsLoading(false);
    } catch (error) {
      console.log(error, 'error=>disConnected_X_Social');
      setIsLoading(false);
    }
  }, []);
  const handleOk = useCallback(async () => {
    await disConnected_X_Social();
    setIsOpenModal(false);
  }, [disConnected_X_Social]);
  const handleCancel = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const openDisconnectModal = useCallback((type, title) => {
    setModalType(type);
    setModalTitle(title);
    setIsOpenModal(true);
  }, []);

  const debounceAuthorized_X_Social = debounce(() => openAuthorized_X_Social(), 1000);
  // const isLinkedBoth = userInfo && !isEmpty(userInfo.discord) && !isEmpty(userInfo.telegram);
  const isLinkedTekegram = userInfo && !isEmpty(userInfo.telegram);

  if (!userInfo) {
    return null;
  }

  return (
    <>
      {isOpenModal && modalType && modalTitle ? (
        <DisconnectConfirmModal
          type={modalType}
          title={modalTitle}
          isOpenModal={isOpenModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      ) : (
        ''
      )}

      <Modal
        footer={null}
        centered
        title={
          <div className="flex gap-x-1 items-center">
            <div className="flex-[1]">
              <h6 className="text-center text-lg">
                {isXSocialModal
                  ? $t('messages:user_form:x_social_modal')
                  : $t('messages:user_form:setting')}
              </h6>
            </div>
          </div>
        }
        open={open}
        onCancel={onClose}>
        {isXSocialModal ? (
          <div className="modal-x-social-connect">
            <div className="modal-title"></div>
            <div className="modal-body mt-16 mb-16">
              <Row gutter={[10, 14]} className="w-full" justify={'center'} align="middle">
                <Col md={8} xs={12}>
                  <div className="flex flex-col items-center justify-between gap-y-1 border border-gray-200 py-4 min-h-[190px] rounded-md">
                    <div className="flex flex-col items-center gap-y-2">
                      <h6 className="font-sans font-medium text-sm">X</h6>
                      <Icon icon="x_social" size={50} />
                    </div>

                    {!userInfo?.twitter?.id ||
                    (userInfo?.twitter?.id && isEmpty(userInfo?.twitter?.id)) ? (
                      <Button
                        onClick={debounceAuthorized_X_Social}
                        className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                        size="middle">
                        {$t('messages:btn_connect')}
                      </Button>
                    ) : (
                      <>
                        <IoCheckmarkCircle size={36} className="text-green-500" />
                        <Button
                          disabled={isLoading}
                          onClick={() => {
                            openDisconnectModal('x-social', 'modal_disconnect_confirm:title');
                          }}
                          className="bg-red-500 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                          size="middle">
                          {isLoading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 18, color: '#fff', marginRight: '8px' }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            ''
                          )}
                          {$t('messages:btn_disconnect')}
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <Form
            form={form}
            layout="horizontal"
            initialValues={{
              name: userInfo.name,
              description: userInfo.description || '',
            }}
            onFinish={value => {
              updateUserInfo(
                {
                  ...value,
                  description: value.description.trim(),
                  name: value.name.trim(),
                },
                () => getUserInfo(),
              );
            }}>
            <div className="flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-2">
                <h6 className="font-sans font-semibold text-sm">
                  {$t('messages:user_form:profile')}
                </h6>
                <div className="flex gap-x-4 pt-4">
                  <Form.Item name="avatar">
                    <Badge
                      count={
                        <div className="w-6 h-6 rounded-full bg-main-color cursor-pointer text-white hover:opacity-90 hover:text-slate-50  focus:text-white">
                          <Input
                            id="upload"
                            type="file"
                            accept="image/*"
                            multiple={false}
                            className="hidden"
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                          />

                          <label
                            className="cursor-pointer w-full h-full flex items-center justify-center"
                            htmlFor="upload">
                            <AiOutlineEdit size={18} className="self-center" />
                          </label>
                        </div>
                      }
                      offset={[-18, 80]}>
                      {renderAvatar()}
                    </Badge>
                  </Form.Item>

                  <div className="flex flex-col flex-[2]">
                    <Form.Item
                      name="name"
                      label={$t('messages:user_form:label_name')}
                      rules={[
                        {
                          max: 50,
                          message: translate('msg:MSG_009', {
                            fieldName: $t('messages:user_form:field_user_name'),
                            maximumLength: 50,
                          }).toString(),
                        },
                        {
                          message: translate('msg:MSG_001', {
                            fieldName: $t('messages:user_form:field_user_name'),
                          }).toString(),
                          whitespace: true,
                        },
                      ]}>
                      <Input placeholder={$t('messages:user_form:field_user_name')} />
                    </Form.Item>

                    <Form.Item
                      name="description"
                      label={$t('messages:user_form:label_bio')}
                      className="flex-[2]">
                      <Input.TextArea
                        placeholder={$t('messages:user_form:field_my_bio')}
                        rows={4}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {/* <Row gutter={[10, 14]}>
                {userInfo?.invitation_url && (
                  <>
                    <Col md={24}>
                      <Typography className="font-sans font-semibold text-sm">
                        {$t('messages:tooltip_invitation_link')}
                      </Typography>
                    </Col>
                    <Col md={24}>
                      <div className="flex items-center gap-x-2">
                        <div className="flex-[5]">
                          <Input
                            placeholder={$t('messages:tooltip_invitation_link')}
                            type={'text'}
                            disabled
                            value={`${domain}${userInfo.invitation_url}`}
                          />
                        </div>
                        <div className="flex-1">
                          <div className="flex items-center gap-x-1">
                            <Button
                              onClick={() => {
                                copyHandler(`${domain}${userInfo.invitation_url}`);
                                message.success($t('messages:noti_copied'));
                              }}
                              className="border-2 border-[#bebcf6] text-purple65 justify-center items-center flex px-4 font-sans font-medium hover:scale-105 hover:!text-purple65 hover:opacity-90"
                              size="middle"
                              icon={<MdOutlineContentCopy size={18} />}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row> */}
              <Row gutter={[10, 14]}>
                {isLinkedTekegram && (
                  <>
                    <Col md={24}>
                      <Typography className="font-sans font-semibold text-sm">
                        {$t('messages:user_form:api_key')}
                      </Typography>
                    </Col>
                    <Col md={24}>
                      <div className="flex items-center gap-x-2">
                        <div className="flex-[5]">
                          <Input
                            placeholder={$t('messages:user_form:api_key')}
                            type={show ? 'text' : 'password'}
                            disabled
                            value={userInfo.api_key}
                          />
                        </div>
                        <div className="flex-1">
                          {userInfo.api_key ? (
                            <div className="flex items-center gap-x-1">
                              <Button
                                onClick={() => {
                                  copyHandler(userInfo.api_key);
                                  message.success($t('messages:noti_copied'));
                                }}
                                className="border-2 border-[#bebcf6] text-purple65 justify-center items-center flex px-4 font-sans font-medium hover:scale-105 hover:!text-purple65 hover:opacity-90"
                                size="middle"
                                icon={<MdOutlineContentCopy size={18} />}
                              />
                              <Button
                                onClick={() => setShow(!show)}
                                className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                                size="middle">
                                {show ? 'Hide' : 'Show'}
                              </Button>
                            </div>
                          ) : (
                            <Button
                              onClick={debounceGenerate}
                              className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                              size="middle">
                              {$t('messages:btn_generate')}
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                )}
                <Row gutter={[10, 14]} className="w-full" justify={'center'} align="middle">
                  <Col md={24}>
                    <h6 className="font-sans font-semibold text-sm px-1">
                      {$t('messages:connection')}
                    </h6>
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="flex flex-col items-center justify-between gap-y-1 border border-gray-200 py-4 min-h-[190px] rounded-md">
                      <div className="flex flex-col items-center gap-y-2">
                        <h6 className="font-sans font-medium text-sm">Telegram</h6>
                        <Icon icon="telegram" size={50} />
                      </div>

                      {!userInfo.telegram ? (
                        <Button
                          onClick={debounceTelegram}
                          className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                          size="middle">
                          {$t('messages:btn_connect')}
                        </Button>
                      ) : (
                        <IoCheckmarkCircle size={36} className="text-green-500" />
                      )}
                    </div>
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="flex flex-col items-center justify-between gap-y-1 border border-gray-200 py-4 min-h-[190px] rounded-md">
                      <div className="flex flex-col items-center gap-y-2">
                        <h6 className="font-sans font-medium text-sm">Discord</h6>
                        <Icon icon="discord" size={50} />
                      </div>

                      {!userInfo.discord || (userInfo.discord && isEmpty(userInfo.discord)) ? (
                        <Button
                          onClick={debounceAuthorized}
                          className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                          size="middle">
                          {$t('messages:btn_connect')}
                        </Button>
                      ) : (
                        <IoCheckmarkCircle size={36} className="text-green-500" />
                      )}
                    </div>
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="flex flex-col items-center justify-between gap-y-1 border border-gray-200 py-4 min-h-[190px] rounded-md">
                      <div className="flex flex-col items-center gap-y-2">
                        <h6 className="font-sans font-medium text-sm">X</h6>
                        <Icon icon="x_social" size={50} />
                      </div>

                      {!userInfo?.twitter?.id ||
                      (userInfo?.twitter?.id && isEmpty(userInfo?.twitter?.id)) ? (
                        <Button
                          onClick={debounceAuthorized_X_Social}
                          className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                          size="middle">
                          {$t('messages:btn_connect')}
                        </Button>
                      ) : (
                        <>
                          <IoCheckmarkCircle size={36} className="text-green-500" />
                          <Button
                            disabled={isLoading}
                            onClick={() => {
                              openDisconnectModal('x-social', 'modal_disconnect_confirm:title');
                            }}
                            className="bg-red-500 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                            size="middle">
                            {isLoading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 18, color: '#fff', marginRight: '8px' }}
                                    spin
                                  />
                                }
                              />
                            ) : (
                              ''
                            )}
                            {$t('messages:btn_disconnect')}
                          </Button>
                        </>
                      )}
                    </div>
                  </Col>
                  {/* <Col md={8} xs={12}>
              <div className="flex flex-col items-center justify-between gap-y-1 border border-gray-200 py-4 min-h-[190px] rounded-md">
                <div className="flex flex-col items-center gap-y-2">
                  <h6 className="font-sans font-medium text-sm">Email</h6>
                  <Icon icon="email" size={50} />
                </div>

                <div className="flex flex-col items-center px-3 gap-y-2">
                  <Input placeholder="Email address" />
                  <Button
                    className="bg-purple65 px-4 text-white font-sans font-medium hover:scale-105 hover:!text-white hover:opacity-90 border-none"
                    size="middle">
                    Connect
                  </Button>
                </div>
              </div>
            </Col> */}
                </Row>
              </Row>

              <div className="flex items-center justify-center gap-x-2">
                <Button
                  htmlType="submit"
                  className="px-10 bg-purple65 text-white hover:scale-105 hover:!text-white hover:opacity-90 font-semibold">
                  {$t('app:save')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};
