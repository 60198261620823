import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './index.scss';
const ProgressDialogComponent = forwardRef((_, ref) => {
  // state
  const [visible, setVisible] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // effect
  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setVisible(true);
      },
      hide: () => {
        setVisible(false);
      },
    }),
    [],
  );

  // render
  return visible ? (
    <>
      <div className={'containerLoading'}>
        <Spin indicator={antIcon} size={'large'} />
      </div>
    </>
  ) : null;
});

export const progressDialogRef = createRef<ProgressDialogRef>();
export const ProgressDialog = () => <ProgressDialogComponent ref={progressDialogRef} />;
export const showLoading = () => {
  progressDialogRef.current?.show();
};

export const hideLoading = () => {
  progressDialogRef.current?.hide();
};
export interface ProgressDialogRef {
  show(): void;
  hide(): void;
}
