import moment from 'moment';

import { GroupNFTContract, RoomNFTContract } from './chain.interface';

export interface User {
  avatar: string;
  wallet_address: string;
  name: string;
  dmtp_pub_key?: string;
  _id?: string;
}

export interface UserGroup extends User {
  role: string;
  friends: string[];
}

export interface CrmUser extends User {
  description?: string;
  friends?: string[];
}

export interface ChatThread {
  name: string;
  avatar: string;
  current_message: string;
  last_time_connect: moment.Moment;
  id: string;
  users: User[];
  unread_count: number;
  user_receive: Chat[];
  user_read: Chat[];
  message_id: string;
  wallet_address: string;
  hasImage: boolean;
  is_friend: boolean;
  room_type: E_ROOM_TYPE;
  admins: string[];
  description?: string;
  sharedKey: string;
}

export interface GroupThread {
  name: string;
  avatar: string;
  current_message: string;
  last_time_connect: moment.Moment;
  id: string;
  users: string[];
  unread_count: number;
  message_id: string;
  hasImage: boolean;
  room_type: E_ROOM_TYPE;
  admins: string[];
  sub_admins: string[];
  description?: string;
  nft_contracts: GroupNFTContract[];
  original_group_model: Group;
}

export interface Chat {
  user: User;
  message_id: string;
  unread_count: string | number;
}

export interface Room {
  name: string;
  avatar: string;
  users: UserGroup[];
  cid: string;
  last_message: {
    message_data: string;
    at: string;
    shared_key: string;
  };
  user_read: Chat[];
  is_official: boolean;
  user_receive: Chat[];
  created_at: string;
  creator: {
    name: string;
    wallet_address: string;
  };
  admins: string[];
  updated_at: string;
  deleted_at: string;
  is_private: boolean;
  _id: string;
  is_friend: boolean;
  room_type: E_ROOM_TYPE;
  description?: string;
  shared_key: string;
  nft_contracts: RoomNFTContract[];
  only_view?: boolean;
  is_disable: string[];
  invitation_url: string;
}

export interface Group {
  name: string;
  users: string[];
  cid: string;
  last_message: string;
  created_at: string;
  creator: {
    name: string;
    wallet_address: string;
  };
  is_official: boolean;
  admins: string[];
  updated_at: string;
  deleted_at: string;
  room_type: string;
  sub_admins: string[];
  both_init: boolean;
  _id: string;
  user_read: Chat[];
  user_receive: Chat[];
  description: string;
  avatar: string;
  nft_contracts: GroupNFTContract[];
}

export interface Emoji {
  name: string;
  image: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface Reaction {
  at: string;
  user: User;
  emoji: string;
  users: User[];
}

export interface MessageData {
  content: string;
  images: string[];
  // sticker?: Sticker;
  // images?: string[];
}

export interface MessageRoom {
  room_id: string;
  sender_user: User;
  message_data: MessageData | string;
  reaction: Reaction[];
  is_forwarded: boolean;
  message_reply: string;
  cid: string;
  message_status: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  _id: string;
  shared_key: string;
}

export interface ReactionEmit {
  message_id: string;
  reaction: Reaction[];
}

export type MessageRoomType = MessageRoom;

export interface MessageRoomWithFriendStatus {
  is_friend: boolean;
  messages: MessageRoom[];
}

export interface MessageSender {
  message_id_reply: string | null;
  is_forwarded: boolean;
  room_id: string;
  message_data: string;
  is_promotion: boolean;
}

export interface BatchMessageSender {
  message_id_reply: string | null;
  is_forwarded: boolean;
  room_ids: string[];
  message_datas: string[];
  is_promotion: boolean;
  crm: boolean;
}

export interface MessageGroupSender {
  message_id_reply: string | null;
  is_forwarded: boolean;
  room_id: string;
  message_data: string;
}

export interface MessageGroupUnlimit {
  message_id_reply: string | null;
  is_forwarded: boolean;
  room_id: string;
  message_data: MessageData;
}

export interface TotalUnread {
  totalUnread: number;
}

export interface Unread {
  address: string;
  totalUnread: TotalUnread;
}

export enum E_ROOM_TYPE {
  PRIVATE = 'PRIVATE',
  UNLIMITED = 'UNLIMITED',
  LIMITED = 'LIMITED',
  KEYROOM = 'KEY_ROOM',
}

export enum E_ROUTE_STATE {
  PERSONAL = 1,
  GROUP_LIMIT = 2,
  GROUP_UNLIMIT = 3,
}

export interface IReactionRemove {
  message_id: string;
  reaction: Reaction[];
}

export interface CrmConditions {
  type: Conditiontypes;
  value: string;
  chain: string;
  range?: number;
}

export interface Engagement {
  [key: string]: {
    read: boolean;
    link: boolean;
  };
}

export interface CrmMessages {
  sender: string;
  tag: string;
  content: Content[];
  conditions: CrmConditions[];
  destination_address_list: Engagement[];
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface CreateCrmMessages {
  tag: string;
  content: Content[];
  conditions: CrmConditions[];
  destination_address_list: string[];
}

export enum Conditiontypes {
  ALL = 'all',
  NFT = 'nft',
  FT = 'ft',
  ASSET = 'asset',
  TRANSACTION = 'tx',
  SELECT = 'select',
}

export interface Content {
  text: string;
  images: string[];
}

export enum ErrorType {
  MESSAGE_TAG = 'message_tag',
  MESSAGE = 'message',
  MESSAGE_LONG = 'message_long',
  DESTINATION = 'destination',
}
