import { call, execFunc, getState, put, showErrorApi, takeLatest } from 'common';
import { ApiConstants, NetWorkResponseType, NetWorkService } from 'library/networking';
import { Sticker, StickerPool } from 'model/create-stickers';
import { AuthorizationLink, InviteServer, MessageSign, Token, UserInfo } from 'model/home';
import { IStickerMarket } from 'model/sticker-market';
import { IStickerOwner } from 'model/sticker-owner';
import { Action } from 'redux';
import { appActions } from 'redux/action-slice';

import { showToast } from '../../library/components/toast/index';
import { homeActions } from '../action-slice/home';

export function* homeSaga() {
  yield* takeLatest(homeActions.onGetUserInfo.type, onGetUserInfo);
  yield* takeLatest(homeActions.onDiscordAuthorization.type, onDiscordAuthorization);
  yield* takeLatest(homeActions.onUserCodeDiscord.type, onUserCodeDiscord);
  yield* takeLatest(homeActions.onDiscordInviteServer.type, onDiscordInviteServer);
  yield* takeLatest(homeActions.onUploadMetaData.type, onUploadMetaData);
  yield* takeLatest(homeActions.onAddToPool.type, onAddToPool);
  yield* takeLatest(homeActions.onUpdateToPool.type, onUpdateToPool);
  yield* takeLatest(homeActions.onRemoveToPool.type, onRemoveToPool);
  yield* takeLatest(homeActions.onClearAllPool.type, onClearAllPool);
  yield* takeLatest(homeActions.onGetPoolList.type, onGetPoolList);
  yield* takeLatest(homeActions.onGetListStickers.type, onGetListStickers);
  yield* takeLatest(homeActions.onGetMessageSign.type, onGetMessageSign);
  yield* takeLatest(homeActions.onGetTokens.type, onGetTokens);
  yield* takeLatest(homeActions.onGetListOwnerStickers.type, onGetListOwnerStickers);
  yield* takeLatest(homeActions.onDownload.type, onDownload);
}

function* onGetUserInfo(action: Action) {
  if (homeActions.onGetUserInfo.match(action)) {
    const { onSucceeded } = action.payload;
    yield* put(appActions.onStartProcess());

    const response = yield* call<NetWorkResponseType<UserInfo>>(NetWorkService.Get, {
      url: ApiConstants.USER_INFO,
    });
    yield* put(appActions.onEndProcess());

    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    const { userInfo } = getState('app');
    if (!userInfo) {
      yield* put(appActions.onSetUserInfo(response?.data as UserInfo));
      yield* put(appActions.onSetAddress((response?.data as UserInfo)?.wallet_address));
      yield* put(appActions.onSetChatSelectedAccount(response.data?.wallet_address));
    }
    execFunc(onSucceeded, response?.data as UserInfo);
  }
}

function* onDiscordAuthorization(action: Action) {
  if (homeActions.onDiscordAuthorization.match(action)) {
    const { onSucceeded, params, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<AuthorizationLink>>(NetWorkService.Get, {
      url: ApiConstants.DISCORD_AUTHORIZATION,
      params: { redirect_uri: window.location.href.split('?')[0] },
    });
    yield* put(appActions.onEndProcess());

    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();

    execFunc(onSucceeded, response.data as AuthorizationLink);
  }
}
function* onDiscordInviteServer(action: Action) {
  if (homeActions.onDiscordInviteServer.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<InviteServer>>(NetWorkService.Get, {
      url: ApiConstants.DISCORD_INVITE_SERVER,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      onFailed?.(response as any);
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as InviteServer);
  }
}

function* onUserCodeDiscord(action: Action) {
  if (homeActions.onUserCodeDiscord.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<string>>(NetWorkService.Post, {
      url: ApiConstants.USER_CODE_DISCORD,
      params: params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as string);
  }
}
function* onUploadMetaData(action: Action) {
  if (homeActions.onUploadMetaData.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<string>>(NetWorkService.Post, {
      url: ApiConstants.UPLOAD_METADATA,
      body: params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as string);
  }
}

function* onAddToPool(action: Action) {
  if (homeActions.onAddToPool.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Sticker>>(NetWorkService.Post, {
      url: ApiConstants.ADD_TO_POOL,
      body: params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as Sticker);
  }
}

function* onUpdateToPool(action: Action) {
  if (homeActions.onUpdateToPool.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Sticker>>(NetWorkService.Patch, {
      url: ApiConstants.UPDATE_TO_POOL,
      params: params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as Sticker);
  }
}

function* onRemoveToPool(action: Action) {
  if (homeActions.onRemoveToPool.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<string>>(NetWorkService.Delete, {
      url: ApiConstants.REMOVE_TO_POOL,
      body: params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as string);
  }
}

function* onClearAllPool(action: Action) {
  if (homeActions.onClearAllPool.match(action)) {
    const { onSucceeded } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<string>>(NetWorkService.Delete, {
      url: ApiConstants.CLEAR_ALL_POOL,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as string);
  }
}

function* onGetPoolList(action: Action) {
  if (homeActions.onGetPoolList.match(action)) {
    const { onSucceeded } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<StickerPool>>>(NetWorkService.Get, {
      url: ApiConstants.GET_POOL_LIST,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    showToast();
    execFunc(onSucceeded, response.data as Array<StickerPool>);
  }
}

function* onGetListStickers(action: Action) {
  if (homeActions.onGetListStickers.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<IStickerMarket>>>(NetWorkService.Get, {
      url: ApiConstants.GET_STICKERS,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<IStickerMarket>);
  }
}

function* onGetListOwnerStickers(action: Action) {
  if (homeActions.onGetListOwnerStickers.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<IStickerOwner>>>(NetWorkService.Get, {
      url: ApiConstants.GET_OWNER_STICKERS,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<IStickerOwner>);
  }
}

function* onGetMessageSign(action: Action) {
  if (homeActions.onGetMessageSign.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageSign>>(NetWorkService.Get, {
      url: ApiConstants.GET_MESSAGE_SIGN,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageSign);
  }
}

function* onGetTokens(action: Action) {
  if (homeActions.onGetTokens.match(action)) {
    const { onSucceeded } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<Token>>>(NetWorkService.Get, {
      url: ApiConstants.GET_TOKEN,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<Token>);
  }
}

function* onDownload(action: Action) {
  if (homeActions.onDownload.match(action)) {
    const { onSucceeded, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<unknown>>(NetWorkService.Get, {
      url: ApiConstants.DOWNLOAD_FILE,
      params,
    });
    yield* put(appActions.onEndProcess());
    console.log('response', response);
    if (!response) {
      return;
    }
    if (!response?.status) {
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as unknown);
  }
}
