import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { dispatch } from 'common';
import { translate } from 'library/utils';
import { Conditiontypes, Content, CrmMessages } from 'model/messages';
import { crmActions } from 'redux/action-slice';
import ROUTES from 'routes/constant';
import { useAccount } from 'wagmi';

import WithSubnavigation from '../navbar';

interface MessageListProps {
  messages: Content[];
}

const MessageList = () => {
  const [crmMessages, setCrmMessages] = useState<CrmMessages[]>([]);
  const { address } = useAccount();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMessage, setSelectedMessage] = useState<CrmMessages | null>(null);
  const [isWalletList, setIsWalletList] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (address) {
      dispatch(crmActions.onGetCrmMessages(data => onGetCrmMessagesSuccess(data)));
    }
  }, [address]);

  const onUpdateCrmMessagesReadSuccess = (data: any) => {
    console.log(data);
  };

  const onGetCrmMessagesSuccess = (data: any) => {
    setCrmMessages(data.reverse());
    dispatch(crmActions.onUpdateCrmMessageRead(data => onUpdateCrmMessagesReadSuccess(data)));
  };

  const navigateNewMessages = () => {
    navigate(ROUTES.newMessage);
  };

  const handleRowClick = (message: CrmMessages) => {
    setSelectedMessage(message);
    onOpen();
  };

  const handleIsWalletList = () => {
    if (isWalletList == true) {
      setIsWalletList(false);
    } else {
      setIsWalletList(true);
    }
  };

  const getChainNameFromHex = (hex: string) => {
    switch (hex) {
      case '0x1':
        return 'Ethereum';
        break;
      case '0x89':
        return 'Polygon';
        break;
    }
    return;
  };

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const getReadAddresses = (destinationAddressList: any[]) => {
    const readAddresses: string[] = [];

    destinationAddressList.forEach(addressObj => {
      const [address] = Object.keys(addressObj);
      if (addressObj[address].read) {
        readAddresses.push(address);
      }
    });

    return readAddresses;
  };

  const ReadAddresses = ({ destinationAddressList }: { destinationAddressList: any[] }) => {
    const readAddresses = getReadAddresses(destinationAddressList);

    return (
      <Tbody>
        {readAddresses.map((address, index) => (
          <Tr key={index}>
            <Td>{address}</Td>
          </Tr>
        ))}
      </Tbody>
    );
  };

  const Message: React.FC<{ content: Content }> = ({ content }) => {
    return (
      <>
        {content.images.length > 0 ? (
          content.images.map((url, index) => (
            <Image
              key={index}
              src={url}
              alt={`Content image ${index}`}
              my={2}
              maxW="60%"
              alignSelf="flex-start"
            />
          ))
        ) : (
          <Box
            minW={'50px'}
            bgGradient={'linear(to-r, #7c4998, #5497cc)'}
            borderRadius="lg"
            p={2}
            alignSelf="flex-start"
            maxWidth="80%"
            my={2}>
            <Text color={'gray.100'} fontWeight={'bold'}>
              {content.text}
            </Text>
          </Box>
        )}
      </>
    );
  };

  const MessageList: React.FC<MessageListProps> = ({ messages }) => {
    return (
      <VStack spacing={4} align="end">
        {messages.map((content, index) => (
          <Message key={index} content={content} />
        ))}
      </VStack>
    );
  };

  return (
    <>
      <Box className={'w-full min-h-screen bg-gray-100 p-5'}>
        <WithSubnavigation />
        <Stack spacing={8} justifyContent={'center'} alignItems={'center'}>
          <Heading
            py={5}
            bgClip="text"
            bgGradient="linear(to-l, #7c4998, #5497cc)"
            fontSize={['4xl', '5xl']}
            fontWeight="extrabold"
            textTransform="uppercase"
            textAlign="center"
            transition="all 0.2s ease-in-out">
            DMTP for Biz - {translate('app:message_list').toString()}
          </Heading>

          <Button
            onClick={navigateNewMessages}
            bgGradient="linear(to-l, #7c4998, #5497cc)"
            color="white"
            _hover={{
              bgGradient: 'linear(to-r, #5497cc, #7c4998)',
              boxShadow: '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
            }}
            _active={{
              bgGradient: 'linear(to-r, #7c4998, #5497cc)',
              boxShadow:
                'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
            }}
            boxShadow="0 0 8px rgba(124, 73, 152, 0.6), 0 0 12px rgba(84, 151, 204, 0.6)"
            borderRadius="md"
            fontWeight="bold"
            size="md"
            leftIcon={<EmailIcon />}
            transition="all 0.2s ease-in-out">
            {translate('app:new_message').toString()}
          </Button>

          <Divider />

          {crmMessages.length > 0 ? (
            <Table size="sm" variant="simple" mt={5} colorScheme="purple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Message Tag</Th>
                  <Th>{translate('app:number_of_sent').toString()}</Th>
                  <Th>{translate('app:number_of_read').toString()}</Th>
                  <Th>{translate('app:percentage_of_read').toString()}</Th>
                  <Th>{translate('app:detail').toString()}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {crmMessages.map((message, index) => {
                  const readMessages = message.destination_address_list.filter(
                    userEngagement => userEngagement[Object.keys(userEngagement)[0]].read,
                  ).length;
                  const totalMessages = message.destination_address_list.length;
                  const readRate = ((readMessages / totalMessages) * 100).toFixed(2);

                  return (
                    <Tr key={index}>
                      <Td>{formatDate(message.created_at.toString())}</Td>
                      <Td>{message.tag}</Td>
                      <Td>{totalMessages}</Td>
                      <Td>{readMessages}</Td>
                      <Td>{readRate}%</Td>
                      <Td>
                        <Button
                          size={'sm'}
                          variant="outline"
                          colorScheme="purple"
                          onClick={() => handleRowClick(message)}>
                          {translate('app:detail').toString()}
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <Flex direction="column" align="center" justify="center" h="200px" mt="5">
              <Text fontSize="xl" color="gray.500">
                No messages available
              </Text>
            </Flex>
          )}

          <Modal isOpen={isOpen} onClose={onClose} size={'2xl'} motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent
              bg="blue.50"
              borderRadius="lg"
              boxShadow="0 0 20px rgba(124, 73, 152, 0.8), 0 0 30px rgba(84, 151, 204, 0.8)">
              <ModalHeader
                bgClip="text"
                bgGradient="linear(to-l, #7c4998, #5497cc)"
                textAlign={'center'}
                borderRadius="lg"
                fontSize="2xl"
                fontWeight="bold">
                {isWalletList ? 'WalletAddress List' : translate('app:message_detail').toString()}
              </ModalHeader>
              <ModalCloseButton color="white" />
              {isWalletList ? (
                <ModalBody>
                  <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Flex
                      alignItems="center"
                      color={'gray'}
                      _hover={{ color: 'purple' }}
                      cursor="pointer"
                      onClick={handleIsWalletList}>
                      <ArrowBackIcon boxSize={'4'} />
                      <Text ml={2}>{translate('app:back').toString()}</Text>
                    </Flex>
                    <Box></Box>
                  </Flex>
                  <Tabs variant={'line'} size={'md'} bg={'white'}>
                    <TabList display="flex">
                      <Tab flex="1" _selected={{ color: 'purple', borderBottomColor: 'purple' }}>
                        {translate('app:destination_wallets').toString()}
                      </Tab>
                      <Tab flex="1" _selected={{ color: 'purple', borderBottomColor: 'purple' }}>
                        {translate('app:read_wallets').toString()}
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Table variant="simple" size={'sm'} mt={'5'}>
                          <Thead>
                            <Tr>
                              <Th>Wallet Addresses</Th>
                            </Tr>
                          </Thead>
                          {selectedMessage?.destination_address_list?.map((address, index) => (
                            <Tbody key={index}>
                              <Tr>
                                <Td>{Object.keys(address)}</Td>
                              </Tr>
                            </Tbody>
                          ))}
                        </Table>
                      </TabPanel>
                      <TabPanel>
                        <Table variant="simple" size={'sm'} mt={'5'}>
                          <Thead>
                            <Tr>
                              <Th>Wallet Addresses</Th>
                            </Tr>
                          </Thead>
                          <ReadAddresses
                            destinationAddressList={selectedMessage?.destination_address_list || []}
                          />
                        </Table>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ModalBody>
              ) : (
                <ModalBody>
                  <VStack spacing={4} align="stretch">
                    {selectedMessage?.content && (
                      <Box
                        w="500px"
                        bg="white"
                        p={5}
                        border={'1px'}
                        borderColor={'purple.800'}
                        borderRadius="lg"
                        boxShadow="md"
                        maxW="sm"
                        mx="auto"
                        my={4}>
                        <MessageList messages={selectedMessage?.content} />
                      </Box>
                    )}

                    <Flex
                      justify="space-between"
                      align="center"
                      p={4}
                      bg="white"
                      borderRadius="md"
                      boxShadow="sm">
                      <VStack align="start" spacing={1}>
                        <Heading size="sm" color="purple.500">
                          {translate('app:destination').toString()}
                        </Heading>
                        <Text fontSize="3xl" fontWeight="bold" color="gray.700">
                          {selectedMessage?.destination_address_list?.length}
                        </Text>
                      </VStack>
                      <VStack align="start" spacing={1}>
                        <Heading size="sm" color="purple.500">
                          {translate('app:number_of_read').toString()}
                        </Heading>
                        <Text fontSize="3xl" fontWeight="bold" color="gray.700">
                          {
                            selectedMessage?.destination_address_list.filter(
                              userEngagement => userEngagement[Object.keys(userEngagement)[0]].read,
                            ).length
                          }
                        </Text>
                      </VStack>

                      <Button
                        variant="outline"
                        size="md"
                        colorScheme="purple"
                        onClick={handleIsWalletList}
                        _hover={{ transform: 'scale(1.05)' }}
                        _active={{ transform: 'scale(1)' }}>
                        {translate('app:address_list').toString()}
                      </Button>
                    </Flex>

                    <Box p={4} bg="white" borderRadius="md">
                      <Heading size={'sm'} mb={2}>
                        {translate('app:sending_conditions').toString()}
                      </Heading>
                      {selectedMessage?.conditions?.map((condition, index) => {
                        let displayText = '';
                        const chainName = getChainNameFromHex(condition.chain);

                        switch (condition.type) {
                          case Conditiontypes.ALL:
                            displayText = translate('app:conditions_message_all').toString();
                            break;
                          case Conditiontypes.NFT:
                            displayText = translate('app:conditions_message_nft', {
                              chain: chainName,
                              value: condition.value,
                            }).toString();
                            break;
                          case Conditiontypes.FT:
                            displayText = translate('app:conditions_message_ft', {
                              chain: chainName,
                              value: condition.value,
                            }).toString();
                            break;
                          case Conditiontypes.TRANSACTION:
                            displayText = translate('app:conditions_message_tx', {
                              chain: chainName,
                              value: condition.value,
                            }).toString();
                            break;
                          case Conditiontypes.ASSET:
                            displayText = translate('app:conditions_message_asset', {
                              chain: chainName,
                              value: condition.value,
                            }).toString();
                            break;
                          case Conditiontypes.SELECT:
                            displayText = translate('app:conditions_message_select').toString();
                            break;
                          default:
                            displayText = translate('app:conditions_message_unknown').toString();
                        }
                        return (
                          <Text key={index} color="gray.700">
                            {displayText}
                          </Text>
                        );
                      })}
                    </Box>
                  </VStack>
                </ModalBody>
              )}
              <ModalFooter borderRadius="lg" justifyContent="center">
                <Button
                  bgGradient={'linear(to-r, #7c4998, #5497cc)'}
                  color="white"
                  colorScheme="purple"
                  variant="ghost"
                  onClick={() => {
                    setIsWalletList(false);
                    onClose();
                  }}
                  _hover={{
                    transform: 'scale(1.05)',
                    bgGradient: 'linear(to-r, #7c4998, #5497cc)',
                  }}
                  _active={{
                    transform: 'scale(0.95)',
                    bgGradient: 'linear(to-r, #5497cc, #7c4998)',
                  }}>
                  {translate('app:close').toString()}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Box>
    </>
  );
};

export default MessageList;
