import Discord from './source/discord.svg';
import Email from './source/email.svg';
import Bag from './source/ic_bag.svg';
import Bell from './source/ic_bell.svg';
import Channel from './source/ic_channel.svg';
import Message from './source/ic_message.svg';
import ICMessage from './source/ic_msg.svg';
import UserAccept from './source/ic_user_accept.svg';
import UserAdd from './source/ic_user_add.svg';
import Wallet from './source/ic_wallet.svg';
import NFTView from './source/nft-view.svg';
import Pencil from './source/pencil.svg';
import Telegram from './source/telegram.svg';
import WalletConnectRounded from './source/walletconnect-rounded.svg';
import WalletConnect from './source/WalletConnect.svg';
import X_Social from './source/x_social.svg';
export const icons = {
  wallet: require('./source/ic_wallet.png'),
  notify: require('./source/ic_notify.png'),
  bell: Bell,
  pencil: Pencil,
  walletSvg: Wallet,
  reaction: require('./source/ic_reaction.png'),
  reply: require('./source/ic_reply.png'),
  dot: require('./source/ic_dot.png'),
  send: require('./source/ic_send.png'),
  camera: require('./source/ic_camera.png'),
  crop: require('./source/ic_crop.png'),
  icon: require('./source/ic_icon.png'),
  plus: require('./source/ic_add.png'),
  message: Message,
  bag: Bag,
  channel: Channel,
  userAdd: UserAdd,
  icMessage: ICMessage,
  userAccept: UserAccept,
  telegram: Telegram,
  discord: Discord,
  email: Email,
  walletConnect: WalletConnect,
  walletConnectRounded: WalletConnectRounded,
  nftView: NFTView,
  x_social: X_Social,
};

export type IconTypes = keyof typeof icons;
