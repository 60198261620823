import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'common';
import backend from 'library/utils/backend';
import { getTextMulti, toFormat } from 'library/utils/common';
import eventBus from 'library/utils/eventBus';
export const UserPoints = ({ className }: { className?: string }) => {
  const [points, setPoints] = useState({
    dmtp: 0,
    point: 0,
  });

  const { userInfo, token } = useSelector(x => x.app);

  useEffect(() => {
    initData();
  }, [userInfo, token]);

  const initData = useCallback(async () => {
    try {
      if (userInfo?.wallet_address && token) {
        const res = await backend.tech.getUserPoint();
        setPoints(res);
      }
    } catch (error) {
      setPoints({
        dmtp: 0,
        point: 0,
      });
    }
  }, [userInfo]);
  useEffect(() => {
    const reloadData = () => {
      initData();
    };

    eventBus.on('reloadUserPoints', reloadData);

    return () => {
      eventBus.off('reloadUserPoints', reloadData);
    };
  }, [initData]);
  return (
    <div className={`${className}`}>
      {userInfo?.wallet_address && token && (
        <div className="flex items-center	justify-center text-lg flex-wrap font-semibold">
          <div className="mx-4">
            {toFormat(points?.dmtp)} <span className="text-xs font-normal">DMTP</span>{' '}
          </div>
          <div className="mx-4">
            {toFormat(points?.point)}{' '}
            <span className="text-xs font-normal">{getTextMulti(points?.point, 'app:point')}</span>{' '}
          </div>
        </div>
      )}
    </div>
  );
};
