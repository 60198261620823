import { getAddress } from '@ethersproject/address';
import BigNumber from 'bignumber.js';
import { $t } from 'library/utils';
import { each, extend, find } from 'lodash';
import moment from 'moment';

export function deepClone(variable) {
  try {
    return JSON.parse(JSON.stringify(variable)); //deep copy
  } catch (error) {
    return null;
  }
}

// merge 2 array by prop
export function mergeByProperty(arr1, arr2, prop) {
  each(arr2, function (arr2obj) {
    const arr1obj = find(arr1, function (arr1obj) {
      return arr1obj[prop] === arr2obj[prop];
    });

    arr1obj ? extend(arr1obj, arr2obj) : arr1.push(arr2obj);
  });
}

// export function truncate(str, n, frontChars, backChars, separator) {
//   /**
//    * str: Input string
//    * n: Number of character want to display
//    * frontChars: Number of characters in front of separator
//    * backChars: Number of characters in back of separator
//    * seperator: Symbol want to display, default "..."
//    */
//   const sep = separator || '...';
//   const sepLen = sep.length;
//   if (str.length < n - sepLen) {
//     return str;
//   }
//   return str.substr(0, frontChars) + sep + str.substr(str.length - backChars);
// }

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getFilename(url) {
  if (url) {
    const m = url
      .split('/')
      .pop()
      .replace(/\?(.*?)$/, '');

    if (m && m.length > 1) {
      return m;
    }
  }
  return '';
}
export function get_url_extension(url) {
  return new Promise(resolve => {
    fetch(url, { method: 'HEAD' })
      .then(response => response.headers.get('Content-Type'))
      .then(type => resolve(type?.replace(/.+\/|;.+/g, '')));
  });
}

export function hexToDec(hexString) {
  return parseInt(hexString, 16);
}
export function dectoHex(number) {
  return Number(number).toString(16);
}

export function scale(input, decimalPlaces) {
  const scalePow = new BigNumber(decimalPlaces.toString());
  const scaleMul = new BigNumber(10).pow(scalePow);
  return input.times(scaleMul);
}

export function toAmountShow(val, decimals = 18, numFixed = 0) {
  if (!val) return 0;
  const tmp = BigNumber(val || 0).div(Math.pow(10, decimals));
  return numFixed ? tmp.toFixed(numFixed) : tmp.toNumber();
}
export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}
// export function getMetamaskAvatar(diameter, address) {
//   if (!diameter || !address) return "";
//   const avatar = Jazzicon(diameter, parseInt(address.slice(2, 10), 16));
//   // console.log(avatar, "avatar");
//   return avatar.outerHTML;
// }

export const convertType = data => {
  const tmp = data;
  return Object.keys(data).map(item => {
    return {
      value: tmp[item],
      key: item,
      label: `PRAMS_TYPE_${item.toLocaleUpperCase()}`,
    };
  });
};
export const hex_to_ascii = str1 => {
  const hex = str1?.toString();
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
};

interface msgProps {
  type: any;
  content: any;
  duration: number;
}
export const mapMsgError = (error, duration = 5) => {
  if (error?.code === '4001' || error?.cause?.code) {
    return {
      type: 'error',
      content: error?.shortMessage || error.cause?.message || error?.message,
      duration: duration,
    };
  }
  let message =
    error?.shortMessage ||
    error?.cause?.shortMessage ||
    error?.data?.message ||
    error?.message ||
    error?.code ||
    error;
  // special message from BE with address return
  if (message && typeof message === 'string' && message?.includes('Sub Account added')) {
    const ethAddressRegex = /(0x[a-fA-F0-9]{40})/g;
    const ethAddresses = message.match(ethAddressRegex) || [];

    const content = 'marketplace:error_sub_accounts_added';
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(content, { address: ethAddresses }),
      duration: duration,
    };
    return msgArgs;
  } else if (message && typeof message === 'string' && message?.includes('is main account')) {
    const ethAddressRegex = /(0x[a-fA-F0-9]{40})/g;
    const ethAddresses = message.match(ethAddressRegex) || [];

    const content = 'marketplace:error_is_main_account';
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(content, { address: ethAddresses }),
      duration: duration,
    };
    return msgArgs;
  } else if (message && typeof message === 'string' && message?.includes('Max ref')) {
    const content = 'marketplace:points:error_max_ref';
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(content),
      duration: duration,
    };
    return msgArgs;
  } else if (message && typeof message === 'string' && message?.includes('Invalid ref')) {
    const content = 'marketplace:points:error_invalid_ref';
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(content),
      duration: duration,
    };
    return msgArgs;
  } else if (message && typeof message === 'string' && message?.includes('Internal server error')) {
    const content = 'marketplace:error_internal_server_error';
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(content),
      duration: duration,
    };
    return msgArgs;
  } else {
    message = getMessageErrorFromSC(message);
    const msgArgs: msgProps = {
      type: 'error',
      content: $t(message),
      duration: duration,
    };
    return msgArgs;
  }
};

export const mapMsgSuccess = (content, duration = 5) => {
  const msgArgs: msgProps = {
    type: 'success',
    content: $t(content),
    duration: duration,
  };
  return msgArgs;
};

export const uniqueArr = arr => {
  const newArr = arr.reduce(function (accumulator, element) {
    if (accumulator.indexOf(element) === -1) {
      accumulator.push(element);
    }
    return accumulator;
  }, []);
  return newArr;
};

export const getMessageErrorFromSC = message => {
  if (message && typeof message === 'string') {
    if (message.includes('DMTP_TECH_PARAM_ADDRESS_INVALID')) {
      // return 'dmtpTech: address param is invalid';
      return 'marketplace:error_dmtp_tech_param_address_invalid';
    } else if (message.includes('DMTP_TECH_ALREADY_ISSUED_KEY')) {
      // return 'dmtpTech: you already have issued a key';
      return 'marketplace:error_dmtp_tech_already_issued_key';
    } else if (message.includes('DMTP_TECH_PARAM_SIGNATURE_INVALID')) {
      // return 'dmtpTech: signature param is invalid';
      return 'marketplace:error_dmtp_tech_param_signature_invalid';
    } else if (message.includes('DMTP_TECH_PARAM_KEY_INVALID')) {
      // return 'dmtpTech: key param is invalid';
      return 'marketplace:error_dmtp_tech_param_key_invalid';
    } else if (message.includes('DMTP_TECH_PRICE_CHANGED')) {
      // return 'dmtpTech: price changed';
      return 'marketplace:error_dmtp_tech_price_changed';
    } else if (message.includes('KEY_CALLER_NOT_OWNER_NOR_APPROVED')) {
      // return 'Key: caller is not owner nor approve';
      return 'marketplace:error_key_caller_not_owner_nor_approved';
    } else if (message.includes('KEY_ISSUE_LIMIT')) {
      // return 'Exceeded key issue limit';
      return 'marketplace:error_key_issue_limit';
    } else if (message.includes('DMTP_TECH_NOT_ALLOW_TO_SELL_KEYS')) {
      // return 'dmtpTech: not allow to sell keys';
      return 'marketplace:error_dmtp_tech_not_allow_to_sell_keys';
    } else if (message.includes('transactionHash')) {
      // return 'Transaction failed, dmtpTech: price changed';
      return 'marketplace:error_transactionhash';
    } else if (message.includes('E11000')) {
      return 'marketplace:error_E11000';
    } else if (message.includes("Can't add yourself")) {
      return 'marketplace:error_add_main_account_to_sub_account';
    } else {
      return message;
    }
  }
};

const DEFAULT_FORMAT = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

export const toFormat = (num, ftm = {}) => {
  if (!num) return 0;
  const x = new BigNumber(num);
  BigNumber.config({ FORMAT: Object.assign({}, DEFAULT_FORMAT, ftm) });
  return x.toFormat();
};

export const getTextMulti = (number, text) => {
  let _number = toFormat(number, {});
  if (!text) return _number;
  _number = _number && _number != 'NaN' ? _number : number;
  return $t(Number(number) == 1 ? text : `${text}s`, { number: _number });
};

export const truncate = (addr, prelength = 6, sublength = 6) => {
  if (!addr) {
    return '';
  }
  const l = addr.length;
  if (l < prelength) {
    return addr;
  }
  const pre = addr.substring(0, prelength);
  const end = addr.substring(l - sublength, l);
  return `${pre}...${end}`;
};

export const isAddress = (addr: any) => {
  if (!addr) {
    return false;
  }
  try {
    return getAddress(addr);
  } catch {
    return false;
  }
};

export const formatTime = (val: any, format = 'DD/MM/YYYY - HH:mm:ss') => {
  if (!val) return '';
  if (typeof val == 'number') {
    return moment.unix(val).format(format);
  }
  return moment(val).format(format);
};
