import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { Box, Button, Center, Flex, Heading, Link, Text, useColorMode } from '@chakra-ui/react';
//import * as sigUtil from '@metamask/eth-sig-util';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Avatar, Checkbox, message, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { R } from 'assets/value';
import { dispatch, useSelector } from 'common';
import {
  decryptMessage,
  decryptTempMessage,
  encryptMessage,
  formatDotAddress,
  getRedirectUri,
} from 'common/utils/core';
import { isMobile } from 'common/utils/mobile';
import { ADDRESS_KEY_HEADER, TOKEN_KEY_HEADER } from 'config/api';
import { NETWOKMB } from 'config/env';
import { ResponseBase } from 'config/type';
import { AES, enc } from 'crypto-js';
//import * as ethUtil from 'ethereumjs-util';
import { motion } from 'framer-motion';
import { IMessageSign } from 'interfaces/message-sign.interface';
import keccak256 from 'keccak256';
import { Icon } from 'library/components/icon';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t } from 'library/utils';
import InternalBE from 'library/utils/backend/internal';
import { mapMsgError } from 'library/utils/common';
import eventBus from 'library/utils/eventBus';
import { isEmpty, isEqual } from 'lodash';
import { AuthorizationLink, MessageSign, UserInfo } from 'model/home';
import moment from 'moment';
import Stack from 'react-bootstrap/Stack';
import { BsTelegram } from 'react-icons/bs';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { appActions, homeActions, userActions } from 'redux/action-slice';
import ROUTES from 'routes/constant';
import { generateNewKeyPair, getSharedKey } from 'services/crypto.service';
import { useAccount, useDisconnect, useSignMessage, useSwitchNetwork } from 'wagmi';

interface NavbarProps {
  is_watch_address?: boolean;
}

export const ConnectWallet = forwardRef(({ is_watch_address }: NavbarProps, ref) => {
  const messageSign = useRef<IMessageSign>();
  const { open } = useWeb3Modal();
  const { colorMode } = useColorMode();
  const [openWallet, setOpenWallet] = useState(false);
  const [show, setShow] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isAcceptTerm, setIsAcceptTerm] = useState(false);
  const [, setRefreshToken, removeCookie] = useCookies([R.strings.TOKEN_INFORMATION]);
  const isCurrentActionDisconnect = useRef(false);
  const typeSheetRef = useRef<'switchNetwork' | 'signMessage' | 'signDecryptMessage' | undefined>();
  const user = useRef<UserInfo>();
  const sheetRef = useRef<BottomSheetRef>(null);
  const acceptOpenSign = useRef(true);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const { isConnected, connector, address, isDisconnected } = useAccount();
  const telegramURL = process.env.REACT_APP_TELEGRAM_BOT || '';
  const [messageApi, contextHolder] = message.useMessage();
  const {
    userInfo,
    addressAccount,
    privateKeys,
    action,
    isConnected: isUserConnected,
    dtmpPublicKey,
    token,
  } = useSelector(x => x.app);
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const location = useLocation();
  // Parse the query string to get the parameters
  const queryParams = new URLSearchParams(location.search);
  const invitationRef = queryParams.get('ref');
  const walletProcess = useRef('');
  const [openSign, setOpenSign] = useState(false);
  const blockMultipleCount = useRef(0);
  const { switchNetworkAsync } = useSwitchNetwork();
  const { signMessageAsync } = useSignMessage({
    async onSuccess(data) {
      console.log(messageSign.current, 'messageSign.currentAAA');
      if (messageSign.current) {
        const token = `${messageSign.current.message}:${data}`;

        const {
          data: { expire },
          originalAddress,
        } = messageSign.current;
        const auth = await InternalBE.authToken({
          grant_type: 'signature',
          address: originalAddress,
          message: messageSign.current.message,
          signature: data,
        });
        let invitationParams = {};
        if (invitationRef) {
          invitationParams = {
            cmp: false,
            ref: invitationRef,
          };
        }
        dispatch(
          userActions.onCreateUserAddress(
            invitationParams,
            data => onCreateUserAddressSuccess({ ...data, auth }, token, expire),
            {
              [ADDRESS_KEY_HEADER]: originalAddress.toLowerCase(),
              [TOKEN_KEY_HEADER]: token,
            },
            error => {
              console.log('🚀 ~ onSuccess ~ error:', error);
              messageApi.open(mapMsgError(error));
              blockMultipleCount.current = 0;
              handleLogout();
              // if (!userInfo) {
              //   disconnect();
              //   dispatch(appActions.reset());
              // }
              // navigate(ROUTES.error);
            },
          ),
        );
      }
    },
    onError() {
      if (!userInfo) {
        disconnect();
      }

      blockMultipleCount.current = 0;
    },
  });

  const { signMessageAsync: signMessageGetEncPublicKey } = useSignMessage();
  const sleep = ms => new Promise(r => setTimeout(r, ms));
  const requestSignatureForEnc = useCallback(
    async (account: string) => {
      // await sleep(100); // add for not change code async await
      // if (messageSign.current) {
      //   return messageSign.current?.message;
      // }
      // return '';
      return await signMessageGetEncPublicKey({
        message: account,
      });
    },
    [signMessageGetEncPublicKey],
  );
  const processPrivateKey = useCallback(
    async (data: UserInfo, cb: (key: string) => void) => {
      user.current = data;
      try {
        let walletEncPublicKey = '';

        if (isMobile()) {
          openBottomSheetFn('signDecryptMessage');
          return;
        }

        walletEncPublicKey = await requestSignatureForEnc(data.wallet_address);

        const privateKeyDecrypted = decryptPrivateKey(
          walletEncPublicKey,
          data.dmtp_priv_key as string,
        );
        cb(privateKeyDecrypted);
        dispatch(
          appActions.setPrivateKey({
            privateKey: privateKeyDecrypted,
            wallet_address: data.wallet_address,
          }),
        );
        if (privateKeyDecrypted) {
          await getAllMessageEncryptedByDMTPAndReNew(privateKeyDecrypted);
        }

        return;
      } catch (e) {
        messageApi.open(mapMsgError(e));
        console.log(e, 'error=>processPrivateKey');
        //await processPrivateKey(data, cb);
      }
    },
    [requestSignatureForEnc, messageApi],
  );
  const handlePrivateKey = useCallback(
    (cb: (key: string) => void) => {
      if (userInfo) {
        processPrivateKey(userInfo, cb);
      }
    },
    [userInfo, processPrivateKey],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        handlePrivateKey,
      };
    },
    [handlePrivateKey],
  );

  useEffect(() => {
    isCurrentActionDisconnect.current = isUserConnected ? !isUserConnected : true;
  }, [isUserConnected]);
  useEffect(() => {
    const openConnectModal = () => {
      setOpenWallet(!openWallet);
    };

    eventBus.on('openConnectModal', openConnectModal);

    return () => {
      eventBus.off('openConnectModal', openConnectModal);
    };
  }, [openWallet]);
  useLayoutEffect(() => {
    acceptOpenSign.current = action ? false : true;
  }, [action]);
  const handleLogout = useCallback(async () => {
    removeCookie(R.strings.TOKEN_INFORMATION);
    dispatch(appActions.reset());
    disconnect();
  }, [removeCookie, disconnect]);
  const processWallet = useCallback(
    async (account: string) => {
      try {
        // if (!connector) {
        //   blockMultipleCount.current = 0;
        //   return;
        // }
        // const providerCurrent = await connector?.getProvider();
        // const web3 = new Web3(providerCurrent);
        // const chain_current = await web3.eth.getChainId();
        // if (chain_current !== NETWOKMB.chainIdNumber && connector) {
        //   await switchNetworkAsync?.(NETWOKMB.chainIdNumber);
        // }
        if (isMobile()) {
          const response: ResponseBase<MessageSign> | null = await NetWorkService.Get({
            url: ApiConstants.GET_MESSAGE_SIGN,
            params: { address: account },
          });

          if (response?.data) {
            messageSign.current = { ...response.data, originalAddress: account.toLowerCase() };
            openBottomSheetFn('signMessage');
          }
        } else {
          console.log(account, 'account');
          if (blockMultipleCount.current === 1) {
            const response: ResponseBase<MessageSign> | null = await NetWorkService.Get({
              url: ApiConstants.GET_MESSAGE_SIGN,
              params: { address: account },
            });
            console.log(response?.data, 'response?.data');
            if (response?.data) {
              messageSign.current = { ...response.data, originalAddress: account.toLowerCase() };
              console.log(messageSign.current, ' messageSign.current');
              await signMessageAsync({ message: response.data.message });
              dispatch(appActions.onSetAddress(messageSign.current.originalAddress.toLowerCase()));
            }

            blockMultipleCount.current = 0;
          }
        }
      } catch (e: any) {
        console.log(e, 'processWallet=>e');
        if (e.code === 4001) {
          handleLogout();
        }
      }
    },
    [connector, signMessageAsync, switchNetworkAsync, handleLogout],
  );
  const processAccountChange = useCallback(
    async (address: string) => {
      if (
        address &&
        isConnected &&
        userInfo &&
        !isEqual(address.toLowerCase(), userInfo.wallet_address.toLowerCase())
        // && !isEqual(addressAccount, address.toLowerCase())
      ) {
        /** Tran Tran - sign msg when account change => to run Marketplace|Key service */
        // if (isCurrentAccountHasPrivateKey(address.toLowerCase())) {
        //   processAccountHasPrivateKey(address);
        //   return;
        // }

        if (isMobile()) {
          await processWallet(address);
          return;
        }

        walletProcess.current = address;
        if (acceptOpenSign.current) setOpenSign(true);
      }
    },
    [userInfo, isConnected, processWallet],
  );

  useLayoutEffect(() => {
    if (connector) {
      connector.on('change', async event => {
        if (event.account) {
          await processAccountChange(event.account);
        }
      });

      connector.on('disconnect', () => {
        isCurrentActionDisconnect.current = true;
        dispatch(appActions.reset());
        removeCookie(R.strings.TOKEN_INFORMATION);
      });
    }

    return () => {
      connector?.off('change');
      connector?.off('disconnect');
    };
  }, [connector, processAccountChange, removeCookie]);

  useEffect(() => {
    if (
      address &&
      isConnected &&
      !addressAccount &&
      !isCurrentActionDisconnect.current &&
      !isFirstCall &&
      is_watch_address
    ) {
      if (blockMultipleCount.current === 0) {
        blockMultipleCount.current += 1;
      }
      processWallet(address);
    }
    setIsFirstCall(false);
    if (isCurrentActionDisconnect.current && isDisconnected) {
      isCurrentActionDisconnect.current = false;
    }
  }, [
    address,
    isConnected,
    addressAccount,
    isDisconnected,
    isFirstCall,
    is_watch_address,
    processWallet,
  ]);

  const processAccountHasPrivateKey = useCallback(
    (address: string) => {
      dispatch(
        userActions.onCreateUserAddress(
          undefined,
          data => {
            dispatch(appActions.onSetUserInfo(data));
            dispatch(appActions.onSetAddress(data.wallet_address));
            dispatch(appActions.onSetChatSelectedAccount(data.wallet_address));

            // onAuthorization(address);
          },
          {
            [ADDRESS_KEY_HEADER]: address.toLowerCase(),
            [TOKEN_KEY_HEADER]: token ?? '',
          },
          () => {
            blockMultipleCount.current = 0;
            if (!userInfo) {
              disconnect();
              dispatch(appActions.reset());
            }
            navigate(ROUTES.error);
          },
        ),
      );
    },
    [token, userInfo, disconnect, navigate],
  );

  const isCurrentAccountHasPrivateKey = (address: string) => {
    return privateKeys.some(
      pair => isEqual(pair.wallet_address, address) && !isEmpty(pair.privateKey),
    );
  };

  const switchNetworkFn = async () => {
    setOpenBottomSheet(false);
    await switchNetworkAsync?.(NETWOKMB.chainIdNumber);
  };

  const signMessageFn = async () => {
    try {
      setOpenBottomSheet(false);
      if (messageSign.current) {
        await signMessageAsync({ message: messageSign.current.message });
        dispatch(appActions.onSetAddress(messageSign.current.originalAddress.toLowerCase()));
      }
    } catch (error) {
      console.log('signMessageFn=>error', error);
    }
  };

  const isPrivateKeyValid = async (localPrivateKey: string) => {
    const response = await NetWorkService.Get<{
      message_data: string;
      dmtp_pub_key: string;
    }>({
      url: ApiConstants.GET_ENCRYPTED_MESSAGE,
    });

    if (response?.data) {
      const sharedKey = getSharedKey(localPrivateKey, response.data.dmtp_pub_key);

      const message = decryptTempMessage(response.data.message_data, sharedKey);

      return isEqual(message, 'hello');
    }

    return false;
  };

  const decryptHandle = async (signature: string, dmtp_priv_key: string) => {
    try {
      const privateKeyDecrypted = await decryptPrivateKey(signature, dmtp_priv_key);

      const isValid = await isPrivateKeyValid(privateKeyDecrypted);

      if (!isValid) {
        await decryptHandle(signature, dmtp_priv_key);
      } else {
        return privateKeyDecrypted;
      }
    } catch (e) {
      await decryptHandle(signature, dmtp_priv_key);
    }
  };

  const signDecryptMessageFn = async () => {
    setOpenBottomSheet(false);
    if (user.current) {
      const signature = await requestSignatureForEnc(user.current.wallet_address);

      let localPrivateKey = '';
      if (!user.current.dmtp_pub_key || !user.current.dmtp_priv_key) {
        localPrivateKey = await getPrivateKeyHandle(signature, user.current.wallet_address);
      }

      if (user.current.dmtp_priv_key) {
        const privateKeyDecrypted = await decryptPrivateKey(signature, user.current.dmtp_priv_key);
        localPrivateKey = privateKeyDecrypted;
        if (!localPrivateKey) {
          localPrivateKey = await getPrivateKeyHandle(signature, user.current.wallet_address);
        }
        dispatch(
          appActions.setPrivateKey({
            privateKey: localPrivateKey,
            wallet_address: user.current.wallet_address,
          }),
        );
      }

      if (localPrivateKey && user.current.dmtp_priv_key) {
        const isValid = await isPrivateKeyValid(localPrivateKey);
        if (!isValid) {
          localPrivateKey = await getPrivateKeyHandle(signature, user.current.wallet_address);
        }
      }

      if (localPrivateKey) {
        await getAllMessageEncryptedByDMTPAndReNew(localPrivateKey);
      }
      console.log(user.current, 'user.current');
      dispatch(appActions.onSetUserInfo(user.current));
      dispatch(appActions.onSetAddress(user.current.wallet_address));
      dispatch(appActions.onSetChatSelectedAccount(user.current.wallet_address));

      user.current = undefined;
      typeSheetRef.current = undefined;
    }
  };

  const openBottomSheetFn = (typeSheet: 'switchNetwork' | 'signMessage' | 'signDecryptMessage') => {
    typeSheetRef.current = typeSheet;
    setOpenBottomSheet(true);
    sheetRef.current?.snapTo(({ maxHeight }) => maxHeight);
  };

  const onDiscordAuthorizationSucceeded = async (response: AuthorizationLink, address: string) => {
    if (!response.is_have_discord_information) {
      window.open(response.authorization_link, '_self', 'noopener,noreferrer');
    } else {
      dispatch(
        homeActions.onGetUserInfo(response => {
          dispatch(appActions.onSetUserInfo(response));
        }),
      );
      // WhiteList
      // const permissionResponse = await NetWorkService.Get<WHITELIST_PERMISSION>({
      //   url: ApiConstants.CHECK_WHITELIST,
      //   params: {
      //     address,
      //   },
      // });

      const accepted = true;

      dispatch(appActions.setIsWhitelist(true));
      // if (permissionResponse?.data) {
      //   const { is_exist } = permissionResponse.data;
      //   dispatch(appActions.setIsWhitelist(is_exist));

      //   if (!is_exist) {
      //     accepted = false;
      //   }
      // }
      if (!location.pathname.includes(ROUTES.messages) && accepted) {
        navigate(ROUTES.messages);
      }

      if (!accepted && location.pathname.includes(ROUTES.messages)) {
        navigate(ROUTES.error);
      }
    }
  };

  const onAuthorization = async (address: string) => {
    const redirect_uri = getRedirectUri('home');
    dispatch(
      homeActions.onDiscordAuthorization({ redirect_uri }, data =>
        onDiscordAuthorizationSucceeded(data, address),
      ),
    );
  };

  const encryptPrivateKey = (signature: string, text: string) => {
    // const result = sigUtil.encrypt({
    //   publicKey,
    //   data: text,
    //   version: 'x25519-xsalsa20-poly1305',
    // });
    // return ethUtil.bufferToHex(Buffer.from(JSON.stringify(result), 'utf8'));
    return AES.encrypt(text, keccak256(signature).toString('hex')).toString();
  };

  const decryptPrivateKey = (signature: string, text: string) => {
    if (!signature || !text) {
      return '';
    }
    // const result = await (window as any).ethereum.send('eth_decrypt', [text, account]);
    // return result.result;
    try {
      return AES.decrypt(text, keccak256(signature).toString('hex')).toString(enc.Utf8);
    } catch (error) {
      console.log(error, 'error=>decryptPrivateKey');
      return '';
    }
  };

  const submitKeyPair = async ({
    publicKey,
    privateKey,
  }: {
    publicKey: string;
    privateKey: string;
  }) => {
    try {
      eventBus.emit('isLoadingSubmitKey', true);
      await NetWorkService.Post({
        url: ApiConstants.SUBMIT_KEY_PAIR,
        body: {
          dmtp_pub_key: publicKey,
          dmtp_priv_key: privateKey,
        },
      });
      eventBus.emit('isLoadingSubmitKey', false);
    } catch (error: any) {
      console.log(error.message);
      eventBus.emit('isLoadingSubmitKey', false);
    }
  };

  const getAllMessageEncryptedByDMTPAndReNew = async (privateKey: string) => {
    try {
      const resAllMessage = await NetWorkService.Get<any[]>({
        url: ApiConstants.GET_ALL_ENCRYPTED_MESSAGE_WITH_DMTP,
      });
      if (resAllMessage?.data && resAllMessage.data.length > 0) {
        const encMessages = resAllMessage.data.map((room: any) => {
          const sharedDmtpKey = getSharedKey(privateKey, room.dmtp_pub_key);
          const sharedKey = getSharedKey(privateKey, room.pub_key);
          return {
            room_id: room.room_id,
            messages: room.message.map((message: any) => {
              const decMessage = decryptMessage(message.message_data, sharedDmtpKey);
              const encMessage = encryptMessage(decMessage, sharedKey);
              return {
                ...message,
                message_data: encMessage,
              };
            }),
          };
        });
        await NetWorkService.Post({
          url: ApiConstants.RENEW_MESSAGE,
          body: encMessages,
        });
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPrivateKeyHandle = async (
    signature: string,
    wallet_address: string,
  ): Promise<string> => {
    const { privateKey, publicKey } = generateNewKeyPair();
    const privateKeyEncrypted = encryptPrivateKey(signature, privateKey);
    await submitKeyPair({ publicKey, privateKey: privateKeyEncrypted });
    console.log('getPrivateKeyHandle');
    dispatch(
      appActions.setPrivateKey({
        privateKey,
        wallet_address,
      }),
    );
    return privateKey;
  };

  const onCreateUserAddressSuccess = async (data: UserInfo, token: string, expire: number) => {
    if (data.wallet_address) {
      setRefreshToken(R.strings.TOKEN_INFORMATION, token, {
        expires: moment(expire).toDate(),
        path: '/',
      });
      dispatch(appActions.onSetToken({ access_token: token }));

      user.current = data;
      console.log(user.current, 'user.current');
      // if not init yet
      try {
        let walletEncPublicKey = '';
        const walletPrivateKey = privateKeys.find(p =>
          isEqual(p.wallet_address, data.wallet_address),
        );

        let localPrivateKey = walletPrivateKey?.privateKey || '';

        if (!data.dmtp_pub_key || !data.dmtp_priv_key || (data.dmtp_priv_key && !localPrivateKey)) {
          if (isMobile()) {
            openBottomSheetFn('signDecryptMessage');
            return;
          }

          walletEncPublicKey = await requestSignatureForEnc(data.wallet_address);
          console.log(data, 'dataAAAA');
          if (!data.dmtp_pub_key || !data.dmtp_priv_key) {
            localPrivateKey = await getPrivateKeyHandle(walletEncPublicKey, data.wallet_address);
          }
          // if init already
          if (data.dmtp_priv_key && !localPrivateKey) {
            localPrivateKey = decryptPrivateKey(walletEncPublicKey, data.dmtp_priv_key);
            if (!localPrivateKey) {
              localPrivateKey = await getPrivateKeyHandle(walletEncPublicKey, data.wallet_address);
            }
            dispatch(
              appActions.setPrivateKey({
                privateKey: localPrivateKey,
                wallet_address: data.wallet_address,
              }),
            );
          }

          if (localPrivateKey) {
            await getAllMessageEncryptedByDMTPAndReNew(localPrivateKey);
          }
        }

        if (localPrivateKey && data.dmtp_priv_key) {
          const isValid = await isPrivateKeyValid(localPrivateKey);
          if (!isValid) {
            walletEncPublicKey = await requestSignatureForEnc(data.wallet_address);
            localPrivateKey = await getPrivateKeyHandle(walletEncPublicKey, data.wallet_address);
            dispatch(
              appActions.setPrivateKey({
                privateKey: localPrivateKey,
                wallet_address: data.wallet_address,
              }),
            );
            await getAllMessageEncryptedByDMTPAndReNew(localPrivateKey);
          }
        }
        dispatch(appActions.onSetUserInfo(data));
        dispatch(appActions.onSetAuthenKey(data?.auth));
        dispatch(appActions.onSetAddress(data.wallet_address));
        dispatch(appActions.onSetChatSelectedAccount(data.wallet_address));

        if (!data.telegram && !data.discord) {
          setShow(true);
        } else {
          navigate(ROUTES.messages);
        }

        // await onAuthorization(data.wallet_address);
      } catch (error) {
        console.log('onCreateUserAddressSuccess=>error', error);
        if (!userInfo) {
          handleLogout();
        }

        return;
      }
    }
  };
  const onChangeAccept = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setIsAcceptTerm(e.target.checked);
  };

  return (
    <>
      {contextHolder}
      {(!userInfo || privateKeys?.length === 0) && (
        <Button
          w="150px"
          onClick={() => {
            disconnect();
            dispatch(appActions.reset());
            // window.localStorage.clear();
            setOpenWallet(!openWallet);
          }}
          bgGradient="linear(to-l, #7c4998, #5497cc)"
          color="white"
          _hover={{
            bgGradient: 'linear(to-r, #5497cc, #7c4998)',
            boxShadow: '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
          }}
          _active={{
            bgGradient: 'linear(to-r, #7c4998, #5497cc)',
            boxShadow:
              'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
          }}
          boxShadow="0 0 8px rgba(124, 73, 152, 0.6), 0 0 12px rgba(84, 151, 204, 0.6)"
          borderRadius="md"
          fontWeight="bold"
          size="md"
          // rightIcon={<SendIcon />}
          transition="all 0.2s ease-in-out">
          {$t('messages:btn_launch_app')}
        </Button>
      )}
      <Modal
        open={openWallet}
        width={300}
        centered
        title={
          <h4 className="font-sans font-semibold leading-5 tracking-wider text-center text-sm">
            {$t('messages:btn_connect_wallet')}
          </h4>
        }
        okButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => setOpenWallet(false)}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className="pt-3 pb-2 flex justify-center">
          <Button
            onClick={() => {
              open();
              setOpenWallet(false);
            }}
            isDisabled={!isAcceptTerm}
            className="font-sans tracking-wider font-medium !text-sm"
            color={'white'}
            bg={'messenger.400'}
            _hover={{
              opacity: '0.9',
            }}
            _focus={{
              background: 'messenger.400',
            }}
            leftIcon={<Icon size={44} icon="walletConnectRounded" />}>
            {$t('messages:btn_walletconnect')}
          </Button>
        </div>
        <div className="term-section">
          <Checkbox onChange={onChangeAccept} checked={isAcceptTerm}>
            {$t('home:wallet_connect_checkbox_label')}
            <span style={{ margin: '0 4px' }}>
              <Link
                color={'blue.400'}
                colorScheme={'blue'}
                isExternal
                rel="noopener,noreferrer"
                href={ROUTES.tos}>
                {$t('home:termsOfUse')}
              </Link>
            </span>
            <span style={{ margin: '0 4px' }}>
              {' '}
              {$t('home:wallet_connect_checkbox_label_and')}{' '}
            </span>
            <Link
              color={'blue.400'}
              colorScheme={'blue'}
              isExternal
              rel="noopener,noreferrer"
              href={ROUTES.privacyPolicies}>
              {$t('home:PrivacyPolicy')}
            </Link>
          </Checkbox>
        </div>
      </Modal>
      <Modal
        open={openSign}
        width={400}
        closable={false}
        centered
        // title={<h6 className="font-semibold text-center">Sign message</h6>}
        maskClosable={false}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className="flex flex-col gap-y-3 items-center justify-center pr-3 w-full">
          <Avatar src="/logo512.png" size={48} />
          <span>
            {/* By connecting your wallet, you agree our Terms of Service and Privacy Policy. */}
            {$t('messages:confirm_sign_message', {
              address: formatDotAddress(walletProcess.current.toLowerCase()),
            })}
          </span>
          <div className="flex justify-between items-center w-full gap-x-4">
            <motion.button
              onClick={() => {
                setOpenSign(false);
              }}
              className="text-center py-1 px-3 rounded-full w-1/2 border cursor-pointer">
              {$t('messages:btn_cancel')}
            </motion.button>
            <motion.button
              onClick={async () => {
                setOpenSign(false);
                blockMultipleCount.current += 1;
                console.log('sign change account');
                await processWallet(walletProcess.current);
              }}
              className="text-center py-1 px-3 bg-blue-500 text-white rounded-full w-1/2 cursor-pointer">
              {$t('messages:btn_sign')}
            </motion.button>
          </div>
        </div>
      </Modal>

      <Modal
        open={show}
        closable={false}
        centered
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div>
          <Heading>{$t('messages:modal_noti_sns:title')}</Heading>
          <Text>{$t('messages:modal_noti_sns:description')}</Text>
          <Flex justifyContent={'center'}>
            <Center p={6}>
              <Box
                w={'200px'}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={6}
                textAlign={'center'}
                color={colorMode === 'dark' ? 'white' : 'black'}>
                <Heading fontSize={'2xl'} fontFamily={'body'}>
                  Telegram
                </Heading>

                <Stack
                  gap={3}
                  className="justify-content-between align-items-center pt-3 pb-2 h-100">
                  <Center>
                    <BsTelegram color="#0088cc" size={35} />
                  </Center>

                  <Center>
                    <Stack gap={2} className="justify-content-center align-items-center">
                      {user.current?.telegram ? (
                        <Center mt={5}>
                          <IoCheckmarkCircle size={24} color="#0af167" />
                        </Center>
                      ) : (
                        <React.Fragment>
                          <Button
                            mt={5}
                            size="sm"
                            onClick={() => {
                              window.open(telegramURL, '_blank', 'noopener,noreferrer');
                              if (location.pathname == ROUTES.home) {
                                navigate(ROUTES.messages);
                              }
                            }}
                            style={{ backgroundColor: '#6559f5' }}
                            color={'white'}>
                            {$t('messages:btn_connect')}
                          </Button>
                        </React.Fragment>
                      )}
                    </Stack>
                  </Center>
                </Stack>
              </Box>
            </Center>
            {/* <Center p={6}>
              <Box
                w={'200px'}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={6}
                textAlign={'center'}
                color={colorMode === 'dark' ? 'white' : 'black'}>
                <Heading fontSize={'2xl'} fontFamily={'body'}>
                  Discord
                </Heading>

                <Stack
                  gap={3}
                  className="justify-content-between align-items-center pt-3 h-100 pb-2">
                  <Center>
                    <BsDiscord color="#5865f2" size={35} />
                  </Center>
                  <Stack gap={2} className="justify-content-end align-items-center">
                    {user.current?.discord ? (
                      <Center mt={5}>
                        <IoCheckmarkCircle size={24} color="#0af167" />
                      </Center>
                    ) : (
                      <Button
                        size="sm"
                        mt={5}
                        onClick={() => {
                          // window.open(discordURL, '_blank', 'noopener,noreferrer');
                          if (!user.current?.wallet_address) return;
                          onAuthorization(user.current?.wallet_address);
                        }}
                        style={{ backgroundColor: '#6559f5' }}
                        color={'white'}>
                        Connect
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Center> */}
          </Flex>
          <Center>
            {location.pathname.includes(ROUTES.marketplace) ? (
              <Button
                onClick={() => {
                  setShow(false);
                }}>
                {$t('messages:modal_noti_sns:btn_Continue_without_connecting')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setShow(false);
                  navigate(ROUTES.messages);
                }}>
                {$t('messages:modal_noti_sns:btn_Continue_without_connecting')}
              </Button>
            )}
          </Center>
        </div>
      </Modal>

      <BottomSheet
        open={openBottomSheet}
        maxHeight={250}
        ref={sheetRef}
        onDismiss={() => {
          if (
            typeSheetRef.current === 'signMessage' ||
            typeSheetRef.current === 'signDecryptMessage'
          ) {
            handleLogout();
          }
          setOpenBottomSheet(false);
        }}>
        <div className="w-full h-[200px] flex flex-col justify-center pr-2 pl-2 items-center gap-y-4">
          {typeSheetRef.current === 'signMessage' && (
            <React.Fragment>
              <h5 className="text-base font-semibold text-black bottom-sheet-title">
                {$t('messages:sign_message')}
              </h5>
              <motion.button
                whileTap={{
                  scale: 1.1,
                }}
                onClick={signMessageFn}
                className="w-full py-4 px-2 bg-blue-600 text-white rounded-full text-sm bottom-sheet-btn">
                {$t('messages:btn_confirm')}
              </motion.button>
            </React.Fragment>
          )}

          {typeSheetRef.current === 'signDecryptMessage' && (
            <React.Fragment>
              <h5 className="text-base font-semibold text-black bottom-sheet-title">
                {$t('messages:sign_message')}
              </h5>
              <motion.button
                whileTap={{
                  scale: 1.1,
                }}
                onClick={signDecryptMessageFn}
                className="w-full py-4 px-2 bg-blue-600 text-white rounded-full text-sm bottom-sheet-btn">
                {$t('messages:btn_confirm')}
              </motion.button>
            </React.Fragment>
          )}

          {typeSheetRef.current === 'switchNetwork' && (
            <React.Fragment>
              <h5 className="text-base font-semibold text-black bottom-sheet-title">
                {$t('messages:confirm_switch_network', { network: NETWOKMB.chainName })}{' '}
              </h5>
              <motion.button
                whileTap={{
                  scale: 1.1,
                }}
                onClick={switchNetworkFn}
                className="w-full py-4 px-2 bg-blue-600 text-white rounded-full text-sm bottom-sheet-btn">
                {$t('messages:btn_switch')}
              </motion.button>
            </React.Fragment>
          )}
        </div>
      </BottomSheet>
    </>
  );
});
