import { useState } from 'react';

import { IconButton, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import i18n, { getCurrentLanguage } from 'library/utils/i18n/i18n';
import { MdLanguage } from 'react-icons/md';

interface LanguageInfo {
  name: string;
  flag: string;
}

interface Languages {
  [key: string]: LanguageInfo;
}

export const LanguageSwitching = ({ colorBtn }: { colorBtn?: string }) => {
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  const changeLanguage = (languageCode: string) => {
    window.localStorage.setItem('userLanguage', languageCode);
    i18n.changeLanguage(languageCode);
  };
  const handleLanguageChange = (languageCode: string) => {
    if (currentLanguage == languageCode) {
      return;
    }
    setCurrentLanguage(languageCode);
    changeLanguage(languageCode);
    window.location.reload();
  };
  const languages: Languages = {
    en: { name: 'English', flag: 'https://flagcdn.com/us.svg' },
    ja: { name: '日本語', flag: 'https://flagcdn.com/jp.svg' },
  };
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MdLanguage size={32} />}
        variant="outline"
        color={colorBtn ? colorBtn : 'white'}
        _active={{ bg: 'whiteAlpha.300' }}
        _hover={{ bg: 'whiteAlpha.300' }}
      />
      <MenuList>
        {Object.entries(languages).map(([code, { name, flag }]) => (
          <MenuItem
            key={code}
            _disabled={{ bg: 'transparent', color: '#7E4698', fontWeight: 600 }}
            isDisabled={currentLanguage == code}
            onClick={() => handleLanguageChange(code)}>
            <Image
              w="24px"
              mr="2"
              src={flag}
              alt={name}
              boxShadow={'0 2px 4px 0 rgba(126,70,152,.4);'}
            />
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
