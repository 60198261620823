/* LAYOUT DEFAULT COMPONENT STYLES
   ========================================================================== */

import styled from 'styled-components';

const StyledV2 = {
  Main: styled.main`
    width: 100%;
    min-height: calc(100vh);
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
  `,
};

export default StyledV2;
