import { call, execFunc, put, showErrorApi, takeLatest } from 'common';
import { ApiConstants, NetWorkResponseType, NetWorkService } from 'library/networking';
import { UserInfo, UserInfoExtend } from 'model/home';
import { Group, MessageRoom, MessageRoomWithFriendStatus, Room } from 'model/messages';
import { RoomSharedKey } from 'model/room.interface';
import { Action } from 'redux';
import { appActions, roomActions } from 'redux/action-slice';

export function* roomSaga() {
  yield* takeLatest(roomActions.onGetRoomList.type, onGetRoomList);
  yield* takeLatest(roomActions.onGetPublicRoomList.type, onGetPublicRoomList);
  yield* takeLatest(roomActions.onCreateRoom.type, onCreateRoom);
  yield* takeLatest(roomActions.onJoinPublicGroup.type, onJoinPublicGroup);
  yield* takeLatest(roomActions.onGetMessageInRoom.type, onGetMessageInRoom);
  yield* takeLatest(roomActions.onSendMessage.type, onSendMessage);
  yield* takeLatest(roomActions.onSendBatchMessage.type, onSendBatchMessage);
  yield* takeLatest(roomActions.onGetUserByAddress.type, onGetUserByAddress);
  yield* takeLatest(roomActions.onGetUserByName.type, onGetUserByName);
  yield* takeLatest(roomActions.onGetRoomId.type, onGetRoomId);
  yield* takeLatest(roomActions.onDeleteMessage.type, onDeleteMessage);
  yield* takeLatest(roomActions.onReactMessage.type, onReactMessage);
  yield* takeLatest(roomActions.onRemoveReactMessage.type, onRemoveReactMessage);
  yield* takeLatest(roomActions.onUpdateMessage.type, onUpdateMessage);
  yield* takeLatest(roomActions.onRemoveRoom.type, onRemoveRoom);
  yield* takeLatest(roomActions.onRenameRoom.type, onRenameRoom);
  yield* takeLatest(roomActions.onReadMessageRoom.type, onReadMessageRoom);
  yield* takeLatest(roomActions.onSetRoleOfRoom.type, onSetRoleOfRoom);
  yield* takeLatest(roomActions.onLeaveRoom.type, onLeaveRoom);
  yield* takeLatest(roomActions.onUpdateRoom.type, onUpdateRoom);
  yield* takeLatest(roomActions.onGetRoomInfo.type, onGetRoomInfo);
  yield* takeLatest(roomActions.getSharedKeys.type, getSharedKeys);
  yield* takeLatest(roomActions.getLatestSharedKeys.type, getLatestSharedKeys);
  //limit
  yield* takeLatest(roomActions.onGetMessageInRoomLimit.type, onGetMessageInRoomLimit);
  yield* takeLatest(roomActions.onSendMessageGroupLimit.type, onSendMessageGroupLimit);
  yield* takeLatest(roomActions.onUpdateMessageGroupLimit.type, onUpdateMessageGroupLimit);
  //unlimit
  yield* takeLatest(roomActions.onGetMessageInRoomUnlimit.type, onGetMessageInRoomUnlimit);
  yield* takeLatest(roomActions.onSendMessageGroupUnlimit.type, onSendMessageGroupUnlimit);
  yield* takeLatest(roomActions.onUpdateMessageGroupUnlimit.type, onUpdateMessageGroupUnlimit);
}

function* onCreateRoom(action: Action) {
  if (roomActions.onCreateRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.CREATE_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onJoinPublicGroup(action: Action) {
  if (roomActions.onJoinPublicGroup.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Group>>(NetWorkService.Post, {
      url: ApiConstants.JOIN_PUBLIC_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Group);
  }
}

function* onRemoveRoom(action: Action) {
  if (roomActions.onRemoveRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.REMOVE_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onRenameRoom(action: Action) {
  if (roomActions.onRenameRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.RENAME_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onSendMessage(action: Action) {
  if (roomActions.onSendMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Post, {
      url: ApiConstants.SEND_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onSendBatchMessage(action: Action) {
  if (roomActions.onSendBatchMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom[]>>(NetWorkService.Post, {
      url: ApiConstants.SEND_BATCH_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom[]);
  }
}

function* onUpdateMessage(action: Action) {
  if (roomActions.onUpdateMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Patch, {
      url: ApiConstants.UPDATE_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onReactMessage(action: Action) {
  if (roomActions.onReactMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Patch, {
      url: ApiConstants.REACT_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onRemoveReactMessage(action: Action) {
  if (roomActions.onRemoveReactMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Patch, {
      url: ApiConstants.REMOVE_REACT_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onReadMessageRoom(action: Action) {
  if (roomActions.onReadMessageRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.READ_MESSAGE_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onDeleteMessage(action: Action) {
  if (roomActions.onDeleteMessage.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Delete, {
      url: ApiConstants.DELETE_MESSAGE,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onGetRoomList(action: Action) {
  if (roomActions.onGetRoomList.match(action)) {
    const { params, onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<Room>>>(NetWorkService.Get, {
      url: ApiConstants.GET_ROOM_LIST,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<Room>);
  }
}

function* onGetMessageInRoom(action: Action) {
  if (roomActions.onGetMessageInRoom.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoomWithFriendStatus>>(
      NetWorkService.Get,
      {
        url: ApiConstants.GET_MESSAGE_IN_ROOM,
        params,
      },
    );
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoomWithFriendStatus);
  }
}

function* onGetUserByAddress(action: Action) {
  if (roomActions.onGetUserByAddress.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo[]>>(NetWorkService.Get, {
      url: ApiConstants.GET_USER_BY_ADDRESS,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfoExtend[]);
  }
}

function* onGetUserByName(action: Action) {
  if (roomActions.onGetUserByName.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<UserInfo[]>>(NetWorkService.Get, {
      url: ApiConstants.GET_USER_BY_NAME,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as UserInfo[]);
  }
}

function* onGetRoomId(action: Action) {
  if (roomActions.onGetRoomId.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<string>>(NetWorkService.Get, {
      url: ApiConstants.GET_ROOM_ID,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as string);
  }
}

function* onGetPublicRoomList(action: Action) {
  if (roomActions.onGetPublicRoomList.match(action)) {
    const { params, onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<Group>>>(NetWorkService.Get, {
      url: ApiConstants.GET_PUBLIC_ROOM_LIST,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<Group>);
  }
}

// limit

function* onGetMessageInRoomLimit(action: Action) {
  if (roomActions.onGetMessageInRoomLimit.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<MessageRoom>>>(NetWorkService.Get, {
      url: ApiConstants.GET_MESSAGE_GROUP_LIMIT,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<MessageRoom>);
  }
}

function* onSendMessageGroupLimit(action: Action) {
  if (roomActions.onSendMessageGroupLimit.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Post, {
      url: ApiConstants.SEND_MESSAGE_GROUP_LIMIT,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onUpdateMessageGroupLimit(action: Action) {
  if (roomActions.onUpdateMessageGroupLimit.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Patch, {
      url: ApiConstants.UPDATE_MESSAGE_GROUP_LIMIT,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

//unlimit
function* onGetMessageInRoomUnlimit(action: Action) {
  if (roomActions.onGetMessageInRoomUnlimit.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Array<MessageRoom>>>(NetWorkService.Get, {
      url: ApiConstants.GET_MESSAGE_GROUP_UNLIMIT,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Array<MessageRoom>);
  }
}

function* onSendMessageGroupUnlimit(action: Action) {
  if (roomActions.onSendMessageGroupUnlimit.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Post, {
      url: ApiConstants.SEND_MESSAGE_GROUP_UNLIMIT,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onUpdateMessageGroupUnlimit(action: Action) {
  if (roomActions.onUpdateMessageGroupUnlimit.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<MessageRoom>>(NetWorkService.Patch, {
      url: ApiConstants.UPDATE_MESSAGE_GROUP_UNLIMIT,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as MessageRoom);
  }
}

function* onSetRoleOfRoom(action: Action) {
  if (roomActions.onSetRoleOfRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.SET_ROLE_GROUP,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onLeaveRoom(action: Action) {
  if (roomActions.onLeaveRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Post, {
      url: ApiConstants.REMOVE_USER_OF_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onUpdateRoom(action: Action) {
  if (roomActions.onUpdateRoom.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room>>(NetWorkService.Patch, {
      url: ApiConstants.UPDATE_ROOM,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as Room);
  }
}

function* onGetRoomInfo(action: Action) {
  if (roomActions.onGetRoomInfo.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<Room | { data: Room }>>(NetWorkService.Get, {
      url: ApiConstants.GET_ROOM_INFO,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }

    execFunc(onSucceeded, response.data as Room);
  }
}

function* getSharedKeys(action: Action) {
  if (roomActions.getSharedKeys.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<{ data: RoomSharedKey[] }>>(
      NetWorkService.Get,
      {
        url: ApiConstants.GET_GROUP_SHARED_KEY,
        params,
      },
    );
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }

    execFunc(onSucceeded, response.data as { data: RoomSharedKey[] });
  }
}

function* getLatestSharedKeys(action: Action) {
  if (roomActions.getLatestSharedKeys.match(action)) {
    const { onSucceeded, onFailed, params } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<{ data: RoomSharedKey }>>(NetWorkService.Get, {
      url: ApiConstants.GET_GROUP_LATEST_SHARED_KEY,
      params,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }

    execFunc(onSucceeded, response.data as { data: RoomSharedKey });
  }
}
