import { Editor, Element, Node, NodeEntry, Range, Transforms } from 'slate';

import { CustomEditor } from '../../../@types/custom-type';

export const clearEditor = (editor: CustomEditor) => {
  const { children } = editor; // Assuming editor.children is an array of nodes
  console.log(children, 'children');
  // Check if there is content before attempting to delete
  if (children && children.length > 0) {
    console.log(Editor.start(editor, []), 'Editor.start(editor, [])');
    console.log(Editor.end(editor, []), 'Editor.end(editor, [])');
    const start = Editor.start(editor, []);
    const end = Editor.end(editor, []);
    Transforms.delete(editor, {
      at: {
        anchor: start,
        focus: end,
      },
    });
  }
  // Transforms.delete(editor, {
  //   at: {
  //     anchor: Editor.start(editor, []),
  //     focus: Editor.end(editor, []),
  //   },
  // });
};

// eslint-disable-next-line no-useless-escape
export const urlBase64Regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/;
// eslint-disable-next-line no-useless-escape
export const urlBase64RegexGlobal = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g;

export const imageSrcRegex = /src=["|'](.*?)["|']/gim;

export const urlRegex =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:(https|http)?|ftp|file|blob):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:;,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

export const urlInline =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:(https|http)?|ftp|file|blob):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:;,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[A-Z0-9+&@#\/%=~_|$])/;

export const altRegex =
  /<img\b(?=\s)(?=(?:[^>=]|='[^']*'|="[^"]*"|=[^'"][^\s>]*)*?\salt=['"]([^"]*)['"]?)(?:[^>=]|='[^']*'|="[^"]*"|=[^'"\s]*)*"\s?\/?>/gi;

export const srcRegex =
  /<img\b(?=\s)(?=(?:[^>=]|='[^']*'|="[^"]*"|=[^'"][^\s>]*)*?\ssrc=['"]([^"]*)['"]?)(?:[^>=]|='[^']*'|="[^"]*"|=[^'"\s]*)*"\s?\/?>/gi;

export const imgRegex = /<img .*?><\/img>/gi;

export const imgRegexInline = /<img .*?><\/img>/i;

export const altSortRegex = /alt\s*=\s*"(.+?)"/gi;

export const findUrlsInText = (text: string): Array<{ url: string; index: number }> => {
  const matchesAll = text.matchAll(urlRegex);
  const matches = [...matchesAll];

  return matches.length > 0
    ? matches.map(match => ({ url: match[0], index: match.index as number }))
    : [];
};

export const splitByUrl = (text: string) => {
  return text.split(urlRegex);
};

export const decorator = ([node, path]: NodeEntry<Node>): Range[] => {
  const nodeText = Node.string(node);
  const isElement = Element.isElement(node);

  if (!isElement) {
    return [];
  }

  if (!nodeText) return [];

  const urls = findUrlsInText(nodeText);

  return urls.map(({ url, index }) => {
    return {
      anchor: {
        path,
        offset: index,
      },
      focus: {
        path,
        offset: index + url.length,
      },
      decoration: 'link',
    };
  });
};

export const removeParagraphEmptyTag = (html: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /<p(\s+[a-z0-9\-_\'\"=]+)*><\/p>/gi;
  // eslint-disable-next-line no-useless-escape
  const regexBr = /<br(\s+[a-z0-9\-_\'\"=]+)*>/gi;

  return html.replace(regexBr, '').replace(regex, '');
};

export const removeImageTag = (html: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /<img .*?><\/img>/gi;

  return html.replace(regex, '');
};
