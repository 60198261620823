import { createAction } from '@reduxjs/toolkit';
import { AxiosRequestHeaders } from 'axios';
import { UserInfo } from 'model/home';
import { Unread } from 'model/messages';
import { IErrorResponse } from 'model/response';
import {
  AcceptFriendRequest,
  AddOfficialAccount,
  CreateWelcomeMessage,
  FriendRequest,
  RejectFriendRequest,
  UnfriendRequest,
} from 'model/user.interface';

import * as Actions from '../action-type';

const onCreateUserAddress = createAction(
  Actions.CREATE_USER_ADDRESS,
  (
    body: unknown,
    onSucceeded: (data: UserInfo) => void,
    headers?: AxiosRequestHeaders,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
      headers,
    },
  }),
);

const onSendFriendRequest = createAction(
  Actions.SEND_FRIEND_REQUEST,
  (
    body: FriendRequest,
    onSucceeded: (data: UserInfo) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onDeleteFriendRequest = createAction(
  Actions.SEND_FRIEND_REQUEST,
  (
    body: RejectFriendRequest,
    onSucceeded: (data: UserInfo) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onRejectFriendRequest = createAction(
  Actions.REJECT_FRIEND_SUCCESS,
  (
    body: RejectFriendRequest,
    onSucceeded: (data: UserInfo) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onAcceptFriendRequest = createAction(
  Actions.ACCEPT_FRIEND_REQUEST,
  (
    body: AcceptFriendRequest,
    onSucceeded: (data: UserInfo) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUnFriendRequest = createAction(
  Actions.UNFRIEND,
  (
    body: UnfriendRequest,
    onSucceeded: (data: UserInfo) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onGetFriends = createAction(
  Actions.GET_FRIEND_LIST,
  (
    params: { filter?: string; crm?: boolean },
    onSucceeded: (data: UserInfo | UserInfo[]) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onGetFriendsRequest = createAction(
  Actions.GET_FRIEND_REQUEST,
  (onSucceeded: (data: UserInfo) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetTotalUnread = createAction(
  Actions.GET_TOTAL_UNREAD,
  (
    params: {
      addressList: string[];
    },
    onSucceeded: (data: Unread[]) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);

const onCreateOrUpdateWelcomeMessage = createAction(
  Actions.CREATE_OR_UPDATE_WELCOME_MESSAGES,
  (
    body: CreateWelcomeMessage,
    onSucceeded: (data: UserInfo) => void,
    headers?: AxiosRequestHeaders,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
      headers,
    },
  }),
);

const onUpdateUserWalletBalances = createAction(
  Actions.UPDATE_USER_WALLET_BALANCES,
  (onSucceeded: (data: UserInfo) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onAddOfficialAccount = createAction(
  Actions.ADD_OFFICIAL_ACCOUNT,
  (
    body: AddOfficialAccount,
    onSucceeded: (data: UserInfo) => void,
    headers?: AxiosRequestHeaders,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
      headers,
    },
  }),
);

const onGetWelcomeMessages = createAction(
  Actions.GET_WELCOME_MESSAGES,
  (
    params: {
      crm?: boolean;
    },
    onSucceeded: (data: Array<string>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);

export const userActions = {
  onCreateUserAddress,
  onSendFriendRequest,
  onRejectFriendRequest,
  onGetFriends,
  onGetFriendsRequest,
  onAcceptFriendRequest,
  onUnFriendRequest,
  onDeleteFriendRequest,
  onGetTotalUnread,
  onCreateOrUpdateWelcomeMessage,
  onGetWelcomeMessages,
  onAddOfficialAccount,
  onUpdateUserWalletBalances,
};
