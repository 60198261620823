/* LAYOUT DEFAULT COMPONENT
   ========================================================================== */

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Drawer } from 'antd';
import { dispatch, useSelector } from 'common';
// eslint-disable-next-line sort-imports
import { CloseIcon } from 'containers/layouts/navbar/icons/close.icon';
import { hideLoading, ProgressDialog, showLoading } from 'library/components/progress-dialog';
import { Toast } from 'library/components/toast';
import { homeActions } from 'redux/action-slice/home';

import StyledV2 from './defaultV2.style';
import FooterV2 from './footerV2/footerV2.component';
import DrawerContent from './navbar/drawer-content.component';
import NavbarV2 from './navbar/navbarV2.component';
const DefaultV2 = () => {
  const { showDialog, token } = useSelector(x => x.app);
  /**
   * STATES
   */
  const [openDrawer, setOpenDrawer] = useState(false);
  //effect
  useEffect(() => {
    if (showDialog) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [showDialog]);

  useEffect(() => {
    if (token) {
      dispatch(
        homeActions.onGetUserInfo(data => {
          // dispatch(appActions.onSetUserInfo(data));
          // dispatch(appActions.onSetAddress(data.wallet_address));
          // dispatch(appActions.onSetChatSelectedAccount(data.wallet_address));
        }),
      );
    }
  }, [token]);
  useEffect(() => {
    const handleResize = () => {
      // Close the drawer if window width is less than 768px
      if (window.innerWidth < 768) {
        onCloseDrawer();
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Run only once on component mount
  /**
   * FUNCTIONS
   */
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };
  //render
  return (
    <div className="themes light">
      <Drawer
        closeIcon={<CloseIcon className="close-icon" />}
        width="70%"
        className="layout-drawer"
        title=""
        placement={'left'}
        onClose={onCloseDrawer}
        open={openDrawer}>
        <DrawerContent />
      </Drawer>
      <div className="layout-main-container">
        <div className={`w-full ${openDrawer ? 'drawer-open' : ''}`}>
          <NavbarV2 openDrawer={() => setOpenDrawer(true)} />
          <StyledV2.Main className={' w-full'}>
            <Outlet />
            <ProgressDialog />
            <Toast />
          </StyledV2.Main>

          <FooterV2 />
        </div>
      </div>
    </div>
  );
};

export default DefaultV2;
