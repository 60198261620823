import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react';

import { Alert } from 'antd';
import './index.scss';
const ToastComponent = forwardRef((_, ref) => {
  // state
  const [visible, setVisible] = useState(false);
  // effect
  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
        }, 1000);
      },
      hide: () => {
        setVisible(false);
      },
    }),
    [],
  );

  // render
  return visible ? (
    <>
      <Alert className="successToast" message="Success" type="success" showIcon />
    </>
  ) : null;
});

export const ToastRef = createRef<ToastRef>();
export const Toast = () => <ToastComponent ref={ToastRef} />;
export const showToast = () => {
  ToastRef.current?.show();
};

export const hideToast = () => {
  ToastRef.current?.hide();
};
export interface ToastRef {
  show(): void;
  hide(): void;
}
