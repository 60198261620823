'use client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { $t } from 'library/utils';
import i18n, { getCurrentLanguage } from 'library/utils/i18n/i18n';
import { MdLanguage } from 'react-icons/md';
import ROUTES from 'routes/constant';
interface LanguageInfo {
  name: string;
  flag: string;
}

interface Languages {
  [key: string]: LanguageInfo;
}

export default function WithSubnavigation() {
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  const { isOpen, onToggle } = useDisclosure();
  const changeLanguage = (languageCode: any) => {
    window.localStorage.setItem('userLanguage', languageCode);
    i18n.changeLanguage(languageCode);
  };
  const handleLanguageChange = (languageCode: string) => {
    setCurrentLanguage(languageCode);
    changeLanguage(languageCode);
    window.location.reload();
  };
  const languages: Languages = {
    en: { name: 'English', flag: 'https://flagcdn.com/us.svg' },
    ja: { name: '日本語', flag: 'https://flagcdn.com/jp.svg' },
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image
            alt={'Login Image'}
            src={'/images/DMTP.png'}
            // width={{ base: '282px', md: '230px', lg: '334px' }}
            height={{ base: '30' }}
          />

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg="white">
            <Flex>
              <MdLanguage />
              <Image
                ml={'4px'}
                w="20px"
                src={languages[currentLanguage].flag}
                alt={languages[currentLanguage].name}
              />
            </Flex>
          </MenuButton>
          <MenuList>
            {Object.entries(languages).map(([code, { name, flag }]) => (
              <MenuItem key={code} onClick={() => handleLanguageChange(code)}>
                <Image w="20px" mr="2" src={flag} alt={name} />
                {name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}></Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('purple.400', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate();
  const navigateWelcomeMessages = () => {
    navigate(ROUTES.welcomeMessage);
  };

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as="button"
                p={2}
                onClick={() => {
                  if (navItem.href) {
                    navigate(navItem.href);
                  }
                }}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('purple.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} _groupHover={{ color: 'purple.400' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'purple.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          color: 'purple',
          // textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map(child => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: $t('crm_page:navbar:home'),
    href: '/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937',
  },
  {
    label: $t('crm_page:navbar:analytics'),
    children: [
      {
        label: $t('crm_page:navbar:analytics_sub_friends_list'),
        subLabel: $t('crm_page:navbar:analytics_sub_friends_list_desc'),
        href: '/friend-list',
      },
      {
        label: $t('crm_page:navbar:analytics_sub_message_list'),
        subLabel: $t('crm_page:navbar:analytics_sub_message_list_desc'),
        href: '/message-list',
      },
    ],
  },
  {
    label: $t('crm_page:navbar:messages'),
    children: [
      {
        label: $t('crm_page:navbar:messages_sub_send_messages'),
        subLabel: $t('crm_page:navbar:messages_sub_send_messages_desc'),
        href: '/e90f5a9e4de9a157cba44b0b5f0a020a6fdc33982c0766b6b5bc546ebf3a2962',
      },
      {
        label: $t('crm_page:navbar:messages_sub_welcome_messages'),
        subLabel: $t('crm_page:navbar:messages_sub_welcome_messages_desc'),
        href: '/crm-welcome-message7300b177d466ae31945c6eada9bcd83418289e1689b052653264eb55a1da568a',
      },
    ],
  },
  {
    label: $t('crm_page:navbar:chats'),
    href: '/messages',
  },
  {
    label: $t('crm_page:navbar:sub_accounts'),
    href: '/sub-accounts',
  },
];
