import React, { CSSProperties, useMemo } from 'react';

import { icons } from 'assets/icon';
import { enhance } from 'common';

import { IconProps } from './type';

const SIZE = 24;

export const Icon = (props: IconProps) => {
  // state
  const { size = SIZE, icon, resizeMode = 'contain', onPress, color } = props;
  // style
  const style = useMemo<CSSProperties>(
    () => enhance([{ width: size, height: size, resizeMode }]),
    [resizeMode, size],
  );

  // render
  return (
    <img onClick={() => onPress?.()} style={style} color={color as string} src={icons[icon]} />
  );
};
