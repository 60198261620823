/* eslint-disable @typescript-eslint/no-explicit-any */
import { MONTH_I18N_KEY } from 'common/constant';
import { ERROR_NETWORK_CODE } from 'config/api';
import { ResponseBase } from 'config/type';
import { translate } from 'library/utils';
import moment from 'moment';
import Web3 from 'web3';
import { TransactionReceipt } from 'web3-core/types';

import CONFIG_CONTRACT from './contract';

import { decodeLogs } from '../decode-logs';
import { getState } from '../redux';
import { stringifyObjectValidateYup } from '../string';
type TypesBase =
  | 'bigint'
  | 'boolean'
  | 'function'
  | 'number'
  | 'object'
  | 'string'
  | 'symbol'
  | 'undefined';

export const onCheckType = (source: any, type: TypesBase): source is TypesBase => {
  return typeof source === type;
};

export const enhance = (arrStyle: Array<any>) => {
  let arrayStyleEnhance = {};
  arrStyle.map(e => {
    arrayStyleEnhance = { ...arrayStyleEnhance, ...e };
  });
  return arrayStyleEnhance;
};

export const checkKeyInObject = (T: any, key: string) => {
  return Object.keys(T).includes(key);
};

export const formatDate = (args: { date?: string }) => {
  if (!args?.date) {
    return {
      tx: '',
      txOptions: {},
    };
  }
  const year = moment(args?.date).get('years');
  const month = moment(args?.date).get('months');
  return {
    tx: `date_time:${MONTH_I18N_KEY[month]}`,
    txOptions: { yearOrDay: year.toString() },
  };
};

export const saveToken = ({
  providerLogin,
  refresh_token,
  access_token,
}: {
  access_token?: string;
  refresh_token?: string;
  providerLogin?: string;
}) => {
  // save(R.strings.TOKEN_INFORMATION, {
  //   access_token,
  //   refresh_token,
  //   providerLogin,
  // });
};
export const execFunc = <Fn extends (...args: any[]) => any>(
  func?: Fn,
  ...args: Parameters<Fn>
): ReturnType<Fn> => {
  if (onCheckType(func, 'function')) {
    return func(...args);
  }
  return undefined as ReturnType<Fn>;
};

export const showErrorApi = (error: string | Array<any>) => {
  if (onCheckType(error, 'string')) {
    // showPopupError({ message: error });
  }
  /**
   * show dialog error api base
   */
};

export const getObjectByArrayProperty = (data: any, array: string[]) => {
  const result = {} as any;
  array.forEach(key => {
    result[key] = data[key];
  });
  return result;
};
const handleData = (responseError: ResponseBase<any>) => {
  return responseError;
};

export const HandleErrorApi = (status: number) => {
  switch (status) {
    case ERROR_NETWORK_CODE:
      return handleData({
        code: ERROR_NETWORK_CODE,
        message: translate('error:error_network'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:error_network' }),
      });
    case 200:
      return handleData({
        code: status,
        message: translate('error:0'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:0' }),
      });
    case 400:
      return handleData({
        code: status,
        message: translate('error:400'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:400' }),
      });
    case 401:
      return handleData({
        code: status,
        message: translate('error:401'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:401' }),
      });
    case 402:
      return handleData({
        code: status,
        message: translate('error:402'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:402' }),
      });
    case 403:
      return handleData({
        code: status,
        message: translate('error:403'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:403' }),
      });
    case 404:
      return handleData({
        code: status,
        message: translate('error:404'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:404' }),
      });
    case 405:
      return handleData({
        code: status,
        message: translate('error:405'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:405' }),
      });
    case 406:
      return handleData({
        code: status,
        message: translate('error:406'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:406' }),
      });
    case 407:
      return handleData({
        code: status,
        message: translate('error:407'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:407' }),
      });
    case 408:
      return handleData({
        code: status,
        message: translate('error:408'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:408' }),
      });

    case 409:
      return handleData({
        code: status,
        message: translate('error:409'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:409' }),
      });
    case 410:
      return handleData({
        code: status,
        message: translate('error:410'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:410' }),
      });

    case 411:
      return handleData({
        code: status,
        message: translate('error:411'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:411' }),
      });
    case 412:
      return handleData({
        code: status,
        message: translate('error:412'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:412' }),
      });

    case 413:
      return handleData({
        code: status,
        message: translate('error:413'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:413' }),
      });
    case 414:
      return handleData({
        code: status,
        message: translate('error:414'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:414' }),
      });
    case 415:
      return handleData({
        code: status,
        message: translate('error:415'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:415' }),
      });
    case 416:
      return handleData({
        code: status,
        message: translate('error:416'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:416' }),
      });
    case 417:
      return handleData({
        code: status,
        message: translate('error:417'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:417' }),
      });
    case 500:
      return handleData({
        code: status,
        message: translate('error:500'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:500' }),
      });
    case 501:
      return handleData({
        code: status,
        message: translate('error:501'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:501' }),
      });
    case 502:
      return handleData({
        code: status,
        message: translate('error:502'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:502' }),
      });
    case 503:
      return handleData({
        code: status,
        message: translate('error:503'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:503' }),
      });
    case 504:
      return handleData({
        code: status,
        message: translate('error:504'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:504' }),
      });
    case 505:
      return handleData({
        code: status,
        message: translate('error:505'),
        data: null,
        status: false,
        errorTx: stringifyObjectValidateYup({ keyT: 'error:505' }),
      });

    default:
      if (status > 503) {
        return handleData({
          code: status,
          message: translate('error:server_error'),
          data: null,
          status: false,
          errorTx: stringifyObjectValidateYup({ keyT: 'error:server_error' }),
        });
      } else if (status < 500 && status >= 400) {
        return handleData({
          code: status,
          message: translate('error:error_on_request'),
          data: null,
          status: false,
          errorTx: stringifyObjectValidateYup({
            keyT: 'error:error_on_request',
          }),
        });
      } else {
        return handleData({
          code: status,
          message: translate('error:error_on_handle'),
          data: null,
          status: false,
          errorTx: stringifyObjectValidateYup({
            keyT: 'error:error_on_handle',
          }),
        });
      }
  }
};
export const checkLoggedIn = () => {
  if (getState('app').token) {
    return true;
  }
  // navigate(COMMON_STACK.ACCESS_DENIED);
  return false;
};

/**
 * Reload current browser link
 * It only works in Client Side Render, because window always existed
 * For Server Side Render, please check window first before any window's methods calls
 */
export const reload = () => {
  window.location.reload();
};

/**
 * Safely parse JSON format
 * @param jsonString input json string
 * @returns data in json format or undefined
 */
export const parseJSON = <T>(jsonString: string | null): T | null => {
  try {
    return jsonString === 'undefined' ? null : JSON.parse(jsonString ?? '');
  } catch (error) {
    console.log('Parsing error on ', { jsonString });
    return null;
  }
};

/**
 * Get value from Session Storage by key
 * @param key to get value from Session Storage
 * @returns JSON data
 */
export const getFromSessionStorage = <T>(key: string): T | null => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    return parseJSON(value);
  }
  return null;
};

/**
 * Get value from Local Storage by key
 * @param key to get value from Local Storage
 * @returns JSON data
 */
export const getFromLocalStorage = <T>(key: string): T | null => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return parseJSON(value);
  }
  return null;
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

//web3
const wmb = window as any;
const web3 = new Web3(wmb.ethereum);
export const getTransactionReceiptMined = (
  txHash: string,
  interval = 500,
): Promise<TransactionReceipt> => {
  return new Promise((resolve, reject) => {
    const transactionReceiptAsync = (_resolve: any, _reject: any) => {
      web3.eth.getTransactionReceipt(txHash, (error: any, receipt: any) => {
        if (error) {
          reject(error);
        } else if (receipt == null) {
          setTimeout(() => transactionReceiptAsync(_resolve, _reject), interval);
        } else {
          resolve(receipt);
        }
      });
    };
    transactionReceiptAsync(resolve, reject);
  });
};
export const getReturnValueSendTransaction = async (
  transactionHash: string,
  contractAbi: any,
  event: string,
  field: string,
): Promise<any> => {
  const receipt = await web3.eth.getTransactionReceipt(transactionHash);
  const decodedLog = decodeLogs(receipt.logs, contractAbi);
  const eventLogs = decodedLog.filter((log: any) => log.name == event);
  return eventLogs.map((evt: any) => evt.events[field]);
};

export const onMintNFT = async (
  address: string | undefined,
  links_metadata: Array<string>,
  amounts: number[] = [1],
) => {
  const stickerContract = new web3.eth.Contract(
    CONFIG_CONTRACT.DMTPSticker.abi as any,
    CONFIG_CONTRACT.DMTPSticker.address,
  );
  const receipt = await stickerContract.methods
    .mintBatch(address, amounts, links_metadata)
    .send({ from: address });
  const { transactionHash } = receipt;
  const isTxDone = await getTransactionReceiptMined(transactionHash);
  const tokenIds = await getReturnValueSendTransaction(
    transactionHash,
    CONFIG_CONTRACT.DMTPSticker.abi,
    'TransferBatch',
    'ids',
  );
  return { isTxDone, tokenIds: tokenIds[0] };
  // const tokenIds = await getReturnValueSendTransaction(
  //   transactionHash,
  //   CONFIG_CONTRACT.Sticker.abi,
  //   'Transfer',
  //   'tokenId',
  // );
  // return { isTxDone, tokenIds };
};

export const listStickerBatch = async ({
  ids,
  address,
  stickerUris,
  amounts,
  tokens,
  prices,
  whitelists,
}: {
  ids: Array<string>;
  address: string;
  stickerUris: Array<string>;
  amounts: Array<number>;
  tokens: Array<string>;
  prices: Array<string>;
  whitelists: Array<string>;
}) => {
  const dmtpMarket = new web3.eth.Contract(
    CONFIG_CONTRACT.DMTPMarket.abi as any,
    CONFIG_CONTRACT.DMTPMarket.address,
  );
  const pricesToWei = prices.map(e => {
    return web3.utils.toWei(e, 'ether');
  });
  const receipt = await dmtpMarket.methods
    .listStickerBatch(ids, stickerUris, amounts, tokens, pricesToWei, whitelists)
    .send({ from: address });
  const { transactionHash } = receipt;
  const isTxDone = await getTransactionReceiptMined(transactionHash);
  return isTxDone;
};

export const onGetAccounts = () => {
  return wmb.web3.eth.getAccounts();
};
