import { Link } from 'react-router-dom';

import { DISCORD_LINK } from 'config/social-links';
import { DiscordIcon } from 'containers/layouts/footerV2/icons/discord.icon';
import { GithubIcon } from 'containers/layouts/footerV2/icons/github.icon';
import { XSocialIcon } from 'containers/layouts/footerV2/icons/x-social.icon';
import { $t } from 'library/utils';
import ROUTES from 'routes/constant';

import cssClass from './footerV2.module.scss';

const FooterV2 = () => {
  /***
   * STATES
   */

  /**
   * HOOKS
   */
  /**
   * FUNCTIONS
   */

  /**
   * RENDERS
   */

  return (
    <div className={`${cssClass.FooterV2}`}>
      <div className="footer-container" data-aos="fade-up">
        <div className="footer-logo">
          <Link to="/">
            <img src={'/images/layoutV2/logo-footer.png'} alt="logo" />
          </Link>
        </div>
        <div className="social-links">
          <a target="_blank" href={'https://twitter.com/DMTPHQ'}>
            <XSocialIcon className="x-social" />
          </a>
          <a target="_blank" href={DISCORD_LINK}>
            <DiscordIcon className="discord" />
          </a>
          <a target="_blank" href={'https://github.com/DMTP-Protocol'}>
            <GithubIcon className="github" />
          </a>
        </div>
        <div className="download-apps">
          <a
            target="_blank"
            href={'https://apps.apple.com/us/app/web3-messenger-dmtp/id6466402239'}>
            <img src={'/images/layoutV2/app-store.png'} alt="app-store" className="app-store" />
          </a>
          <a
            target="_blank"
            href={'https://play.google.com/store/apps/details?id=co.highphen.dmtp&pli=1'}>
            <img
              src={'/images/layoutV2/google-store.png'}
              alt="google-store"
              className="google-store"
            />
          </a>
        </div>
        <div className="term-section">
          <a target="_blank" href={ROUTES.tos}>
            {$t('home:termsOfUse')}
          </a>
          <a target="_blank" href={ROUTES.privacyPolicies}>
            {$t('home:PrivacyPolicy')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterV2;
