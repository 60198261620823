import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Link } from '@chakra-ui/react';
import { $t } from 'library/utils';
import eventBus from 'library/utils/eventBus';
import { getCurrentLanguage } from 'library/utils/i18n/i18n';
import ROUTES from 'routes/constant';

import cssClass from './drawer-content.module.scss';
const DrawerContent = () => {
  /***
   * STATES
   */

  /**
   * HOOKS
   */
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  /**
   * FUNCTIONS
   */
  const handleOpenConnectModal = () => {
    eventBus.emit('openConnectModal');
  };
  /**
   * RENDERS
   */
  return (
    <div className={`${cssClass.DrawerContent}`}>
      <div className="nav-logo">
        <NavLink to="/">
          <img src={'/images/layoutV2/logo.png'} alt="logo" />
        </NavLink>
      </div>
      <div className="button-connect-container">
        <Button size={'md'} onClick={handleOpenConnectModal} className="button-connect">
          {$t('messages:btn_launch_app')}
        </Button>
      </div>
      <div className="nav-links">
        <ul>
          <li>
            <NavLink to={ROUTES.home}>{$t('homepage_navigation_links:link_home')}</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.partners}>{$t('homepage_navigation_links:link_partners')}</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.tokens}>{$t('homepage_navigation_links:link_token')}</NavLink>
          </li>
          <li>
            <Link href="https://dmtp.gitbook.io/dmtp-docs" isExternal>
              {$t('homepage_navigation_links:link_docs')}
            </Link>
          </li>
          <li>
            <li>
              <Link
                isExternal
                href={
                  currentLanguage === 'en'
                    ? 'https://dmtp-tech.zendesk.com/hc/en-us/sections/26856529070617-FAQ'
                    : 'https://dmtp-tech.zendesk.com/hc/ja/sections/26856529070617'
                }>
                {$t('homepage_navigation_links:link_faq')}
              </Link>
            </li>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DrawerContent;
