import escapeHtml from 'escape-html';
import { isEmpty } from 'lodash';
import { Descendant, Node as SlateNode, Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import { findUrlsInText, splitByUrl } from './editor';

const regexURL = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$',
  'gi',
);

/**
 * Serializes slate node to html
 * @param {Descendant} node
 * @returns {String}
 */
export const serialize = node => {
  if (Text.isText(node)) {
    // let string = '';
    // console.log('dsada', isUrl(node.text));
    // if (node.text && regexURL.test(decodeURI(node.text))) {
    //   const exec = regexURL.exec(decodeURI(node.text));
    //   console.log(exec, 'ex');
    //   //string = string.replace(regexURL, `<a href="${exec[0]}" target="_blank"></a>`);
    // }
    let string = escapeHtml(node.text);

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    const urls = findUrlsInText(string);

    if (urls.length > 0) {
      for (const item of urls) {
        string = string.replace(
          item.url,
          `<a class="text-blue-400" href="${item.url}" target="_blank" rel="noopener,noreferrer">${item.url}</a>`,
        );
      }
    }

    return string;
  }

  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case 'block-quote':
      return `<blockquote><p class="whitespace-pre-line break-all">${children}</p></blockquote>`;
    case 'paragraph':
      if (isEmpty(children)) {
        return `<br /> ${children}`;
      }
      return `<p class="whitespace-pre-line break-all">${children}</p>`;
    case 'div':
      return `<div class="whitespace-pre-line break-all">${children}</div>`;
    case 'link':
      return `<a class="text-blue-400" target="_blank" href="${escapeHtml(
        node.url,
      )}" rel="noopener,noreferrer">${children}</a>`;
    case 'image':
      return `<img class="w-[100px] h-[100px] object-cover" alt="${node.name}" src="${escapeHtml(
        node.url,
      )}" >${children}</img>`;
    case 'img':
      return `<img class="w-[100px] h-[100px] object-cover" src="${escapeHtml(
        node.url,
      )}" >${children}</img>`;
    default:
      return children;
  }
};

/**
 * Text to Slate node
 * @param {Descendant} nodeEl
 * @returns {Descendant}
 */
export const textToSlateNode = nodeEl => {
  let children = [];
  let hasURL = false;
  for (const node of nodeEl.children) {
    let nodeChild = [];
    if (Text.isText(node)) {
      let html = node.text;
      const urls = findUrlsInText(node.text);

      if (urls.length > 0) {
        hasURL = true;
        for (const pair of urls) {
          html = html.replace(
            pair.url,
            `<a class="text-blue-400" href="${pair.url}" target="_blank" rel="noopener,noreferrer">${pair.url}</a>`,
          );
        }

        const document = new DOMParser().parseFromString(html, 'text/html');
        nodeChild = deserialize(document.body);
        children.push({
          type: 'div',
          children: nodeChild,
        });
      } else {
        children.push(node);
      }
    } else {
      children.push(node);
    }
  }

  return {
    ...nodeEl,
    type: hasURL ? 'div' : nodeEl.type,
    children,
  };
};

/**
 * Deserialize html tag to Slate node
 * @param {Node} el
 * @param {} markAttributes
 * @returns {Descendant[]}
 */
export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'strong':
      nodeAttributes.bold = true;
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case '\n':
      return jsx('element', { type: 'br' }, children);
    case 'BR':
      return jsx('element', { type: 'br' }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'DIV':
      return jsx('element', { type: 'div' }, children);
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
    case 'IMG':
      return jsx('element', { type: 'img', url: el.getAttribute('src') }, children);
    default:
      return children;
  }
};

/**
 * Convert slate node to plain text
 * @param {Descendant[]} nodes
 * @returns {String}
 */
export const serializePlainText = nodes => {
  return nodes.map(n => SlateNode.string(n)).join('\n');
};

/**
 * Remove all br tag at end of string
 * @param {String} text
 * @returns {String}
 */
export const replaceBrAtEndOfString = text => {
  return text.replace(/(<br\s+\/>\s+)+$/, '');
};
