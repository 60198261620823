export interface NotificationDataEmbed {
  title: string;
  color: number;
  url: string;
}

export interface FriendAccept {
  accept_address: string;
  user: UserRequest;
  room_id?: string | undefined | null;
}

export interface UserRequest {
  avatar: string;
  name: string;
  wallet_address: string;
}

export interface UserData {
  user: UserRequest;
  room_id?: string | undefined | null;
}

export interface FriendRequest {
  sender_address: string;
  user: UserRequest;
}

export enum NotificationType {
  NEW_MESSAGE = 'NEW_MESSAGE',
  FRIEND_REQUEST = 'FRIEND_REQUEST',
  FRIEND_ACCEPT = 'FRIEND_ACCEPT',
}

export interface NotificationEmbed {
  embeds: NotificationDataEmbed[];
  type: NotificationType;
  content: string;
  data: UserData;
}

export type NotificationUnion = NotificationEmbed | FriendAccept | FriendRequest;

export interface NotificationDataMessage {
  content: string;
  created_at: string;
  data: NotificationUnion;
  type: NotificationType;
}

export interface Notification {
  content: string;
  to_address: string;
  is_read: boolean;
  data: NotificationDataMessage;
  created_at: string;
  updated_at: string;
  _id: string;
}

export interface ReadNotification {
  notification_id: string;
}

export interface NotificationWithCount {
  notifications: Notification[];
  count: number;
}
