/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import 'antd/dist/reset.css';
import 'assets/styles/global.css';
import 'assets/styles/reset.css';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation, useNavigate, useRoutes } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Modal } from 'antd';
import AOS from 'aos';
import { R } from 'assets/value';
import axios from 'axios';
import { dispatch, reload, RXStore, useSelector } from 'common';
import { wagmiConfig } from 'common/constant/wallet-connect';
import { useTracking } from 'common/hooks/useTracking';
import { ThemesProvider } from 'common/styled-themes';
import ErrorBoundary from 'containers/error-boundary/error-boundary';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t } from 'library/utils';
import eventBus from 'library/utils/eventBus';
import I18n from 'library/utils/i18n/i18n';
/* eslint-disable import/order */
import { PersistGate } from 'redux-persist/integration/react';
import { appActions } from 'redux/action-slice';
/* eslint-enable import/order */
import 'aos/dist/aos.css'; // Import AOS CSS
import { blockAccessRoutes, fullAccessRoutes, routesMode } from 'routes';
import ROUTES from 'routes/constant';
import { persistor, store } from 'store/store';
import { chakraTheme } from 'theme/chakra';
import { useDisconnect, WagmiConfig } from 'wagmi';
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-YZQTLYGG82');
// import './index.scss';

const useAppMode = () => {
  const [isMaintenance, setIsMaintenance] = React.useState(false);
  const [isWhitelist, setIsWhitelist] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { chatSelectedAccount, userInfo } = useSelector(state => state.app);
  useEffect(() => {
    if (!userInfo?.wallet_address) {
      dispatch(appActions.onSetAddress(undefined));
    }
  }, [userInfo]);

  // animation on scroll config
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  useEffect(() => {
    checkingMaintenanceStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (chatSelectedAccount) {
      permissionCheckAccess(chatSelectedAccount);
    } else {
      setIsWhitelist(false);
    }
  }, [chatSelectedAccount]);

  // reload user points from header when change page
  useEffect(() => {
    eventBus.emit('reloadUserPoints');
  }, [location]);
  const checkingMaintenanceStatus = async () => {
    const response = await NetWorkService.Get<boolean>({
      url: ApiConstants.GET_MAINTENANCE_STATUS,
    });

    setIsMaintenance(response?.data || false);

    if (response?.data && location.pathname !== ROUTES.maintenance) {
      navigate(ROUTES.maintenance);
    }
  };

  const permissionCheckAccess = async (account: string) => {
    //White list
    // const permissionResponse = await NetWorkService.Get<WHITELIST_PERMISSION>({
    //   url: ApiConstants.CHECK_WHITELIST,
    //   params: {
    //     address: account,
    //   },
    // });

    setIsWhitelist(true);

    // if (permissionResponse && permissionResponse.data) {
    //   const { is_exist } = permissionResponse.data;

    //   setIsWhitelist(is_exist);
    // } else {
    //   setIsWhitelist(false);
    // }
  };

  return {
    isMaintenance,
    isWhitelist,
  };
};

/**
 * Entry point for route component
 * @returns JSX Element represents for route components
 */

const Main = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log = function no_console() {};
  }

  //state
  const { isMaintenance, isWhitelist } = useAppMode();
  const getRoutes = (isMaintenance: boolean, isWhitelist: boolean) => {
    if (isMaintenance) {
      return routesMode;
    }
    if (!isWhitelist) {
      return blockAccessRoutes;
    }

    return fullAccessRoutes;
  };
  const element = useRoutes(getRoutes(isMaintenance, isWhitelist));

  const { disconnect } = useDisconnect();
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  const { sessionExpired } = useSelector(state => state.app);
  const cookie = new Cookies();
  //render
  return (
    <>
      {element}
      <Modal
        width={350}
        open={sessionExpired}
        centered
        closable={false}
        afterClose={() => {
          disconnect();
          cookie.remove(R.strings.TOKEN_INFORMATION);
          dispatch(appActions.reset());
        }}
        maskClosable={false}
        cancelButtonProps={{
          className: 'hide',
          style: { display: 'none' },
        }}
        okButtonProps={{
          style: {
            background: '#2a74eb',
          },
        }}
        onOk={() => {
          // window.location.replace(ROUTES.home);
          disconnect();
          cookie.remove(R.strings.TOKEN_INFORMATION);
          dispatch(appActions.reset());
        }}>
        <div className="container mx-auto text-center">
          <h6 className="text-sm font-sans font-semibold">{$t('messages:noti_session_expired')}</h6>
        </div>
      </Modal>
    </>
  );
};

/**
 * Entry point for App
 * @returns JSX Element represents for app
 */
const App = () => {
  const getMaticPrice = useCallback(async () => {
    // const api_key = process.env.NEXT_PUBLIC_POLYGONSCAN_API_KEY;
    // const price = await axios.get(
    //   `https://api.polygonscan.com/api?module=stats&action=maticprice&apikey=${api_key}`,
    // );
    // console.log(price, 'price');
    // const matic_price = price?.data?.result?.maticusd || 0;
    // dispatch(appActions.setMaticPrice(matic_price));
    try {
      const price: any = await axios.get(
        // 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
        'https://api.coinbase.com/v2/prices/ETH-USD/spot',
      );
      // const ethereum_price = price?.data?.ethereum?.usd || 0;
      const ethereum_price = price?.data?.data?.amount || 0;
      console.log('================>ethereum_price', ethereum_price);
      dispatch(appActions.setMaticPrice(ethereum_price));
    } catch (error) {
      console.log(error);
    }
  }, []);
  //effect
  useEffect(() => {
    dispatch(appActions.onLoadApp());
    getMaticPrice();
  }, [getMaticPrice]);

  const muiTheme = createTheme();

  //render
  const MetaTags = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const refParam = queryParams.get('ref');
    console.log('🚀 ~ MetaTags ~ refParam:', refParam);
    if (!refParam) {
      return (
        <Helmet>
          <title>DMTP</title>
          <meta
            name="description"
            content="DMTP is Decentralized Message Transfer Protocol Communication infrastructure for Web3"
            data-react-helmet="true"
          />
          <meta property="og:title" content="DMTP" data-react-helmet="true" />
          <meta
            property="og:description"
            content="DMTP is Decentralized Message Transfer Protocol Communication infrastructure for Web3"
            data-react-helmet="true"
          />
        </Helmet>
      );
    }
    return (
      <Helmet>
        <title>Get rewards by signing up through this invitation link!</title>
        <meta
          name="description"
          content="DMTP is Decentralized Message Transfer Protocol Communication infrastructure for Web3"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Get rewards by signing up through this invitation link!"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="DMTP is Decentralized Message Transfer Protocol Communication infrastructure for Web3"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="Get rewards by signing up through this invitation link!"
          data-react-helmet="true"
        />
      </Helmet>
    );
  };
  return (
    <ThemeProvider theme={muiTheme}>
      <ChakraProvider theme={chakraTheme}>
        <ErrorBoundary onReset={reload}>
          <BrowserRouter>
            <CookiesProvider>
              <ThemesProvider>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <I18nextProvider i18n={I18n}>
                      <WagmiConfig config={wagmiConfig}>
                        <MetaTags />

                        <Main />
                      </WagmiConfig>
                      <RXStore />
                    </I18nextProvider>
                  </PersistGate>
                </Provider>
              </ThemesProvider>
            </CookiesProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
