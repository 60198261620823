import Axios from 'axios';

import query_json from './query';

const URL =
  process.env.REACT_APP_THEGRAPH_URL ||
  'https://api.thegraph.com/subgraphs/name/diepvuongthang/dmtp';

const graphqlRequest = async query => {
  try {
    const _query = Array.isArray(query) ? query : { query };
    const body = JSON.stringify(_query);
    const opts = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: false,
      timeout: 5 * 1000,
    };
    const response = await Axios.post(URL, body, opts);
    return response?.data?.data || response?.data || response;
  } catch (ex) {
    console.log('============> graphqlRequest => ex: ', ex);
    throw ex;
  }
};

const getKeys = async params => {
  const query = `query keysQuery {
            keys(
              ${params?.first ? `first: ${params?.first},` : ''}
              ${params?.skip ? `skip: ${params?.skip},` : ''}
              ${params?.orderBy ? `orderBy: ${params?.orderBy},` : ''}
              ${params?.orderDirection ? `orderDirection: ${params?.orderDirection},` : ''},
              ${params?.where ? `where: {${params?.where}}` : ''},
            ) {
                ${query_json.json_keys}
            }
        }`;

  const result = await graphqlRequest(query);
  return result?.keys || result;
};

const getActivities = async params => {
  const query = `query activitiesQuery {
            activities(
              ${params?.first ? `first: ${params?.first},` : ''}
              ${params?.skip ? `skip: ${params?.skip},` : ''}
              ${params?.orderBy ? `orderBy: ${params?.orderBy},` : ''}
              ${params?.orderDirection ? `orderDirection: ${params?.orderDirection},` : ''},
              ${params?.where ? `where: {${params?.where}}` : ''},
            ) {
                ${params?.field ? params?.field : query_json.json_activities}
            }
        }`;

  const result = await graphqlRequest(query);
  return result?.activities || result;
};

const getKeysByAuthors = async authors => {
  const query = `query keysQuery {
            keys(
              first: ${authors.length},
              where: {
                  author_in: [${authors.map(i => `"${i}"`)}]
                }
            ) {
                ${query_json.json_keys}
            }
        }`;

  const result = await graphqlRequest(query);
  return result?.keys || result;
};

const getOwnKeys = async params => {
  const query = `query OwnKeysQuery {
              ownKeys(
                ${params?.first ? `first: ${params?.first},` : ''}
                ${params?.skip ? `skip: ${params?.skip},` : ''}
                ${params?.orderBy ? `orderBy: ${params?.orderBy},` : ''}
                ${params?.orderDirection ? `orderDirection: ${params?.orderDirection},` : ''},
                ${params?.where ? `where: {${params?.where}}` : ''},
              ) {
                ${params?.field ? params?.field : query_json.json_own_keys}
              }
        }`;

  const result = await graphqlRequest(query);
  return result?.ownKeys || result;
};

const func = {
  getKeys,
  getActivities,
  getKeysByAuthors,
  getOwnKeys,
};
export default func;
