import { createAction } from '@reduxjs/toolkit';
import { CreateCrmMessages, CrmMessages } from 'model/messages';
import { IErrorResponse } from 'model/response';
import { UserStats } from 'model/user.interface';

import * as Actions from '../action-type';

const onGetCrmMessages = createAction(
  Actions.GET_CRM_MESSAGES,
  (
    onSucceeded?: (data: Array<CrmMessages>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onCreateCrmMessages = createAction(
  Actions.CREATE_CRM_MESSAGES,
  (
    body: CreateCrmMessages,
    onSucceeded: (data: CrmMessages) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUpdateCrmMessageRead = createAction(
  Actions.UPDATE_CRM_MESSAGE_READ,
  (onSucceeded: (data: CrmMessages) => void, onFailed?: (response: IErrorResponse) => void) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetTransitionOfFriends = createAction(
  Actions.GET_TRANSITION_OF_FRIENDS,
  (
    onSucceeded?: (data: Array<UserStats>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
    },
  }),
);
export const crmActions = {
  onGetCrmMessages,
  onCreateCrmMessages,
  onUpdateCrmMessageRead,
  onGetTransitionOfFriends,
};
