export const CODE_DEFAULT = -200;
export const CODE_SUCCESS = 200;
export const ERROR_NETWORK_CODE = -100;
export const RESULT_CODE_PUSH_OUT = 401;
export const TIME_OUT = 20000;
export const STATUS_TIME_OUT = 'ECONNABORTED';
export const CODE_TIME_OUT = 408;
export const CONTENT_TYPE = 'application/json';
export const TOKEN_KEY_HEADER = 'authorize';
export const ADDRESS_KEY_HEADER = 'address';
export const getBearerToken = (token?: string) => {
  return token ? `Bearer ${token}` : '';
};
export const PROVIDER_LOGIN = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  LINE: 'line',
  APPLE: 'apple',
  EMAIL: 'email',
} as const;
export type ProviderLogin = (typeof PROVIDER_LOGIN)[keyof typeof PROVIDER_LOGIN];
