import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import isEqual from 'react-fast-compare';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import ReactLoading from 'react-loading';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { LogoutOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button as CKButton,
  Switch,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
/* eslint-disable import/order */
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Empty,
  Input,
  message,
  Modal,
  Row,
  Tabs,
} from 'antd';
/* eslint-enable import/order */
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { R } from 'assets/value';
/* eslint-disable import/order */
import { dispatch, SOCKET_EVENT_NAME, useSelector, useSocket } from 'common';
/* eslint-enable import/order */
import {
  copyHandler,
  formatChatDotAddress,
  formatDotAddress,
  getRedirectUri,
} from 'common/utils/core';
import { isMobile } from 'common/utils/mobile';
import { changeDomainToAddress } from 'common/utils/web3domain';
import { motion } from 'framer-motion';
import { Icon } from 'library/components/icon';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t, translate } from 'library/utils';
import eventBus from 'library/utils/eventBus';
import { debounce, isEmpty } from 'lodash';
import {
  AuthorizationLink,
  FriendStatus,
  InviteServer,
  UserInfo,
  UserInfoExtend,
} from 'model/home';
import { WHITELIST_PERMISSION, WHITELIST_TYPE } from 'model/room.interface';
import { IUpdateUserInfo } from 'model/user.interface';
import moment from 'moment';
import { UserEdit } from 'pages/messages/mobile/user/edit';
import {
  AiOutlineCopy,
  AiOutlineMessage,
  AiOutlineSetting,
  AiOutlineUserDelete,
  AiOutlineWallet,
} from 'react-icons/ai';
import { BiGroup, BiKey } from 'react-icons/bi';
import { FaEthereum } from 'react-icons/fa';
import { IoCreateOutline } from 'react-icons/io5';
import { MdOutlineCancel, MdOutlineDarkMode } from 'react-icons/md';
import { TbMessageCircle2 } from 'react-icons/tb';
import { appActions, notificationActions, roomActions, userActions } from 'redux/action-slice';
import { homeActions } from 'redux/action-slice/home';
import ROUTES from 'routes/constant';
//import { web3Service } from 'services/web3.service';
import { AntdConfigProvider } from 'theme/antd.provider';
import { ColorDefault } from 'theme/color';
import { useOnClickOutside } from 'usehooks-ts';
//import Web3 from 'web3';
import { useDisconnect } from 'wagmi';

import { ConnectWallet } from './connect-wallet';
import { InviteModal } from './invite';
import { LanguageSwitching } from './language-switching';
import Styled from './navbar.style';
import { ProfileModal } from './profile';
import { Tab } from './tab';
import { UserPoints } from './user-points';
type ActiveKey = 'message' | 'community' | 'bag' | 'keys';

type AccountRef = {
  handlePrivateKey: (cb: (key: string) => void) => Promise<void>;
};

interface NavbarProps {
  className?: string;
  primary?: boolean;
  label?: string;
  size?: string;
}

type TabRef = {
  viewAll: () => void;
  readAllHandle: () => void;
};

const Navbar = forwardRef(({ className }: NavbarProps, ref) => {
  //state
  const { cid } = useParams();
  const wmb = window as any;
  const { colorMode, toggleColorMode } = useColorMode();
  const [openProfile, setOpenProfile] = useState(false);
  const [profileResponsive, setProfileResponsive] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [, setRefreshToken, removeCookie] = useCookies([R.strings.TOKEN_INFORMATION]);
  const accountRef = useRef<AccountRef>(null);
  //const isLoadingNavbar = useRef<boolean>(false);
  const [isLoadingNavbar, setIsLoadingNavbar] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const { disconnect } = useDisconnect();
  const {
    userInfo,
    chatSelectedAccount,
    token,
    privateKeys,
    isWhitelist,
    whitelistType,
    showDialog,
  } = useSelector(x => x.app);
  const [isMatch] = useMediaQuery('(max-width: 768px)');
  const [searchParams] = useSearchParams('code');
  const code = useMemo(() => searchParams.get('code'), [searchParams]);
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<ActiveKey>('message');
  const [activeNotify, setActiveNotify] = useState<string>('');
  const [value, setValue] = useState('');
  const [users, setUsers] = useState<UserInfoExtend[]>([]);
  const [openResult, setOpenResult] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  // const [incomings, setIncomings] = useState<Friend[]>([]);
  const tabRef = useRef<TabRef>(null);
  const location = useLocation();
  const navRef = useRef(null);
  const notifyRef = useRef(null);
  const [openLogout, setOpenLogout] = useState(false);
  const textColor = useColorModeValue('!text-black', '!text-white');
  const backgroundColor = useColorModeValue('!bg-white', '!bg-antd');
  const closeProfileResponsive = () => setProfileResponsive(false);
  const { socketListen, socketDisconnect, removeAllListeners } = useSocket();
  const isXSocialModal = useRef(false);
  useOnClickOutside(navRef, () => {
    setOpenResult(false);
  });

  useOnClickOutside(notifyRef, () => {
    setShowNotify(false);
    setActiveNotify('');
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        handlePrivateKey,
      };
    },
    [],
  );
  useEffect(() => {
    const isLoading = payload => {
      console.log('isLoadingSubmitKey:', payload);
      setIsLoadingNavbar(payload);
    };

    eventBus.on('isLoadingSubmitKey', isLoading);

    return () => {
      eventBus.off('isLoadingSubmitKey', isLoading);
    };
  }, [isLoadingNavbar]);
  useEffect(() => {
    const openSettingsModal = payload => {
      navigateSetting();
      isXSocialModal.current = true;
    };

    eventBus.on('openSettingsModal', openSettingsModal);

    return () => {
      eventBus.off('openSettingsModal', openSettingsModal);
    };
  }, []);
  useEffect(() => {
    if (location.pathname.includes(ROUTES.messages)) {
      setActiveKey(location.state?.tab || 'message');
    }
    if (location.pathname.includes(ROUTES.marketplace)) {
      setActiveKey(location.state?.tab || 'keys');
    }
  }, [location]);

  const onGetNotifyCount = () => {
    dispatch(
      notificationActions.onGetList(
        {
          numberPerPage: 100,
          page: 0,
          type: '',
        },
        response => {
          setTotal(response.count);
        },
      ),
    );
  };

  useEffect(() => {
    if (privateKeys && privateKeys.length > 0 && userInfo) {
      onGetNotifyCount();
    }
  }, [privateKeys, userInfo]);
  useEffect(() => {
    if (!userInfo?.wallet_address) {
      dispatch(appActions.onSetAddress(undefined));
    }
  }, [userInfo]);
  const [messageApi, contextHolder] = message.useMessage();
  //effect
  const [queryParameters] = useSearchParams();
  useEffect(() => {
    function getQueryParameter(name) {
      const url = window.location.href;
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    // Get the value of the 'error' query parameter
    const errorCode = getQueryParameter('error');

    console.log(errorCode, 'errorCode');
    if (errorCode === '403') {
      messageApi.open({
        type: 'error',
        content: $t('marketplace:error_message_twitter_account_connected'),
        duration: 5,
      });
    }
  }, [queryParameters, messageApi]);
  //func
  const handleThemeSwitch = useCallback(() => {
    toggleColorMode();
  }, [toggleColorMode]);

  const renderThemeSwitcher = useMemo(() => {
    const icon =
      colorMode === 'dark' ? (
        <span style={{ cursor: 'pointer' }}>☀️</span>
      ) : (
        <span style={{ cursor: 'pointer' }}>🌙</span>
      );

    return <Styled.ThemeSwitcher onClick={handleThemeSwitch}>{icon}</Styled.ThemeSwitcher>;
  }, [handleThemeSwitch, colorMode]);
  const getWhitelist = useCallback(async () => {
    if (!chatSelectedAccount) return;
    const res = await NetWorkService.Get<WHITELIST_PERMISSION>({
      url: ApiConstants.CHECK_WHITELIST,
      params: {
        address: chatSelectedAccount,
      },
    });
    if (!res || !res.data) return;
    dispatch(appActions.setWhitelistType(res.data?.type));
  }, [chatSelectedAccount]);
  useEffect(() => {
    getWhitelist();
  }, [chatSelectedAccount, userInfo, location, getWhitelist]);

  // const onGetFriendsRequestSuccess = (user: UserInfo | UserInfo[]) => {
  //   const requests = Array.isArray(user) ? user[0].friend_requests : user.friend_requests;
  //   setIncomings(requests);
  // };

  useEffect(() => {
    socketListen(SOCKET_EVENT_NAME.notification, arg => {
      onGetNotifyCount();
    });

    return () => {
      removeAllListeners(SOCKET_EVENT_NAME.notification);
    };
  }, [socketListen, removeAllListeners]);

  const NFTSale = userInfo ? (
    <Styled.NFT onClick={() => navigate(ROUTES.nftSale)}>
      <FaEthereum color={ColorDefault.primaryPurple} size={20} />
    </Styled.NFT>
  ) : null;

  const isMessagePath = () => {
    return (
      location.pathname.includes(ROUTES.messages) || location.pathname.includes(ROUTES.marketplace)
    );
  };

  const onAuthorization = async () => {
    const redirect_uri = getRedirectUri('home');
    dispatch(homeActions.onDiscordAuthorization({ redirect_uri }, onDiscordAuthorizationSucceeded));
  };

  const onDiscordAuthorizationSucceeded = (response: AuthorizationLink) => {
    if (!response.is_have_discord_information) {
      window.open(response.authorization_link, '_self');
    }
  };
  const onGetUserInfoSucceeded = useCallback(
    async (response: UserInfo) => {
      if (response._id) {
        dispatch(appActions.onSetUserInfo(response));
        dispatch(appActions.onSetAddress(response.wallet_address));
        dispatch(appActions.onSetChatSelectedAccount(response.wallet_address));

        // const permissionResponse = await NetWorkService.Get<WHITELIST_PERMISSION>({
        //   url: ApiConstants.CHECK_WHITELIST,
        //   params: {
        //     address: response.wallet_address,
        //   },
        // });

        const accepted = true;

        // if (permissionResponse?.data) {
        // const { is_exist } = permissionResponse.data;
        // dispatch(appActions.setIsWhitelist(is_exist));
        dispatch(appActions.setIsWhitelist(true));

        // if (!is_exist) {
        //   accepted = false;
        // }
        // }

        // automatically redirect to messages
        if (!location.pathname.includes(ROUTES.messages) && accepted) {
          navigate(ROUTES.messages);
        }

        if (location.pathname.includes(ROUTES.messages) && !accepted) {
          navigate(ROUTES.error);
        }
      }
    },
    [location.pathname, navigate],
  );
  const onDiscordInviteServerSucceeded = useCallback(
    (response: InviteServer) => {
      if (response && !response.is_in_discord_server) {
        window.open(response.invite_link, '_blank');
      }
      dispatch(homeActions.onGetUserInfo(onGetUserInfoSucceeded));
    },
    [onGetUserInfoSucceeded],
  );
  const onUserCodeDiscordSucceeded = useCallback(() => {
    dispatch(homeActions.onDiscordInviteServer(onDiscordInviteServerSucceeded));
  }, [onDiscordInviteServerSucceeded]);

  const handlePrivateKey = async (cb: (key: string) => void) => {
    await accountRef.current?.handlePrivateKey(cb);
  };

  // const addNetwork = async () => {
  //   await wmb.ethereum.request({
  //     method: 'wallet_addEthereumChain',
  //     params: [
  //       {
  //         chainId: NETWOKMB.chainId,
  //         chainName: NETWOKMB.chainName,
  //         nativeCurrency: {
  //           name: NETWOKMB.currencyName,
  //           symbol: NETWOKMB.currencySymbol,
  //           decimals: 18,
  //         },
  //         rpcUrls: [NETWOKMB.rpc],
  //         // blockExplorerUrls: [NETWOKR.rpcwss],
  //       },
  //     ],
  //   });
  // };

  // const switchToNetwork = async () => {
  //   if (wmb.ethereum.networkVersion !== NETWOKMB.chainId) {
  //     try {
  //       await wmb.ethereum.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: wmb.web3.utils.toHex(NETWOKMB.chainId) }],
  //       });
  //     } catch (err: any) {
  //       alert(err.message);
  //     }
  //   }
  // };

  // const connectWallet = async () => {
  //   if (wmb.ethereum) {
  //     try {
  //       wmb.web3 = new Web3(wmb.ethereum);
  //       //await wmb.ethereum.enable();
  //       const connectedAccounts: string[] = await web3Service.getAccountsConnected();
  //       const listAccount = (await Promise.all(
  //         connectedAccounts.map(
  //           account =>
  //             new Promise((resolve, reject) => {
  //               NetWorkService.Get({
  //                 url: ApiConstants.GET_USER_BY_ADDRESS,
  //                 params: { address: account },
  //               })
  //                 .then(res => resolve(res?.data))
  //                 .catch(reject);
  //             }),
  //         ),
  //       )) as UserInfo[];
  //       dispatch(appActions.onSetConnectedAccounts(listAccount));
  //       await addNetwork();
  //       await switchToNetwork();
  //       const accounts = await web3Service.getAccounts();
  //       if (accounts.length > 0) {
  //         const response: ResponseBase<MessageSign> | null = await NetWorkService.Get({
  //           url: ApiConstants.GET_MESSAGE_SIGN,
  //           params: { address: accounts[0] },
  //         });
  //         if (response?.data) {
  //           const signature = await wmb.ethereum.request({
  //             method: 'personal_sign',
  //             params: [response.data?.message, accounts[0]],
  //           });
  //           const token = `${response.data?.message}:${signature}`;
  //           setRefreshToken(R.strings.TOKEN_INFORMATION, token, {
  //             expires: moment(response.data.data.expire).toDate(),
  //             path: '/',
  //           });
  //           dispatch(appActions.onSetToken({ access_token: token }));
  //           dispatch(appActions.onSetAddress(accounts[0].toLowerCase()));
  //           dispatch(
  //             userActions.onCreateUserAddress(undefined, onCreateUserAddressSuccess, {
  //               [ADDRESS_KEY_HEADER]: accounts[0].toLowerCase(),
  //               [TOKEN_KEY_HEADER]: token,
  //             }),
  //           );
  //         }
  //       }
  //     } catch (error: any) {
  //       console.log(error.message);
  //     }
  //   } else {
  //     wmb.alert('Please Install MetaMask as a Chrome Extension');
  //   }
  // };

  //effect
  useEffect(() => {
    if (code && token && !location.pathname.includes(ROUTES.linkTelegram)) {
      dispatch(
        homeActions.onUserCodeDiscord(
          { code, redirect_uri: window.location.href.split('?')[0] },
          onUserCodeDiscordSucceeded,
        ),
      );
    }
  }, [code, token, location, onUserCodeDiscordSucceeded]);

  const onGetUserByAddressSucceeded = (data: UserInfoExtend[]) => {
    if (!isEmpty(data)) {
      setUsers(data);
    } else {
      setUsers([]);
    }
    setOpenResult(true);
  };

  const searchByAdress = debounce(async (address: string) => {
    const configuredAddress = await changeDomainToAddress(address);
    dispatch(
      roomActions.onGetUserByAddress(
        { address: configuredAddress?.toLowerCase() },
        onGetUserByAddressSucceeded,
      ),
    );
  }, 500);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (event.target.value) {
      debounceSearch(event.target.value);
    } else {
      setOpenResult(false);
    }
  };

  const handleEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.persist();
    if (event.key === 'Enter' && event.currentTarget.value) {
      const configuredAddress = await changeDomainToAddress(event.currentTarget.value);
      dispatch(
        roomActions.onGetUserByAddress(
          { address: configuredAddress?.toLowerCase() },
          onGetUserByAddressSucceeded,
        ),
      );
    } else {
      setOpenResult(false);
    }
  };

  const onSendFriendRequestSuccess = (data: UserInfo) => {
    message.success($t('messages:noti_send_friend_successfully'), 2);
    setValue('');
    setOpenResult(false);
  };

  const sendFriendRequest = (user: UserInfo) => {
    dispatch(
      userActions.onSendFriendRequest(
        {
          object_address: user.wallet_address,
        },
        onSendFriendRequestSuccess,
      ),
    );
  };

  const cancelFriendRequest = (user: UserInfo) => {
    try {
      setValue('');
      setOpenResult(false);
      NetWorkService.Post({
        url: ApiConstants.CANCEL_FRIEND_REQUEST,
        body: {
          sender_address: user.wallet_address,
        },
      }).then(res => {
        message.success($t('messages:noti_cancel_friend_successfully'));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onUnFriendSuccess = (user: UserInfo) => {
    message.success($t('messages:noti_unfriend_successfully'));
    setValue('');
    setOpenResult(false);
  };

  const unFriendRequest = (user: UserInfo) => {
    Modal.confirm({
      title: $t('messages:modal_title_confirm'),
      content: $t('messages:confirm_unfriend', {
        name: user.name || formatChatDotAddress(user.wallet_address),
      }),
      okButtonProps: {
        className: 'bg-blue-500',
      },
      cancelText: $t('messages:btn_cancel'),
      okText: $t('messages:btn_ok'),
      maskClosable: true,
      onCancel: () => {
        setValue('');
        setOpenResult(false);
      },
      onOk: () => {
        dispatch(
          userActions.onUnFriendRequest(
            {
              object_address: user.wallet_address,
            },
            data => {
              onUnFriendSuccess(data);
              // call list rooms again here to reload data
              eventBus.emit('reloadRooms', 'unfriend');

              // if (cid && isMessagePath()) {
              //   //dispatch(appActions.onSetTargetUser({ ...targetUser, is_friend: false }));
              //   dispatch(appActions.onSetReloadRoomInfo(true));
              // }
            },
          ),
        );
      },
    });
  };

  // const isFriend = (user: UserInfo) => {
  //   if (isArrayString(user.friends) && chatSelectedAccount) {
  //     return (user.friends as unknown as string[]).includes(chatSelectedAccount.toLowerCase());
  //   }

  //   if (chatSelectedAccount) {
  //     return user.friends.some(u => isEqual(u.wallet_address.toLowerCase(), chatSelectedAccount));
  //   }

  //   return false;
  // };

  const isCurrentAccount = (user: UserInfo) => {
    return (
      chatSelectedAccount &&
      isEqual(user.wallet_address.toLowerCase(), chatSelectedAccount.toLowerCase())
    );
  };

  const debounceSearch = useCallback((value: string) => searchByAdress(value), []);

  const handleNotify = () => {
    const mobileAgent = isMobile();
    if (isMatch || mobileAgent) {
      navigate(`${ROUTES.messages}/${ROUTES.notification}`);
      setActiveNotify('all');
    } else {
      setShowNotify(!showNotify);
      setActiveNotify(showNotify ? '' : 'all');
    }
  };

  const handleLogout = useCallback(async () => {
    disconnect();
    //localStorage.clear();
    socketDisconnect();
    setOpenLogout(false);
    removeCookie(R.strings.TOKEN_INFORMATION);
    const mobileAgent = isMobile();
    dispatch(mobileAgent ? appActions.resetKeepAccount() : appActions.reset());
  }, [removeCookie, disconnect, socketDisconnect]);

  const formatAmount = (amount: number): string => {
    return new Intl.NumberFormat().format(amount);
  };

  const renderAvatar = (avatar: string, wallet_address?: string) => {
    if (avatar) {
      return (
        <Avatar
          src={
            avatar.startsWith('http')
              ? avatar
              : `${process.env.REACT_APP_API_URL + avatar}&time=${moment().unix()}`
          }
          size={40}
        />
      );
    }
    if (wallet_address)
      return (
        <Jazzicon
          diameter={40}
          paperStyles={{ width: 40, height: 40 }}
          seed={jsNumberForAddress(wallet_address)}
        />
      );

    return null;
  };
  const navigateCreateSticker = useCallback(() => {
    navigate(ROUTES.createStickers);
  }, [navigate]);
  const navigateMessage = useCallback(
    async (info: UserInfo) => {
      // const permissionResponse = await NetWorkService.Get<WHITELIST_PERMISSION>({
      //   url: ApiConstants.CHECK_WHITELIST,
      //   params: {
      //     address: info.wallet_address,
      //   },
      // });

      const accepted = true;

      // if (permissionResponse?.data) {
      // const { is_exist } = permissionResponse.data;

      // if (!is_exist) {
      //   accepted = false;
      // }
      // }

      // eslint-disable-next-line no-nested-ternary

      if (accepted) {
        navigate(ROUTES.messages);
      } else {
        navigate(ROUTES.error);
      }
    },
    [navigate],
  );
  const renderDropdown = useMemo(() => {
    if (userInfo) {
      let menus: ItemType[] = [
        {
          label: <h1 className="text-ellipsis line-clamp-1">{userInfo.name || ''}</h1>,
          key: 'name',
          className: `${textColor} username`,
        },
        {
          type: 'divider',
        },
        {
          label: formatDotAddress(userInfo.wallet_address as string),
          key: 'wallet',
          icon: <AiOutlineWallet size={14} />,
          itemIcon: (
            <AiOutlineCopy
              onClick={() => {
                copyHandler(userInfo.wallet_address as string);
                message.success($t('messages:noti_copied'));
              }}
              size={14}
            />
          ),
          className: `${textColor}`,
        },
        // {
        //   label: 'DMTP',
        //   key: 'currency',
        //   icon: <BsCurrencyDollar size={15} />,
        //   itemIcon: (
        //     <span className="line-clamp-1 text-ellipsis">{formatAmount(userInfo.dmtp)}</span>
        //   ),
        //   className: `${textColor}`,
        // },
      ];
      console.log('isWhitelistAAA', isWhitelist);
      if (isWhitelist) {
        if (userInfo.is_minter) {
          menus = menus.concat({
            label: $t('messages:menu_create_new_sticker'),
            key: 'createSticker',
            icon: <IoCreateOutline size={14} />,
            onClick: () => navigateCreateSticker(),
            className: `${textColor}`,
          });
        }

        if (whitelistType === WHITELIST_TYPE.CRM || whitelistType === WHITELIST_TYPE.MASTER) {
          menus = menus.concat({
            label: $t('messages:menu_crm'),
            key: 'e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937',
            icon: <IoCreateOutline size={14} />,
            onClick: () => navigateCRM(),
            className: `${textColor}`,
          });
        }

        menus = menus.concat([
          {
            label: $t('messages:menu_message'),
            key: 'message',
            icon: <AiOutlineMessage size={14} />,
            onClick: () => navigateMessage(userInfo),
            className: `${textColor}`,
          },
          {
            label: $t('messages:menu_marketplace'),
            key: 'marketplace',
            icon: <BiKey size={14} />,
            onClick: () => navigate(ROUTES.marketplace),
            className: `${textColor}`,
          },
          // {
          //   label: 'Invite Friends',
          //   key: 'invite_friend',
          //   icon: <TbFriends size={14} />,
          //   onClick: () => setOpenInvite(true),
          //   className: `${colorMode === 'dark' ? '!text-white' : '!text-black'}`,
          // },
          {
            label: $t('messages:menu_setting'),
            key: 'setting',
            icon: <AiOutlineSetting size={14} />,
            onClick: () => navigateSetting(),
            // onClick: () => setOpenProfile(true),
            className: `${textColor}`,
          },
        ]);
      }

      menus = menus.concat([
        {
          label: <Switch isChecked={colorMode === 'dark'} onChange={handleThemeSwitch}></Switch>,
          key: 'theme',
          icon: <MdOutlineDarkMode size={15} />,
          className: `${textColor}`,
        },
        {
          type: 'divider',
        },
        {
          label: $t('messages:btn_logout'),
          key: 'logout',
          icon: <LogoutOutlined />,
          onClick: () => {
            setOpenLogout(true);
            // Modal.confirm({
            //   onOk: () => handleLogout(),
            // });
          },
          className: `${textColor}`,
        },
      ]);

      return (
        <Dropdown
          className={'cursor-pointer flex justify-center items-center'}
          overlayClassName={'w-52 h-44'}
          trigger={['click', 'contextMenu']}
          menu={{
            className: `${backgroundColor}`,
            items: menus,
          }}>
          <div>{renderAvatar(userInfo.avatar, userInfo.wallet_address)}</div>
        </Dropdown>
      );
    }

    return null;
  }, [
    userInfo,
    isWhitelist,
    colorMode,
    backgroundColor,
    handleThemeSwitch,
    navigate,
    navigateCreateSticker,
    navigateMessage,
    textColor,
    whitelistType,
  ]);

  useEffect(() => {
    setValue('');
    setUsers([]);
    setOpenResult(false);
  }, [chatSelectedAccount]);

  const onReadAll = () => {
    dispatch(
      notificationActions.onReadAll(() => {
        tabRef.current?.readAllHandle();
        setTotal(0);
      }),
    );
  };

  const navigateCRM = () => {
    const domain = window?.location?.protocol + '//' + window?.location?.host;
    window.open(`${domain}/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937`);
  };

  const navigateSetting = () => {
    const isMobile = window.innerWidth < 450;
    isMobile ? setProfileResponsive(true) : setOpenProfile(true);
  };

  const closeProfile = () => {
    isXSocialModal.current = false;
    setOpenProfile(false);
  };

  const closeInvite = () => setOpenInvite(false);

  const updateUserInfo = async (userModel: IUpdateUserInfo, cbSuccess?: () => void) => {
    const response = await NetWorkService.Post<UserInfo>({
      url: ApiConstants.UPDATE_USER_INFO,
      body: userModel,
    });

    if (response?.data) {
      message.success($t('messages:noti_update_user_info_successfully'));
      cbSuccess?.();
      closeProfile();
    } else {
      message.error(response?.message as string);
    }
  };

  const renderAvatarInSearch = (wallet_address: string, avatar: string) => {
    if (avatar) {
      return (
        <Avatar
          size={40}
          src={
            avatar.startsWith('http')
              ? avatar
              : `${process.env.REACT_APP_API_URL + avatar}&time=${moment().unix()}`
          }
        />
      );
    }

    return (
      <Jazzicon seed={jsNumberForAddress(wallet_address)} paperStyles={{ width: 40, height: 40 }} />
    );
  };

  const handleClick = (user: UserInfoExtend) => {
    setOpenResult(false);
    setValue('');
    setUsers([]);
    if (
      user.friend_status === FriendStatus.ACCEPTED &&
      user.room_id &&
      !location.pathname.includes(user.room_id)
    ) {
      navigate(`${ROUTES.messages}/${user.room_id}`, { replace: true });
    }
  };

  //render
  return (
    <>
      {contextHolder}
      {isLoadingNavbar ? (
        <div>
          <div className="bg-submit-loading"></div>
          <div className="bg-submit-loading !opacity-100 !bg-transparent w-full min-h-[100vh] flex flex-col items-center justify-center	">
            <ReactLoading type="bars" width={100} color="#7E4698" />
          </div>
        </div>
      ) : (
        <> </>
      )}

      <Styled.Container className={`h-14 ${className || ''} bg-main-color`} ref={navRef}>
        <Styled.Right className="flex flex-row items-center gap-x-10">
          <Row align="middle">
            <Col span={10}>
              <Link to="/">
                <img
                  src={'/images/DMTP-dark.png'}
                  className={`${
                    className && className !== 'left-0' ? 'w-[120px]' : 'w-[280px]'
                  } object-cover`}
                  alt="logo"
                />
              </Link>
            </Col>
            {isMessagePath() && (
              <Col span={14} className="relative">
                <Input
                  value={value}
                  onChange={handleChange}
                  onKeyDown={handleEnter}
                  onFocus={e => {
                    if (!e.target.value) {
                      setUsers([]);
                    } else {
                      if (users.length === 0) {
                        setOpenResult(false);
                      }
                    }
                  }}
                  className="rounded-full relative w-56 transition-[width] ease-in-out duration-[400]"
                  placeholder={$t('messages:address')}
                  prefix={
                    <SearchOutlined
                      size={18}
                      className="text-[#7c7c7c]"
                      color={ColorDefault.shade50}
                    />
                  }
                />
                {openResult && users.length > 0 && (
                  <div
                    className={`absolute ${
                      colorMode === 'dark' ? 'bg-antd shadow-black' : 'bg-white'
                    } top-12 left-0 rounded-sm right-0 w-[350px] max-h-56 overflow-scroll shadow-xl z-50`}>
                    {users.map(user => (
                      <div
                        key={user._id}
                        className={
                          'h-14 py-2 flex flex-row justify-between items-center text-xs pl-2 pr-4'
                        }>
                        <div className="flex flex-row items-center gap-x-4">
                          {renderAvatarInSearch(user.wallet_address, user.avatar)}
                          <div
                            className={`flex flex-col ${
                              colorMode === 'dark' ? 'text-white' : 'text-black'
                            } cursor-pointer`}
                            onClick={() => handleClick(user)}>
                            <h6 className="text-sm font-semibold hover:underline line-clamp-1 text-ellipsis max-w-[120px]">
                              {user.name}
                            </h6>
                          </div>
                        </div>
                        {user.friend_status === FriendStatus.NONE && !isCurrentAccount(user) && (
                          <motion.button
                            className="pl-3 pr-3 pt-1 pb-1 bg-main-color rounded-full text-xs flex justify-center items-center gap-x-2 text-white font-semibold"
                            whileTap={{
                              scale: 1.1,
                            }}
                            onClick={() => sendFriendRequest(user)}
                            whileHover={{ scale: 1.1 }}>
                            <span>{`${translate('app:friend_request')}`}</span>
                            <Icon icon="userAdd" size={18} />
                          </motion.button>
                        )}

                        {user.friend_status === FriendStatus.SENDER_WAIT &&
                          !isCurrentAccount(user) && (
                            <motion.button
                              className="pl-3 pr-3 pt-1 pb-1 bg-main-color rounded-full text-xs flex justify-center items-center gap-x-2 text-white font-semibold"
                              whileTap={{
                                scale: 1.1,
                              }}
                              onClick={() => cancelFriendRequest(user)}
                              whileHover={{ scale: 1.1 }}>
                              <span>{`${translate('app:cancel_friend_request')}`}</span>
                              <MdOutlineCancel size={18} />
                            </motion.button>
                          )}
                        {user.friend_status === FriendStatus.RECEIVER_WAIT &&
                          !isCurrentAccount(user) && (
                            <motion.button
                              className="pl-3 pr-3 pt-1 pb-1 bg-main-color rounded-full text-xs flex justify-center items-center gap-x-2 text-white font-semibold"
                              whileTap={{
                                scale: 1.1,
                              }}
                              onClick={() => {
                                setActiveNotify('friend');
                                setShowNotify(true);
                                setValue('');
                                setOpenResult(false);
                              }}
                              whileHover={{ scale: 1.1 }}>
                              <span>{`${translate('app:feedback')}`}</span>
                              <Icon icon="userAdd" size={18} />
                            </motion.button>
                          )}

                        {user.friend_status === FriendStatus.ACCEPTED &&
                          !isCurrentAccount(user) && (
                            <motion.button
                              className="pl-3 pr-3 pt-1 pb-1 bg-main-color rounded-full text-xs flex justify-center items-center gap-x-2 text-white font-semibold"
                              whileTap={{
                                scale: 1.1,
                              }}
                              onClick={() => unFriendRequest(user)}
                              whileHover={{ scale: 1.1 }}>
                              <span>{$t('messages:btn_unfriend')}</span>
                              <AiOutlineUserDelete size={18} />
                            </motion.button>
                          )}
                      </div>
                    ))}
                  </div>
                )}

                {openResult && users.length === 0 && (
                  <div
                    className={`absolute ${
                      colorMode === 'dark' ? 'bg-antd' : 'bg-white'
                    } z-30 top-12 left-0 right-0 w-[350px] min-h-[200px] shadow-2xl`}>
                    <Empty
                      description={
                        <div className={`${colorMode === 'dark' ? 'text-white' : 'text-black'}`}>
                          {$t('messages:no_result_found')}
                        </div>
                      }
                    />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Styled.Right>

        {(location.pathname.includes(ROUTES.messages) ||
          location.pathname.includes(ROUTES.marketplace)) && (
          <Tabs
            centered
            className="pl-2 pr-2 min-width-[300px]"
            activeKey={activeKey}
            tabBarStyle={{}}
            items={[
              {
                label: (
                  <Button
                    type="link"
                    className="p-0 border-0 bg-none active:bg-none focus:outline-none w-6 active:border-none"
                    onClick={() => {
                      navigate(ROUTES.messages, {
                        state: {
                          tab: 'message',
                        },
                      });
                      setActiveKey('message');
                    }}>
                    <TbMessageCircle2
                      size={24}
                      color={activeKey === 'message' ? '#7E4698' : '#fff'}
                    />
                  </Button>
                ),
                key: 'message',
              },

              {
                label: (
                  <Button
                    type="link"
                    className="p-0 border-0 bg-none active:bg-none focus:outline-none w-6 active:border-none"
                    onClick={() => {
                      navigate(`${ROUTES.messages}/${ROUTES.community}`, {
                        state: {
                          tab: 'community',
                        },
                      });
                      setActiveKey('community');
                    }}>
                    <BiGroup size={24} color={activeKey === 'community' ? '#7E4698' : '#fff'} />
                  </Button>
                ),
                key: 'community',
              },

              {
                label: (
                  <Button
                    type="link"
                    className="p-0 border-0 bg-none active:bg-none focus:outline-none w-6 active:border-none"
                    onClick={() => {
                      navigate(ROUTES.marketplace, {
                        state: {
                          tab: 'keys',
                        },
                      });
                      setActiveKey('keys');
                    }}>
                    <BiKey size={24} color={activeKey === 'keys' ? '#7E4698' : '#fff'} />
                  </Button>
                ),
                key: 'keys',
              },

              // {
              //   label: (
              //     <Button
              //       type="link"
              //       onClick={() => {
              //         navigate('/checkout');
              //         setActiveKey('bag');
              //       }}
              //       className="p-0 border-0 bg-none active:bg-none focus:outline-none w-6 active:border-none">
              //       <Icon icon="bag" size={20} color={activeKey === 'bag' ? '#7E4698' : '#7C7C7C'} />
              //     </Button>
              //   ),
              //   key: 'bag',
              // },
            ]}
          />
        )}

        <Styled.Left className="justify-end gap-x-2">
          {/* {NFTSale} */}
          {/* {userInfo?.dmtp && (
          <Row align="middle">
            <Space size={'small'}>
              <TextLibrary preset="Roboto13" text="$DMTP" colorTheme="shade50" />
              <TextLibrary
                colorTheme="primaryPurple"
                fontFamily="RobotoBold"
                text={userInfo?.dmtp ? numberWithCommas(userInfo?.dmtp) : numberWithCommas(0)}
              />
            </Space>
          </Row>
        )} */}
          {userInfo && <UserPoints className="text-white hidden xl:block mr-8" />}

          <LanguageSwitching />

          {userInfo && privateKeys?.length > 0 && (
            <React.Fragment>
              <div ref={notifyRef} className="relative cursor-pointer">
                <div
                  onClick={() => {
                    handleNotify();
                  }}>
                  <Badge
                    key={total.toString()}
                    size="small"
                    count={
                      total > 0 ? (
                        <Styled.BadgeStatus>
                          <Styled.BadgeStatusText>
                            {total >= 100 ? '99+' : total}
                          </Styled.BadgeStatusText>
                        </Styled.BadgeStatus>
                      ) : (
                        <></>
                      )
                    }
                    dot={false}
                    showZero={false}
                    className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center relative">
                    <Icon
                      icon="bell"
                      size={20}
                      color={ColorDefault.primaryPurple}
                      resizeMode="cover"
                    />
                  </Badge>
                </div>
                <div
                  id="scrollDiv"
                  className={`absolute ${
                    showNotify ? 'block' : 'hidden'
                  } top-16 -left-64 md:-left-72 w-[360px] h-[400px] md:w-[380px] ${
                    colorMode === 'dark' ? 'bg-antd border' : 'bg-white'
                  } rounded-xl shadow-2xl`}>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-row items-center justify-between px-4 pt-4">
                      <h6 className="text-base font-semibold">{$t('messages:notifications')}</h6>
                      <motion.button
                        onClick={onReadAll}
                        className="underline underline-offset-2 text-[11px] font-medium text-[#7E4698]"
                        whileTap={{ scale: 1.1 }}>
                        {`${translate('app:markAllAsRead')}`}
                      </motion.button>
                    </div>
                    <Tabs
                      activeKey={activeNotify}
                      onChange={setActiveNotify}
                      className="px-4"
                      tabBarGutter={20}
                      items={[
                        {
                          label: $t('messages:tab_all'),
                          key: 'all',
                          children: activeNotify === 'all' && (
                            <Tab
                              tabType="ALL"
                              setShowNotify={setShowNotify}
                              setActiveNotify={setActiveNotify}
                              ref={tabRef}
                              updateTotal={onGetNotifyCount}
                            />
                          ),
                          animated: true,
                        },
                      ]}
                    />
                  </div>
                  <div className="flex justify-end items-center pr-4">
                    <motion.button
                      onClick={() => {
                        tabRef.current?.viewAll();
                      }}
                      whileTap={{ scale: 1.1 }}
                      className="underline underline-offset-2 text-[11px] font-medium text-[#7E4698]">
                      {`${translate('app:viewAll')}`}
                    </motion.button>
                  </div>
                </div>
              </div>
              <div>{renderDropdown}</div>
              <AntdConfigProvider>
                {openProfile && (
                  <ProfileModal
                    isXSocialModal={isXSocialModal.current}
                    updateUserInfo={updateUserInfo}
                    open={openProfile}
                    onClose={closeProfile}
                    userInfo={userInfo}
                  />
                )}
                {userInfo && window.innerWidth < 450 && (
                  <UserEdit
                    updateUserInfo={updateUserInfo}
                    open={profileResponsive}
                    onClose={closeProfileResponsive}
                    userInfo={userInfo}
                  />
                )}
              </AntdConfigProvider>
              {openInvite && <InviteModal open={openInvite} onClose={closeInvite} />}
            </React.Fragment>
          )}
          <div>
            <ConnectWallet is_watch_address={true} ref={accountRef} />
          </div>
        </Styled.Left>
        <Modal
          width={350}
          centered
          open={openLogout}
          onCancel={() => setOpenLogout(false)}
          footer={null}>
          <div className="flex flex-col justify-center items-center gap-y-4">
            <h6 className="whitespace-pre-line text-sm font-sans font-medium">
              {translate('msg:MSG_040').toString()}
            </h6>
            <div className="flex items-center gap-x-3">
              <CKButton size={'sm'} autoFocus onClick={handleLogout}>
                {$t('messages:btn_logout')}
              </CKButton>
              <CKButton size={'sm'} bgColor={'transparent'} onClick={() => setOpenLogout(false)}>
                {$t('messages:btn_cancel')}
              </CKButton>
            </div>
          </div>
        </Modal>
      </Styled.Container>
    </>
  );
});

export default React.memo(Navbar, isEqual);
