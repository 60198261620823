import { useState } from 'react';

import { motion } from 'framer-motion';
import { translate } from 'library/utils';
import { debounce } from 'lodash';

interface ContextActionProps {
  onRejectFriend: (address: string, notificationId: string) => void;
  onAcceptFriend: (address: string, notificationId: string) => void;
  sender_address: string;
  notificationId: string;
}

export const ContextAction = ({
  onRejectFriend,
  onAcceptFriend,
  sender_address,
  notificationId,
}: ContextActionProps) => {
  const [hide, setHide] = useState(false);
  const debounceAccept = debounce(
    (address: string, notificationId: string) => onAcceptFriend(address, notificationId),
    500,
    {
      trailing: true,
    },
  );
  const debounceDeny = debounce(
    (address: string, notificationId: string) => onRejectFriend(address, notificationId),
    500,
    {
      trailing: true,
    },
  );

  return (
    <div className="flex items-center gap-x-2">
      <motion.button
        className={`${
          hide ? 'hidden' : 'flex'
        } justify-center text-[11px] items-center text-red-600 font-medium gap-x-1 border border-red-400 rounded-[4px] px-2 py-1`}
        whileTap={{ scale: 1.1 }}
        onClick={() => {
          setHide(true);
          debounceDeny(sender_address, notificationId);
        }}>
        <span>{`${translate('app:deny')}`}</span>
      </motion.button>
      <motion.button
        className={`${
          hide ? 'hidden' : 'flex'
        } justify-center text-[11px] items-center text-white font-medium gap-x-1 bg-main-color rounded-[4px] px-2 py-1`}
        whileTap={{ scale: 1.1 }}
        onClick={() => {
          setHide(true);
          debounceAccept(sender_address, notificationId);
        }}>
        <span>{`${translate('app:accept')}`}</span>
      </motion.button>
    </div>
  );
};
