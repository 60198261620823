import { NetWorkService } from 'library/networking/service-dmtp-internal';

const URL = process.env.REACT_APP_API_URL || 'https://dmtp-backend-new.vinaweb.app';

const handleResponse = response => {
  if (response?.code === 200) {
    return response?.data?.data || response?.data || response;
  } else {
    throw response;
  }
};

const getSubAccountList = async () => {
  const res = await NetWorkService.Get({
    url: `${URL}/sub-account/list`,
  });
  return handleResponse(res);
};
const getMainAccount = async () => {
  const res = await NetWorkService.Get({
    url: `${URL}/sub-account/main`,
  });
  return handleResponse(res);
};
const updateSubAccounts = async data => {
  const res = await NetWorkService.Post({ url: `${URL}/sub-account/update`, body: data });
  return handleResponse(res);
};

const func = {
  getSubAccountList,
  getMainAccount,
  updateSubAccounts,
};

export default func;
