const API_VERSION = '/';

const DISCORD = '/discord';
const USER = '/users';
const STICKER = '/sticker';
const POOL = '/pool';
const SESSION = '/session';
const MARKET = '/market';
const ROOM = '/room';
const MESSAGE = '/message';
const NOTIFICATION = '/notification';
const FILE = '/file';
const MESSAGE_GROUP_LIMIT = '/message-public-limited';
const MESSAGE_GROUP_UNLIMIT = '/message-public-unlimited';
const SHARED_KEY = '/shared-key';
const WHITELIST = '/whitelist';
const MAINTENANCE = '/update';
const TELEGRAM = '/telegram';
const CRM = '/crm';

const ApiEndPoint = {
  //USER
  USER_INFO: {
    service: USER,
    endpoint: 'user-info',
  },
  USER_CODE_DISCORD: {
    service: USER,
    endpoint: 'user-from-code-or-refresh-token-discord',
  },
  SUBMIT_KEY_PAIR: {
    service: USER,
    endpoint: 'submit-key-pair',
  },

  //DISCORD
  DISCORD_INVITE_SERVER: {
    service: DISCORD,
    endpoint: 'invite-server-link',
  },
  DISCORD_AUTHORIZATION: {
    service: DISCORD,
    endpoint: 'authorization-link',
  },
  //STICKER
  UPLOAD_IMAGE: {
    service: STICKER,
    endpoint: 'upload-image',
  },
  UPLOAD_IMAGES: {
    service: STICKER,
    endpoint: 'upload-folder',
  },
  UPLOAD_METADATA: {
    service: STICKER,
    endpoint: 'upload-nft-metadata',
  },
  GET_OWNER_STICKERS: {
    service: STICKER,
    endpoint: 'get-sticker-list',
  },
  GET_STICKER_DETAIL: {
    service: STICKER,
    endpoint: 'get-sticker-by-nft-id',
  },
  CSV_TO_ADDRESS: {
    service: STICKER,
    endpoint: 'csv-to-addresses',
  },
  GET_TOKEN: {
    service: STICKER,
    endpoint: 'get-tokens',
  },
  GET_SUPPORTED_CHAIN: {
    service: STICKER,
    endpoint: 'get-supported-chain',
  },
  GET_NFT_CONTRACT_INFO: {
    service: STICKER,
    endpoint: 'get-nft-contract-information',
  },

  // market
  GET_STICKERS: {
    service: MARKET,
    endpoint: 'get-sticker-market',
  },
  GET_LATEST_STICKER: {
    service: MARKET,
    endpoint: 'get-latest-sticker-market',
  },
  GET_WHITELIST_TOP_HASH: {
    service: MARKET,
    endpoint: 'get-whitelist-top-hash',
  },
  GET_PROOF: {
    service: MARKET,
    endpoint: 'get-proof',
  },

  //POOL
  GET_POOL_LIST: {
    service: POOL,
    endpoint: 'get-pool-list',
  },
  CLEAR_ALL_POOL: {
    service: POOL,
    endpoint: 'clear-all-pool',
  },
  REMOVE_TO_POOL: {
    service: POOL,
    endpoint: 'remove-to-pool',
  },
  UPDATE_TO_POOL: {
    service: POOL,
    endpoint: 'update-to-pool',
  },
  ADD_TO_POOL: {
    service: POOL,
    endpoint: 'add-to-pool',
  },

  // ROOM
  CREATE_ROOM: {
    service: ROOM,
    endpoint: 'create-room',
  },
  ADD_USER_OF_ROOM: {
    service: ROOM,
    endpoint: 'add-user-of-room',
  },
  JOIN_PUBLIC_ROOM: {
    service: ROOM,
    endpoint: 'join-public-room',
  },
  REMOVE_USER_OF_ROOM: {
    service: ROOM,
    endpoint: 'remove-user-of-room',
  },
  SET_ADMIN_OF_ROOM: {
    service: ROOM,
    endpoint: 'set-admin-of-room',
  },
  UNSET_ADMIN_OF_ROOM: {
    service: ROOM,
    endpoint: 'unset-admin-of-room',
  },
  REMOVE_ROOM: {
    service: ROOM,
    endpoint: 'remove-room',
  },
  RENAME_ROOM: {
    service: ROOM,
    endpoint: 'rename-room',
  },
  GET_ROOM_LIST: {
    service: ROOM,
    endpoint: 'get-room-list-v2',
  },
  GET_PUBLIC_ROOM_LIST: {
    service: ROOM,
    endpoint: 'get-public-room-list',
  },
  READ_MESSAGE_ROOM: {
    service: ROOM,
    endpoint: 'read-message',
  },
  GET_ALL_ENCRYPTED_MESSAGE_WITH_DMTP: {
    service: ROOM,
    endpoint: 'get-all-encrypted-message-with-dmtp',
  },
  GET_OPPOSITE_USER: {
    service: ROOM,
    endpoint: 'get-opposite-user',
  },
  GET_ROOM_ID: {
    service: ROOM,
    endpoint: 'get-room-id',
  },
  SET_ROLE_GROUP: {
    service: ROOM,
    endpoint: 'set-role-of-room',
  },
  UPDATE_ROOM: {
    service: ROOM,
    endpoint: 'update-room',
  },
  GET_ROOM_INFO: {
    service: ROOM,
    endpoint: 'get-room-info',
  },
  HIDE_ROOM: {
    service: ROOM,
    endpoint: 'hidden-room',
  },
  SET_NOTIFICATION_ROOM: {
    service: ROOM,
    endpoint: 'set-notification-room',
  },

  // messsage
  GET_MESSAGE_IN_ROOM: {
    service: MESSAGE,
    endpoint: 'get-message-in-room-v2',
  },
  RENEW_MESSAGE: {
    service: MESSAGE,
    endpoint: 'renew-message',
  },
  SEND_MESSAGE: {
    service: MESSAGE,
    endpoint: 'send-message-v2',
  },
  SEND_BATCH_MESSAGE: {
    service: MESSAGE,
    endpoint: 'send-batch-message-v2',
  },
  UPDATE_MESSAGE: {
    service: MESSAGE,
    endpoint: 'update-message-v2',
  },
  DELETE_MESSAGE: {
    service: MESSAGE,
    endpoint: 'delete-message',
  },
  REACT_MESSAGE: {
    service: MESSAGE,
    endpoint: 'react-message',
  },
  REMOVE_REACT_MESSAGE: {
    service: MESSAGE,
    endpoint: 'remove-reaction',
  },
  GET_CRM_MESSAGES: {
    service: MESSAGE,
    endpoint: 'get-crm-messages',
  },
  CREATE_CRM_MESSAGES: {
    service: MESSAGE,
    endpoint: 'create-crm-message',
  },

  // group unlimit
  GET_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'get-message-in-room',
  },
  SEND_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'send-message',
  },
  UPDATE_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'update-message',
  },
  DELETE_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'delete-message',
  },
  REACT_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'react-message',
  },
  REMOVE_REACT_MESSAGE_GROUP_UNLIMIT: {
    service: MESSAGE_GROUP_UNLIMIT,
    endpoint: 'remove-reaction',
  },

  // group limit
  GET_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'get-message-in-room',
  },
  SEND_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'send-message',
  },
  UPDATE_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'update-message',
  },
  DELETE_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'delete-message',
  },
  REACT_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'react-message',
  },
  REMOVE_REACT_MESSAGE_GROUP_LIMIT: {
    service: MESSAGE_GROUP_LIMIT,
    endpoint: 'remove-reaction',
  },

  //telegram
  VERIFY_TELEGRAM_OTP: {
    service: TELEGRAM,
    endpoint: 'verify-otp',
  },
  GET_TELEGRAM_AUTHORIZED_ID: {
    service: TELEGRAM,
    endpoint: 'get-authorized-id',
  },

  // user

  CREATE_USER_ADDRESS: {
    service: USER,
    endpoint: 'create-user-address',
  },

  GET_ENCRYPTED_MESSAGE: {
    service: USER,
    endpoint: 'get-encrypted-message',
  },

  GENERATE_API_KEY: {
    service: USER,
    endpoint: 'generate-api-key',
  },

  GET_FRIEND_LIST: {
    service: USER,
    endpoint: 'get-friend-list',
  },

  GET_TOTAL_UNREAD: {
    service: USER,
    endpoint: 'get-total-unread',
  },

  GET_FRIEND_REQUESTS: {
    service: USER,
    endpoint: 'get-friend-requests',
  },
  GET_USER_BY_ADDRESS: {
    service: USER,
    endpoint: 'get-user-by-address',
  },
  GET_USER_BY_NAME: {
    service: USER,
    endpoint: 'get-users-by-name',
  },
  SEND_FRIEND_REQUEST: {
    service: USER,
    endpoint: 'send-friend-request',
  },
  GET_ADDRESS_FROM_UNSTOPPABLE_DOMAIN: {
    service: USER,
    endpoint: 'unstoppable-domain-to-address',
  },
  ACCEPT_FRIEND_REQUEST: {
    service: USER,
    endpoint: 'accept-friend-request',
  },

  UNFRIEND_REQUEST: {
    service: USER,
    endpoint: 'unfriend',
  },

  UPDATE_USER_INFO: {
    service: USER,
    endpoint: 'create-or-update-user-info',
  },

  REJECT_FRIEND_REQUEST: {
    service: USER,
    endpoint: 'delete-friend-request',
  },
  CANCEL_FRIEND_REQUEST: {
    service: USER,
    endpoint: 'cancel-friend-request',
  },
  GET_WELCOME_MESSAGES: {
    service: USER,
    endpoint: 'get-welcome-messages',
  },
  CREATE_OR_UPDATE_WELCOME_MESSAGES: {
    service: USER,
    endpoint: 'create-or-update-welcome-message',
  },
  ADD_OFFICIAL_ACCOUNT: {
    service: USER,
    endpoint: 'add-official-account',
  },
  UPDATE_USER_WALLET_BALANCES: {
    service: USER,
    endpoint: 'update-user-wallet-balances',
  },

  // WHITELIST
  CHECK_WHITELIST: {
    service: WHITELIST,
    endpoint: 'check-whitelist',
  },
  GET_ADDRESSES_BY_TYPE: {
    service: WHITELIST,
    endpoint: 'get-addresses-by-type',
  },

  // NOTIFICATION
  GET_LIST_NOTIFICATION: {
    service: NOTIFICATION,
    endpoint: 'get-list',
  },
  READ_NOTIFICATION: {
    service: NOTIFICATION,
    endpoint: 'read-notification',
  },
  READ_ALL_NOTIFICATION: {
    service: NOTIFICATION,
    endpoint: 'read-all-notification',
  },

  //FILE
  DOWNLOAD_FILE: {
    service: FILE,
    endpoint: 'download-file',
  },

  //SESSION
  GET_MESSAGE_SIGN: {
    service: SESSION,
    endpoint: 'get-message-sign',
  },

  //SHARED-KEY
  GET_GROUP_SHARED_KEY: {
    service: SHARED_KEY,
    endpoint: 'get-shared-keys',
  },
  GET_GROUP_LATEST_SHARED_KEY: {
    service: SHARED_KEY,
    endpoint: 'get-latest-shared-keys',
  },
  GET_DMTP_PUB_KEY: {
    service: SHARED_KEY,
    endpoint: 'get-dmtp-pubkey',
  },

  // UPDATE
  GET_MAINTENANCE_STATUS: {
    endpoint: 'get-flag',
    service: MAINTENANCE,
  },

  //CRM
  GET_LOGIN_INFO: {
    endpoint: 'login-information',
    service: CRM,
  },
  GET_NFT_OWNERS: {
    endpoint: 'nft-owners',
    service: CRM,
  },
  UPDATE_CRM_MESSAGE_READ: {
    endpoint: 'update-crm-message-read',
    service: CRM,
  },
  GET_TRANSITION_OF_FRIENDS: {
    endpoint: 'get-transition-of-friends',
    service: CRM,
  },

  REFRESH_TOKEN: '',
} as const;

const configApi = () => {
  const apiOb: Record<string, string> = {};
  Object.keys(ApiEndPoint).forEach(x => {
    const valueApi = ApiEndPoint[x as keyof typeof ApiEndPoint];
    if (typeof valueApi !== 'string') {
      apiOb[x] = valueApi.service + API_VERSION + valueApi.endpoint;
    } else {
      apiOb[x] = API_VERSION + valueApi;
    }
  });
  return apiOb;
};

type ApiConstantsType<T> = {
  [a in keyof T]: string;
};
export const ApiConstants = configApi() as ApiConstantsType<typeof ApiEndPoint>;
