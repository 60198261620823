import internal from './internal';
import subAccount from './subAccount';
import tech from './tech';
import thegraph from './thegraph';
import twitter from './twitter';
const func = {
  thegraph,
  tech,
  internal,
  twitter,
  subAccount,
};
export default func;
