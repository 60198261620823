import { useState } from 'react';

import { Button, Input, Modal } from 'antd';
import { copyHandler } from 'common/utils/core';
import { $t } from 'library/utils';
import { delay } from 'lodash';
import { AiOutlineCopy } from 'react-icons/ai';
import { IoCheckmarkDone } from 'react-icons/io5';

interface InviteModalProps {
  open: boolean;
  onClose: () => void;
  invitation_link?: string;
}

export const InviteModal = ({
  open,
  onClose,
  invitation_link = 'https://www.dmtp.tech/Q4Jhe56ks0sh',
}: InviteModalProps) => {
  const [isCopyDone, setIsCopyDone] = useState(false);
  const handleCopy = (invitation_link: string) => {
    setIsCopyDone(true);

    copyHandler(invitation_link);
    delay(() => setIsCopyDone(false), 2000);
  };
  return (
    <Modal
      footer={null}
      centered
      title={<h6 className="text-center text-lg">{$t('messages:modal_invite:title')}</h6>}
      open={open}
      onCancel={onClose}>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-2 items-end">
          <div className="flex flex-col flex-[2]">
            <label>{$t('messages:modal_invite:invitation_url')}</label>
            <Input
              value={invitation_link}
              disabled
              placeholder={$t('messages:modal_invite:invite_link')}
            />
          </div>
          <Button onClick={() => handleCopy(invitation_link)}>
            {isCopyDone ? (
              <IoCheckmarkDone color="#6559f5" size={20} />
            ) : (
              <AiOutlineCopy color="#6559f5" size={20} />
            )}
          </Button>
        </div>

        <div>
          <span className="whitespace-pre-line">{$t('messages:modal_invite:description')}</span>
        </div>

        <div className="flex items-center justify-center gap-x-2">
          <Button onClick={onClose}>{$t('messages:btn_close')}</Button>
        </div>
      </div>
    </Modal>
  );
};
