import { NetWorkService } from 'library/networking';
import ROUTES from 'routes/constant';

const URL = process.env.REACT_APP_API_URL || 'https://dev.dmtp.tech';
const URL_IMAGE_TECH = process.env.REACT_APP_API_URL;

const toProfile = address => {
  return `${ROUTES.marketplace}/${ROUTES.keysKOLProfile}/${address}`;
};
const handleResponse = response => {
  if (response?.code === 200) {
    return response?.data?.data || response?.data || response;
  } else {
    throw response;
  }
};

// room - start
const postCreateRoom = async params => {
  const res = await NetWorkService.Post({ url: `${URL}/room/create-key-room`, body: params });
  return handleResponse(res);
};
const postAddUserOfRoom = async params => {
  const res = await NetWorkService.Post({ url: `${URL}/room/add-user-of-room`, body: params });
  return handleResponse(res);
};
const postRemoveUserOfRoom = async params => {
  const res = await NetWorkService.Post({ url: `${URL}/room/remove-user-of-room`, body: params });
  return handleResponse(res);
};
const getRoomList = async params => {
  const res = await NetWorkService.Get({ url: `${URL}/room/get-room-list-v2`, params });
  return handleResponse(res);
};
const getRoomInfo = async room_id => {
  const res = await NetWorkService.Get({ url: `${URL}/room/get-room-info?room_id=${room_id}` });
  return handleResponse(res);
};
const getRoomIdByAddress = async wallet_address => {
  wallet_address = wallet_address?.toLowerCase();
  const res = await NetWorkService.Get({
    url: `${URL}/room/get-key-room?wallet_address=${wallet_address}`,
  });
  return handleResponse(res);
};
// room - end

// whitelist - start
const checkIsWhiteListAddress = async address => {
  const res = await NetWorkService.Get({
    url: `${URL}/whitelist/check-whitelist?address=${address}`,
  });
  return handleResponse(res);
};
// whitelist - end

// user - start
const getUserInfo = async () => {
  const res = await NetWorkService.Get({ url: `${URL}/users/user-info` });
  return handleResponse(res);
};
const getUserPoint = async () => {
  const res = await NetWorkService.Get({ url: `${URL}/users/user-point` });
  return handleResponse(res);
};
const getUserByAddress = async address => {
  const res = await NetWorkService.Get({
    url: `${URL}/users/get-user-by-address?address=${address}`,
  });
  return handleResponse(res);
};
const getUserFormAddresses = async params => {
  const res = await NetWorkService.Get({ url: `${URL}/users/get-users-from-addresses`, params });
  return handleResponse(res);
};
const getUsersByName = async name => {
  const res = await NetWorkService.Get({
    url: `${URL}/users/get-users-by-name?name=${encodeURIComponent(name)}`,
  });
  return handleResponse(res);
};
const createUserAddress = async () => {
  const res = await NetWorkService.Post({ url: `${URL}/users/create-user-addressbody: ` });
  return handleResponse(res);
};
const createOrUpdateUserInfo = async params => {
  const res = await NetWorkService.Post({
    url: `${URL}/users/create-or-update-user-info`,
    body: params,
  });
  return handleResponse(res);
};
const mapUserAddress = async arr_address => {
  try {
    if (!arr_address || !arr_address.length) return null;
    const res_users = await getUserFormAddresses({
      address: arr_address,
    });
    const info = {};
    for (let i = 0; i < arr_address.length; i++) {
      const address = arr_address[i]?.toLowerCase();
      const user = res_users.find(i => i.wallet_address?.toLowerCase() == address);

      info[address] = {
        ...user,
        name: user?.name && user?.name != 'Unnamed' ? user?.name : '',
        address: address,
        avatar: user?.avatar,
        urlImage: user?.avatar ? `${URL_IMAGE_TECH}${user?.avatar}` : '',
        url_profile: toProfile(address),
      };
    }
    return info;
  } catch (error) {
    const info = {};
    for (let i = 0; i < arr_address.length; i++) {
      const address = arr_address[i];
      info[address.toLowerCase()] = {
        name: '',
        avatar: '',
        address: address,
        url_profile: toProfile(address),
      };
    }
    return info;
  }
};
// user - end

// get news from homepage v2
const getLatestNews = async params => {
  const { limit, offset, language } = params;
  const res = await NetWorkService.Get({
    url: `${URL}/news?offset=${offset}&limit=${limit}&language=${language}`,
  });
  console.log('🚀 ~ getLatestNews ~ res:', res);

  return handleResponse(res);
};
const func = {
  postCreateRoom,
  postAddUserOfRoom,
  postRemoveUserOfRoom,
  getRoomList,
  getRoomInfo,
  getRoomIdByAddress,

  checkIsWhiteListAddress,

  getUserInfo,
  getUserPoint,
  getUsersByName,
  getUserByAddress,
  getUserFormAddresses,
  createUserAddress,
  createOrUpdateUserInfo,
  mapUserAddress,
  getLatestNews,
};
export default func;
