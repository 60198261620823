export interface DiscordAuthorizationResponse {
  data: string;
  message: string;
}
export interface Discord {
  id: string;
  discriminator: string;
  refresh_token: string;
  username: string;
  is_in_discord_server: boolean;
}

export interface Friend {
  _id: string;
  wallet_address: string;
  name: string;
  email: string;
  avatar: string;
  public_key?: string;
}

export interface FriendWithRoom extends Friend {
  room_id: string;
}

export interface UserInfo {
  _id: string;
  api_key: string;
  discord: Discord;
  stickers: unknown[];
  wallet_address: string;
  dmtp: number;
  active_points: number;
  premium: boolean;
  avatar: string;
  email: string;
  name: string;
  friends: FriendWithRoom[];
  friend_requests: Friend[];
  created_at: Date;
  is_minter: boolean;
  invitation_url: string;
  campaign: string;
  dmtp_pub_key?: string;
  dmtp_priv_key?: string;
  description: string;
  link_addresses: string[];
  telegram: string;
  auth: any;
  twitter: any;
  devices: any[];
}
export interface AuthenKey {
  access_token: string;
  expires_in: number;
  refresh_token: number;
  token_type: number;
  scope: number;
  user: any;
}

export enum FriendStatus {
  NONE = 0,
  SENDER_WAIT = 1,
  ACCEPTED = 2,
  RECEIVER_WAIT = 3,
  REJECTED = 4,
}

export interface UserInfoExtend extends UserInfo {
  friend_status: FriendStatus;
  room_id: string;
}

export interface InviteServer {
  invite_link: string;
  is_in_discord_server: boolean;
}

export interface UploadMetaDataRequest {
  name: string;
  description?: string;
  images: Array<string>;
}

export interface MessageSign {
  message: string;
  data: dataSign;
}

interface dataSign {
  address: string;
  expire: number;
}

export interface Token {
  contract_address: string;
  decimals: number;
  symbol: string;
  name: string;
  logo: string;
}
export interface AuthorizationLink {
  is_have_discord_information: true;
  authorization_link: string;
}

export interface Maintenance {
  created_at: string;
  done_at: string;
}
