import { SLICE_NAME } from 'config/type';

export const GET_ROOM_LIST = SLICE_NAME.ROOM + 'GET_ROOM_LIST';
export const GET_PUBLIC_ROOM_LIST = SLICE_NAME.ROOM + 'GET_PUBLIC_ROOM_LIST';
export const CREATE_ROOM = SLICE_NAME.ROOM + 'CREATE_ROOM';
export const ADD_USER_TO_ROOM = SLICE_NAME.ROOM + 'ADD_USER_TO_ROOM';
export const JOIN_PUBLIC_ROOM = SLICE_NAME.ROOM + 'JOIN_PUBLIC_ROOM';
export const REMOVE_USER_OF_ROOM = SLICE_NAME.ROOM + 'REMOVE_USER_OF_ROOM';
export const SET_ADMIN_OF_ROOM = SLICE_NAME.ROOM + 'SET_ADMIN_OF_ROOM';
export const UNSET_ADMIN_OF_ROOM = SLICE_NAME.ROOM + 'UNSET_ADMIN_OF_ROOM';
export const REMOVE_ROOM = SLICE_NAME.ROOM + 'REMOVE_ROOM';
export const RENAME_ROOM = SLICE_NAME.ROOM + 'RENAME_ROOM';
export const ADD_USER_TO_PUBLIC_ROOM = SLICE_NAME.ROOM + 'ADD_USER_TO_PUBLIC_ROOM';
export const READ_MESSAGE_ROOM = SLICE_NAME.ROOM + 'READ_MESSAGE_ROOM';
export const RECEIVE_MESSAGE_ROOM = SLICE_NAME.ROOM + 'RECEIVE_MESSAGE_ROOM';
export const GET_MESSAGE_IN_ROOM = SLICE_NAME.ROOM + 'GET_MESSAGE_IN_ROOM';
export const SEND_MESSAGE = SLICE_NAME.ROOM + 'SEND_MESSAGE';
export const SEND_BATCH_MESSAGE = SLICE_NAME.ROOM + 'SEND_BATCH_MESSAGE';
export const GET_USER_BY_ADDRESS = SLICE_NAME.ROOM + 'GET_USER_BY_ADDRESS';
export const GET_USER_BY_NAME = SLICE_NAME.ROOM + 'GET_USER_BY_NAME';
export const GET_ROOM_ID = SLICE_NAME.ROOM + 'GET_ROOM_ID';
export const USER_REACT_MESSAGE = SLICE_NAME.ROOM + 'USER_REACT_MESSAGE';
export const USER_REMOVE_REACT_MESSAGE = SLICE_NAME.ROOM + 'USER_REMOVE_REACT_MESSAGE';
export const USER_DELETE_MESSAGE = SLICE_NAME.ROOM + 'USER_DELETE_MESSAGE';
export const USER_UPDATE_MESSAGE = SLICE_NAME.ROOM + 'USER_UPDATE_MESSAGE';
export const GET_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'GET_MESSAGE_GROUP_UNLIMIT';
export const SEND_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'SEND_MESSAGE_GROUP_UNLIMIT';
export const UPDATE_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'UPDATE_MESSAGE_GROUP_UNLIMIT';
export const REACT_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'REACT_MESSAGE_GROUP_UNLIMIT';
export const DELETE_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'DELETE_MESSAGE_GROUP_UNLIMIT';
export const REMOVE_REACT_MESSAGE_GROUP_UNLIMIT = SLICE_NAME.ROOM + 'DELETE_MESSAGE_GROUP_UNLIMIT';
export const GET_MESSAGE_GROUP_LIMIT = SLICE_NAME.ROOM + 'GET_MESSAGE_GROUP_LIMIT';
export const SEND_MESSAGE_GROUP_LIMIT = SLICE_NAME.ROOM + 'SEND_MESSAGE_GROUP_LIMIT';
export const UPDATE_MESSAGE_GROUP_LIMIT = SLICE_NAME.ROOM + 'UPDATE_MESSAGE_GROUP_LIMIT';
export const REACT_MESSAGE_GROUP_LIMIT = SLICE_NAME.ROOM + 'REACT_MESSAGE_GROUP_LIMIT';
export const DELETE_MESSAGE_GROUP_LIMIT = SLICE_NAME.ROOM + 'DELETE_MESSAGE_GROUP_LIMIT';
export const REMOVE_REACT_MESSAGE_GROUP_LIMIT =
  SLICE_NAME.ROOM + 'REMOVE_REACT_MESSAGE_GROUP_LIMIT';
export const SET_ROLE_OF_ROOM = SLICE_NAME.ROOM + 'SET_ROLE_OF_ROOM';
export const UPDATE_ROOM = SLICE_NAME.ROOM + 'UPDATE_ROOM';
export const GET_ROOM_INFO = SLICE_NAME.ROOM + 'GET_ROOM_INFO';
export const GET_GROUP_SHARED_KEYS = SLICE_NAME.ROOM + 'GET_GROUP_SHARED_KEYS';
export const GET_GROUP_LATEST_SHARED_KEYS = SLICE_NAME.ROOM + 'GET_GROUP_LATEST_SHARED_KEYS';
