const json_keys = `
  author
  amount
  keyId
  price
  supply
  totalVolume
  createdAt
`;

const json_activities = `
  author
  address
  keyId
  price
  txHash
  timestamp
  type
`;

const json_own_keys = `
  author
  address
  amount
  keyId
`;
const json = {
  json_keys,
  json_activities,
  json_own_keys,
};
export default json;
