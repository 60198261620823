import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from 'react-infinite-scroll-component';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useColorMode } from '@chakra-ui/react';
import { Avatar, Empty } from 'antd';
/* eslint-enable import/order */
import { dispatch, SOCKET_EVENT_NAME, useSelector, useSocket } from 'common';
/* eslint-disable import/order */
import { calculatePage, formatDotAddress } from 'common/utils/core';
import { motion } from 'framer-motion';
import { Icon } from 'library/components/icon';
import { $t, translate } from 'library/utils';
import eventBus from 'library/utils/eventBus';
import { cloneDeep, isEqual, last, uniqBy } from 'lodash';
import {
  FriendAccept,
  FriendRequest,
  Notification,
  NotificationDataMessage,
  NotificationEmbed,
  NotificationType,
  NotificationUnion,
} from 'model/notification';
import moment from 'moment';
import { appActions, notificationActions, roomActions, userActions } from 'redux/action-slice';
import ROUTES from 'routes/constant';

import { ContextAction } from './context-action';

type TabType = 'NEW_MESSAGE' | 'FRIEND' | 'FRIEND_REQUEST' | 'ALL';

interface IPaginate {
  page: number;
  numberPerPage: number;
  type: TabType;
  isConcat: boolean;
}

interface TabProps {
  tabType: TabType;
  setShowNotify: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveNotify: React.Dispatch<React.SetStateAction<string>>;
  updateTotal: () => void;
}

export const Tab = forwardRef(
  ({ tabType, setShowNotify, setActiveNotify, updateTotal }: TabProps, ref) => {
    const { colorMode } = useColorMode();
    const { privateKeys, userInfo } = useSelector(state => state.app);
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState<IPaginate>({
      page: 0,
      numberPerPage: 20,
      type: tabType,
      isConcat: true,
    });
    const [hasMore, setHasMore] = useState(true);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { socketListen, removeAllListeners } = useSocket();
    const { cid } = useParams();
    const location = useLocation();

    useEffect(() => {
      socketListen(SOCKET_EVENT_NAME.notification, arg => {
        setPaginate(paginate => ({
          ...paginate,
          page: 0,
          numberPerPage: (paginate.page + 1) * paginate.numberPerPage,
          isConcat: false,
        }));
        updateTotal();
      });
      return () => {
        removeAllListeners(SOCKET_EVENT_NAME.notification);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabType, socketListen, removeAllListeners]);

    const readAllHandle = useCallback(() => {
      const newNotifications = cloneDeep(notifications);
      setNotifications(newNotifications.map(n => ({ ...n, is_read: true })));
    }, [notifications]);

    const onGetAllNotify = useCallback(() => {
      dispatch(
        notificationActions.onGetList(
          { page: 0, numberPerPage: 100000, type: tabType },
          response => {
            setNotifications(response.notifications);
            setHasMore(false);
          },
        ),
      );
    }, [tabType]);

    useImperativeHandle(
      ref,
      () => {
        return {
          viewAll() {
            onGetAllNotify();
          },
          readAllHandle() {
            readAllHandle();
          },
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onGetAllNotify, readAllHandle],
    );

    const onGetNotifyList = useCallback(() => {
      const { isConcat, ...rest } = paginate;
      dispatch(
        notificationActions.onGetList(rest, response => {
          if (response.notifications.length < paginate.numberPerPage) {
            setHasMore(false);
          }
          if (paginate.page === 0 && response.notifications.length === 0) {
            setNotifications([]);
            return;
          }

          if (isConcat) {
            setNotifications(n => uniqBy(n.concat(response.notifications), n => n._id));
          } else {
            setNotifications(response.notifications);
          }
        }),
      );
    }, [paginate]);

    useEffect(() => {
      if (privateKeys && privateKeys.length > 0 && userInfo) {
        onGetNotifyList();
      }
    }, [privateKeys, onGetNotifyList, userInfo]);

    const isEmbed = (data: NotificationDataMessage) => {
      if ((data as unknown as NotificationEmbed).embeds) {
        return true;
      }

      return false;
    };

    const getSenderAdress = (request: FriendRequest) => {
      if (request.sender_address) return request.sender_address;

      if (request.user) return request.user?.wallet_address;

      return '';
    };

    const getAcceptAddress = (request: FriendAccept) => {
      if (request.accept_address) return request.accept_address;

      if (request.user) return request.user?.wallet_address;

      return '';
    };

    const isFriendRequest = (data: NotificationUnion) => {
      if (
        (data as unknown as FriendRequest).sender_address ||
        (data as unknown as FriendRequest).user
      ) {
        return true;
      }

      return false;
    };

    const isFriendAccept = (data: NotificationUnion) => {
      if (
        (data as unknown as FriendAccept).accept_address ||
        (data as unknown as FriendAccept).user
      ) {
        return true;
      }

      return false;
    };

    const isMessagePath = () => {
      return location.pathname.includes(ROUTES.messages);
    };

    const onAcceptFriend = (sender_address: string, notificationId: string) => {
      setActiveNotify('friend');
      dispatch(
        userActions.onAcceptFriendRequest(
          {
            sender_address,
          },
          () => {
            // if (cid && isMessagePath()) {
            //   dispatch(appActions.onSetReloadRoomInfo(true));
            // }
            // call list rooms again here to reload data
            eventBus.emit('reloadRooms');
            setNotifications(notifications =>
              notifications.filter(notify => notify._id !== notificationId),
            );
            updateTotal();
          },
        ),
      );
    };

    const onRejectFriend = (sender_address: string, notificationId: string) => {
      setActiveNotify('friend');
      dispatch(
        userActions.onRejectFriendRequest(
          {
            sender_address,
          },
          () => {
            setNotifications(notifications =>
              notifications.filter(notify => notify._id !== notificationId),
            );
            updateTotal();
          },
        ),
      );
    };

    const renderNotificationAction = (notification: Notification) => {
      if (isEmbed(notification.data)) {
        const { type, embeds, data } = notification.data as unknown as NotificationEmbed;
        const [item] = embeds;
        const strings = item.url.split('/');
        const roomId = last(strings) as string;
        if (type === NotificationType.NEW_MESSAGE) {
          return (
            <motion.button
              onClick={() => {
                setShowNotify(false);
                setActiveNotify('message');
                if (window.location.pathname === `${ROUTES.messages}/${roomId}`) return;
                navigate(`${ROUTES.messages}/${roomId}`, { state: { tab: 'roomId' } });
                dispatch(
                  roomActions.onReadMessageRoom(
                    {
                      message_id: '',
                      room_id: roomId,
                    },
                    () => {
                      dispatch(appActions.onSetReloadRoom(true));
                    },
                  ),
                );
                // if (data && data.user) {
                //   dispatch(appActions.onSetTargetUser({ ...data.user, is_friend: true }));
                // }
              }}
              className="flex justify-center text-[11px] items-center text-white gap-x-1 min-w-[89px] bg-main-color rounded-full px-2 py-1"
              whileTap={{ scale: 1.1 }}>
              <Icon icon="icMessage" size={10} />
              <span>{`${translate('app:reply')}`}</span>
            </motion.button>
          );
        }
      }

      if (
        notification.data.data &&
        isFriendRequest(notification.data.data) &&
        notification.data.type === NotificationType.FRIEND_REQUEST
      ) {
        const sender_address = getSenderAdress(notification.data.data as unknown as FriendRequest);
        return (
          <ContextAction
            onAcceptFriend={onAcceptFriend}
            onRejectFriend={onRejectFriend}
            sender_address={sender_address}
            notificationId={notification._id}
          />
        );
      }

      if (
        notification.data.data &&
        isFriendAccept(notification.data.data) &&
        notification.data.type === NotificationType.FRIEND_ACCEPT
      ) {
        return (
          <motion.button
            className="flex justify-center text-[11px] items-center text-white gap-x-1 min-w-[89px] bg-main-color rounded-full px-2 py-1"
            whileTap={{ scale: 1.1 }}
            onClick={() => {
              setShowNotify(false);
              setActiveNotify('message');
              const notifyData: any = notification.data.data;
              if (location.pathname.includes(notifyData.room_id)) return;
              if (notifyData.room_id) {
                navigate(`${ROUTES.messages}/${notifyData.room_id}`, { state: { tab: 'roomId' } });
                // if (notifyData && notifyData.user) {
                //   dispatch(appActions.onSetTargetUser(notifyData.user));
                // }
                dispatch(
                  roomActions.onReadMessageRoom(
                    {
                      message_id: '',
                      room_id: notifyData.room_id,
                    },
                    () => {
                      dispatch(appActions.onSetReloadRoom(true));
                    },
                  ),
                );
              }
            }}>
            <Icon icon="icMessage" size={10} />
            <span>{`${translate('app:message')}`}</span>
          </motion.button>
        );
      }

      return <div className="min-w-[89px]"></div>;
    };

    const getNotificationText = (notification: Notification) => {
      if (isEmbed(notification.data)) {
        return (notification.data as unknown as NotificationEmbed).embeds[0].title;
      }

      if (
        notification.data &&
        isEqual(notification.data.type, NotificationType.FRIEND_REQUEST) &&
        (notification.data.data as unknown as FriendRequest)
      ) {
        // return `<b>You have a friend request from</b> ${
        //   (notification.data.data as unknown as FriendRequest).user.wallet_address
        // } to ${formatDotAddress(notification.to_address)}`;
        return $t('messages:confirm_friend_request', {
          name_from: (notification.data.data as unknown as FriendRequest).user?.wallet_address,
          name_to: formatDotAddress(notification.to_address),
        });
      }

      return notification.content;
    };

    const getAvatar = (notification: Notification) => {
      if (
        isEmbed(notification.data) &&
        (notification.data as unknown as NotificationEmbed).data &&
        (notification.data as unknown as NotificationEmbed).data.user
      ) {
        return (notification.data as unknown as NotificationEmbed).data.user?.avatar;
      }

      if (
        notification.data.data &&
        isFriendRequest(notification.data.data) &&
        (notification.data.data as unknown as FriendRequest).user
      ) {
        return (notification.data.data as unknown as FriendRequest).user?.avatar;
      }

      if (
        notification.data.data &&
        isFriendAccept(notification.data.data) &&
        (notification.data.data as unknown as FriendAccept).user
      ) {
        return (notification.data.data as unknown as FriendAccept).user?.avatar;
      }

      return '';
    };

    const getWalletAddress = (notification: Notification) => {
      if (
        isEmbed(notification.data) &&
        (notification.data as unknown as NotificationEmbed).data &&
        (notification.data as unknown as NotificationEmbed).data.user
      ) {
        return (notification.data as unknown as NotificationEmbed).data.user?.wallet_address;
      }

      if (
        notification.data.data &&
        isFriendRequest(notification.data.data) &&
        (notification.data.data as unknown as FriendRequest).user
      ) {
        return (notification.data.data as unknown as FriendRequest).user?.wallet_address;
      }

      if (
        notification.data.data &&
        isFriendAccept(notification.data.data) &&
        (notification.data.data as unknown as FriendAccept).user
      ) {
        return (notification.data.data as unknown as FriendAccept).user?.wallet_address;
      }

      return '';
    };

    // const renderNotificationAvatar = (avatar: string, wallet_address: string) => {
    //   return (
    //     <Jazzicon
    //       paperStyles={{ width: 32, height: 32 }}
    //       seed={jsNumberForAddress(wallet_address)}
    //     />
    //   );
    // };
    const renderNotificationAvatar = (avatar: string, wallet_address?: string) => {
      if (avatar) {
        return (
          <Avatar
            src={
              avatar.startsWith('http')
                ? avatar
                : `${process.env.REACT_APP_API_URL + avatar}&time=${moment().unix()}`
            }
            size={32}
          />
        );
      }
      if (wallet_address)
        return (
          <Jazzicon
            diameter={32}
            paperStyles={{ width: 32, height: 32 }}
            seed={jsNumberForAddress(wallet_address)}
          />
        );

      return null;
    };

    const onRead = (notification_id: string) => {
      dispatch(
        notificationActions.onRead(
          {
            notification_id,
          },
          () => {
            updateTotal();
            const newNotifications = cloneDeep(notifications);
            const index = newNotifications.findIndex(n => n._id === notification_id);
            if (index > -1) {
              newNotifications[index] = {
                ...newNotifications[index],
                is_read: true,
              };
              setNotifications(newNotifications);
            }
          },
        ),
      );
    };

    const renderDotRead = (notification: Notification) => {
      if (!notification.is_read) {
        return <div className="w-[6px] h-[6px] rounded-full bg-[#7E4698]"></div>;
      }

      return null;
    };

    return (
      <div className="h-full lg:h-[235px] overflow-auto" id="scrollTab">
        <InfiniteScroll
          loader={<></>}
          scrollableTarget="scrollTab"
          hasMore={hasMore}
          className="flex flex-col gap-y-3 pl-0 pr-1"
          next={() => {
            setPaginate(paginate => ({
              ...paginate,
              numberPerPage: 20,
              isConcat: true,
              page: calculatePage(paginate.page, paginate.numberPerPage),
            }));
          }}
          key={notifications.length.toString()}
          dataLength={notifications.length}>
          {notifications.length > 0 ? (
            notifications.map(notification => {
              const avatar = getAvatar(notification);
              const wallet_address = getWalletAddress(notification);
              return (
                <div
                  onClick={() => onRead(notification._id)}
                  key={notification._id}
                  className="flex flex-row items-center justify-between gap-x-2">
                  <div className="flex flex-row items-center gap-x-1 w-[62%]">
                    <div className="w-8 gap-x-1">
                      {renderNotificationAvatar(avatar, wallet_address)}
                    </div>
                    <span
                      className={`text-[11px] overflow-hidden text-ellipsis ${
                        colorMode === 'dark' ? 'text-white' : 'text-black'
                      }`}>
                      {ReactHtmlParser(getNotificationText(notification))}
                    </span>
                  </div>
                  {renderNotificationAction(notification)}
                  {renderDotRead(notification)}
                </div>
              );
            })
          ) : (
            <Empty
              description={
                <div className={`${colorMode === 'dark' ? 'text-white' : 'text-black'}`}>
                  {$t('messages:no_data')}
                </div>
              }
            />
          )}
        </InfiniteScroll>
      </div>
    );
  },
);
