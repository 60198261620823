import { $t } from 'library/utils';

import { UserInfo } from './home';
export interface FriendRequest {
  object_address: string;
}

export interface SenderRequest {
  sender_address: string;
}

export type AcceptFriendRequest = SenderRequest;

export type UnfriendRequest = FriendRequest;

export type RejectFriendRequest = SenderRequest;

export interface BaseFriendRequest {
  address: string;
}

export type Unfriend = FriendRequest & SenderRequest;

export type FriendAcceptSocket = {
  receiver: UserInfo;
  sender: UserInfo;
};

export enum Role {
  Member = 'MEMBER',
  Admin = 'ADMIN',
  SubAdmin = 'SUB_ADMIN',
}

export const ROLES = [
  {
    label: $t('user_role:member'),
    value: 'MEMBER',
  },
  {
    label: $t('user_role:admin'),
    value: 'ADMIN',
  },
  {
    label: $t('user_role:sub_admin'),
    value: 'SUB_ADMIN',
  },
];

export type IUpdateUserInfo = {
  name: string;
  description: string;
  avatar: string;
};

export interface WelcomeMessage {
  content: string;
  images: string[];
}

export interface CreateWelcomeMessage {
  messages: string[];
  crm: boolean;
}

export interface AddOfficialAccount {
  official_account_wallet_address: string;
}

export interface UpdateUserWalletBalances {
  wallet_addresses: string[];
}

export enum ChainType {
  ALL = 'all',
  ETHEREUM = 'ethereum',
  POLYGON = 'polygon',
  BSC = 'bsc',
  ASTAR = 'astar',
  OASYS = 'oasys',
}

export interface FriendWithRoomAndKey {
  description: string;
  dmtp_pub_key: string;
  friend_status: number;
  name: string;
  room_id: string;
  wallet_address: string;
  _id: string;
  blockchain: any;
  last_login?: Date;
  updated_at: Date;
}

export interface UserStats {
  _id: string;
  wallet_address: string;
  friend_count: string;
  created_at: Date;
  updated_at?: Date;
}
