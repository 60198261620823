import { call, execFunc, showErrorApi, takeLatest } from 'common';
import { ApiConstants, NetWorkResponseType, NetWorkService } from 'library/networking';
import { Notification, NotificationWithCount } from 'model/notification';
import { Action } from 'redux';
import { notificationActions } from 'redux/action-slice';

export function* notificationSaga() {
  yield* takeLatest(notificationActions.onGetList.type, onGetList);
  yield* takeLatest(notificationActions.onRead.type, onRead);
  yield* takeLatest(notificationActions.onReadAll.type, onReadAll);
}

function* onGetList(action: Action) {
  if (notificationActions.onGetList.match(action)) {
    const { params, onSucceeded, onFailed } = action.payload;
    const response = yield* call<NetWorkResponseType<NotificationWithCount>>(NetWorkService.Get, {
      url: ApiConstants.GET_LIST_NOTIFICATION,
      params,
    });
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as NotificationWithCount);
  }
}

function* onRead(action: Action) {
  if (notificationActions.onRead.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    const response = yield* call<NetWorkResponseType<unknown>>(NetWorkService.Post, {
      url: ApiConstants.READ_NOTIFICATION,
      body,
    });
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as unknown);
  }
}

function* onReadAll(action: Action) {
  if (notificationActions.onReadAll.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    const response = yield* call<NetWorkResponseType<unknown>>(NetWorkService.Post, {
      url: ApiConstants.READ_ALL_NOTIFICATION,
    });
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as unknown);
  }
}
