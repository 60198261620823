import { Cookies } from 'react-cookie';

import { R } from 'assets/value';
import { all, call, onGetAccounts, put, takeLatest } from 'common';
import { appActions } from 'redux/action-slice';
import Web3 from 'web3';
const wmb = window as any;
wmb.web3 = new Web3(wmb.ethereum);
const cookies = new Cookies();
export function* appSaga() {
  yield* takeLatest(appActions.onLoadApp.type, onLoadAppModeAndTheme);
}
function* onLoadAppModeAndTheme() {
  const { tokenInformation, addressAccount } = yield* all({
    tokenInformation: cookies.get(R.strings.TOKEN_INFORMATION),
    addressAccount: call<any>(onGetAccounts),

    //   appTheme: call<StorageGetFunc<string>>(loadString, R.strings.APP_THEME),
    //   appLanguage: call<StorageGetFunc<string>>(
    //     loadString,
    //     R.strings.APP_LANGUAGE,
    //   ),
    //   cacheLanguages: call<StorageGetFunc<DataLanguage['content']>>(
    //     load,
    //     R.strings.CACHE_LANGUAGES,
    //   ),
    //   responseLanguages: call<NetWorkResponseType<Array<DataLanguage>>>(
    //     NetWorkService.Get,
    //     {
    //       url: ApiConstants.GET_LANGUAGES,
    //     },
    //   ),
  });
  if (tokenInformation !== null) {
    yield* put(appActions.onSetToken({ access_token: tokenInformation as string }));
  }
  if (addressAccount !== null) {
    yield* put(appActions.onSetAddress(addressAccount[0]));
  }
}
