/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-var-requires */
const abiMethods = require('./abi.js');
const logMethods = require('./logs.js');
const methodMethods = require('./methods.js');

class AbiParser {
  constructor(abiArray = []) {
    this.state = {
      savedABIs: [],
      methodIDs: {},
    };
    this.addABI(abiArray);
  }

  _typeToString(input) {
    if (input.type === 'tuple') {
      return '(' + input.components.map(this._typeToString).join(',') + ')';
    }
    return input.type;
  }

  addABI = abiMethods.addABI;
  removeABI = abiMethods.removeABI;
  getABIs = abiMethods.getABIs;
  getMethodIDs = abiMethods.getMethodIDs;

  decodeMethod = methodMethods.decodeMethod;
  decodeMethods = methodMethods.decodeMethods;

  decodeLog = logMethods.decodeLog;
  decodeLogs = logMethods.decodeLogs;
}

export function decodeLogs(logs, abi) {
  const parser = new AbiParser(abi);
  return parser.decodeLogs(logs, true).filter(log => !!log);
}
