// eslint-disable-next-line
const { createECDH } = require('crypto-browserify');

export const generateNewKeyPair = () => {
  const client = createECDH('secp256k1');
  client.generateKeys();
  return {
    privateKey: client.getPrivateKey('hex'),
    publicKey: client.getPublicKey('hex'),
  };
};

export const getSharedKey = (senderPrivateKey: string, toPublicKey: string) => {
  try {
    const fromClient = createECDH('secp256k1');
    fromClient.setPrivateKey(senderPrivateKey, 'hex');
    const sharedKey = fromClient.computeSecret(toPublicKey, 'hex', 'hex');
    return sharedKey;
  } catch (error) {
    return '';
  }
  // const alice = createECDH('secp256k1');
  // const bob = createECDH('secp256k1');
  // bob.setPrivateKey('7770957a318ed1acf321eea3ddf59be523cf9ee0513c4ad63dc6f0d54f14b34f', 'hex');
  // bob.setPublicKey(
  //   '04a79604736e2aacfed6b9ebecc4e3c1ae4f570de89385147c927da700d83971b8833b39854947d642796d4f8a0ae6e51e73f8f6b0f3d5bbfbe0f6f1a2c65df82c',
  //   'hex',
  // );
  // alice.setPrivateKey('c065935833c4011ae34354efc3dc65f2092d16f7b786e31461fe763567ad656c', 'hex');
  // alice.setPublicKey(
  //   '040ceea34873cc31cb7cb228e6a7ee67a649a79588f6c169232b2fd533a111c322708db97b6efd102d92b621a25d4d018e3ec58bee6fd546f98384c93bef84972d',
  //   'hex',
  // );
  // const alice_secret = alice.computeSecret(bob.getPublicKey(), 'hex', 'hex');
  // const bob_secret = bob.computeSecret(alice.getPublicKey(), 'hex', 'hex');
  // console.log(alice_secret === bob_secret);
  // return '';
};
