/* ROUTES COMPONENT
   ========================================================================== */

import { RouteObject } from 'react-router-dom';

import loadable from '@loadable/component';
import LayoutDefault from 'containers/layouts/default';
import LayoutDefaultV2 from 'containers/layouts/defaultV2';
import Loading from 'containers/loadable-fallback/loading';
import FriendList from 'pages/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937/analytics/friendList';
import MessageList from 'pages/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937/analytics/messageList';
import NewMessage from 'pages/e90f5a9e4de9a157cba44b0b5f0a020a6fdc33982c0766b6b5bc546ebf3a2962/';

import ROUTES from './constant';
import { ProtectedRoute } from './protected.route';

/**
 * Lazy load page components. Fallback to <Loading /> when in loading phase
 */
const Home = loadable(() => import('pages/home'), { fallback: <Loading /> });
const Partners = loadable(() => import('pages/partners'), { fallback: <Loading /> });
const Tokens = loadable(() => import('pages/tokens'), { fallback: <Loading /> });
const Community = loadable(() => import('pages/community'), { fallback: <Loading /> });
const CreateTickers = loadable(() => import('pages/create-stickers'), { fallback: <Loading /> });
const ListMarketSticker = loadable(() => import('pages/list-market-sticker'), {
  fallback: <Loading />,
});
const ListOwnerSticker = loadable(() => import('pages/list-owner-sticker'), {
  fallback: <Loading />,
});
const NFTSales = loadable(() => import('pages/nft-sale'), {
  fallback: <Loading />,
});
const CRM = loadable(
  () => import('pages/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937'),
  {
    fallback: <Loading />,
  },
);
const WelcomeMessages = loadable(
  () =>
    import(
      'pages/crm-welcome-message7300b177d466ae31945c6eada9bcd83418289e1689b052653264eb55a1da568a'
    ),
  {
    fallback: <Loading />,
  },
);
const SubAccounts = loadable(
  () =>
    import('pages/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937/sub-accounts'),
  {
    fallback: <Loading />,
  },
);
const Messages = loadable(() => import('pages/messages'), { fallback: <Loading /> });
const MessageDefault = loadable(() => import('pages/messages/message-content/default'), {
  fallback: <Loading />,
});
const Notification = loadable(() => import('pages/messages/mobile/notification-list'), {
  fallback: <Loading />,
});
const UserInfo = loadable(() => import('pages/messages/mobile/user'), {
  fallback: <Loading />,
});
const Wallet = loadable(() => import('pages/messages/mobile/wallet-list'), {
  fallback: <Loading />,
});
const ChatContent = loadable(() => import('pages/messages/message-content'), {
  fallback: <Loading />,
});
const PreRegistration = loadable(() => import('pages/pre-registration'), { fallback: <Loading /> });
const Error = loadable(() => import('pages/error'), { fallback: <Loading /> });
const Maintenance = loadable(() => import('pages/maintenance'), { fallback: <Loading /> });
const PCAvailable = loadable(() => import('pages/error/available'), { fallback: <Loading /> });
const SnsPage = loadable(() => import('pages/sns'), { fallback: <Loading /> });
const PrivacyPolicies = loadable(() => import('pages/privacy-policies'), { fallback: <Loading /> });
const Tos = loadable(() => import('pages/tos'), { fallback: <Loading /> });

const KeysHome = loadable(() => import('pages/keys'), { fallback: <Loading /> });
const KeysPage = loadable(() => import('pages/keys/keys'), { fallback: <Loading /> });
const KeysExplorePage = loadable(() => import('pages/keys/explore'), { fallback: <Loading /> });
const KeysMyPage = loadable(() => import('pages/keys/my-page'), { fallback: <Loading /> });
const KeysPoints = loadable(() => import('pages/keys/points'), { fallback: <Loading /> });
const KeysProfile = loadable(() => import('pages/keys/profile'), { fallback: <Loading /> });

const SignupCrm = loadable(() => import('pages/signup-crm'), { fallback: <Loading /> });

/**
 * Use <AuthRoute /> to protect authenticate pages
 */
export const blockAccessRoutes: RouteObject[] = [
  {
    path: ROUTES.signupCrmPage,
    element: <SignupCrm />,
  },
  {
    path: ROUTES.home,
    element: <LayoutDefaultV2 />, // TODO homepage v2
    children: [
      { path: ROUTES.home, element: <Home /> },
      { index: true, element: <Home /> },
      { path: ROUTES.linkTelegram, element: <Home /> },
      { path: ROUTES.partners, element: <Partners /> },
      { path: ROUTES.tokens, element: <Tokens /> },
    ],
  },
  { path: ROUTES.preRegistration, element: <PreRegistration /> },
  { path: ROUTES.notfound, element: <Error /> },
  {
    path: ROUTES.available,
    element: <PCAvailable />,
  },
  { path: ROUTES.discordConnect, element: <SnsPage /> },
  { path: ROUTES.privacyPolicies, element: <PrivacyPolicies /> },
  { path: ROUTES.tos, element: <Tos /> },
];

export const fullAccessRoutes: RouteObject[] = [
  {
    path: ROUTES.signupCrmPage,
    element: <SignupCrm />,
  },
  {
    path: ROUTES.home,
    element: <LayoutDefaultV2 />, // TODO homepage v2
    children: [
      { path: ROUTES.home, element: <Home /> },
      { index: true, element: <Home /> },
      { path: ROUTES.linkTelegram, element: <Home /> },
      { path: ROUTES.partners, element: <Partners /> },
      { path: ROUTES.tokens, element: <Tokens /> },
    ],
  },
  {
    path: ROUTES.home,
    element: <LayoutDefault />,
    children: [
      {
        path: ROUTES.createStickers,
        element: (
          <ProtectedRoute redirectPath={ROUTES.home}>
            <CreateTickers />
          </ProtectedRoute>
        ),
      },
      { path: ROUTES.listMarketStickers, element: <ListMarketSticker /> },
      {
        path: ROUTES.listOwnerStickers,
        element: (
          <ProtectedRoute redirectPath={ROUTES.home}>
            <ListOwnerSticker />
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.nftSale,
        element: (
          <ProtectedRoute redirectPath={ROUTES.home}>
            <NFTSales />
          </ProtectedRoute>
        ),
      },
      // { path: ROUTES.home, element: <Home /> },
      // { index: true, element: <Home /> },
      // { path: ROUTES.linkTelegram, element: <Home /> },
    ],
  },

  {
    path: ROUTES.newMessage,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <NewMessage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.welcomeMessage,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <WelcomeMessages />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.crm,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <CRM />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.subAccounts,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <SubAccounts />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.messageList,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <MessageList />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.friendList,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home} role={'admin'}>
        <FriendList />
      </ProtectedRoute>
    ),
  },
  { path: ROUTES.preRegistration, element: <PreRegistration /> },
  { path: ROUTES.wallet, element: <Wallet /> },
  { path: ROUTES.notfound, element: <Error /> },
  {
    path: ROUTES.messages,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home}>
        <Messages />
      </ProtectedRoute>
    ),
    children: [
      { path: ROUTES.chatThread, element: <ChatContent /> },
      { path: '', element: <MessageDefault /> },
      { path: ROUTES.notification, element: <Notification /> },
      { path: ROUTES.user, element: <UserInfo /> },
      { path: ROUTES.community, element: <Community /> },
    ],
  },
  {
    path: ROUTES.marketplace,
    element: (
      <ProtectedRoute redirectPath={ROUTES.home}>
        <KeysHome />
      </ProtectedRoute>
    ),
    children: [
      { path: ROUTES.keys, element: <KeysPage /> },
      { path: ROUTES.keysExplore, element: <KeysExplorePage /> },
      { path: ROUTES.keysMyPage, element: <KeysMyPage /> },
      { path: ROUTES.keysPoints, element: <KeysPoints /> },
      { path: `${ROUTES.keysKOLProfile}/:id`, element: <KeysProfile /> },
    ],
  },
  {
    path: ROUTES.available,
    element: <PCAvailable />,
  },
  { path: ROUTES.discordConnect, element: <SnsPage /> },
  { path: ROUTES.privacyPolicies, element: <PrivacyPolicies /> },
  { path: ROUTES.tos, element: <Tos /> },
];

export const routesMode: RouteObject[] = [{ path: ROUTES.maintenance, element: <Maintenance /> }];
