import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-spring-bottom-sheet/dist/style.css';

import { ColorModeScript } from '@chakra-ui/react';
import theme from 'theme';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';
import './styles/globals-custom.scss';
ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.unregister();

// serviceWorkerRegistration.register({
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   onUpdate: (registration: any) => {
//     // alert('New version available!  Ready to update?');
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//     window.location.reload();
//   },
// });
