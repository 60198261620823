import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const baseStyle = definePartsStyle({
  tab: {
    fontWeight: 'semibold',
  },
});

const sizes = {
  xl: definePartsStyle({
    tab: {
      fontSize: 'xl',
      py: '4',
      px: '6',
    },
    tabpanel: {
      py: '4',
      px: '6',
    },
  }),
};

// define custom variants
const colorfulVariant = definePartsStyle(props => {
  const { colorScheme: c } = props;

  return {
    tab: {
      bgGradient: 'linear(to-l, #7c4998, #5497cc)',
      borderTopRadius: 'lg',
      borderBottom: 'none',
      color: 'white',
      _selected: {
        bgGradient: 'linear(to-l, #7c4998, #5497cc)',
        bgClip: 'text',
        border: '3px solid',
        borderColor: 'inherit',
        borderBottom: 'none',
        mb: '-2px',
      },
    },
    tablist: {
      borderBottom: '2x solid',
      borderColor: 'inherit',
    },
    tabpanel: {
      border: '2px solid',
      borderColor: 'inherit',
      borderBottomRadius: 'lg',
      borderTopRightRadius: 'lg',
    },
  };
});

const variants = {
  colorful: colorfulVariant,
};

const defaultProps = {
  size: 'xl' as const,
  variant: 'colorful' as const,
  colorScheme: 'green',
};

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps,
});
