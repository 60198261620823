import { Cookies } from 'react-cookie';

import { R } from 'assets/value';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { dispatch, getState } from 'common';
import {
  ADDRESS_KEY_HEADER,
  CONTENT_TYPE,
  getBearerToken,
  RESULT_CODE_PUSH_OUT,
  TIME_OUT,
  TOKEN_KEY_HEADER,
} from 'config/api';
import { ENVConfig } from 'config/env';
import { ParamsNetwork, ResponseBase } from 'config/type';
import { AppState } from 'model/app';
import { appActions } from 'redux/action-slice';

import { ApiConstants } from './api';
import { handleErrorAxios, handleParameter, handleResponseAxios } from './helper';

let refreshTokenRequest: Promise<string | null> | null = null;
const AxiosInstance = Axios.create({});
const cookies = new Cookies();
AxiosInstance.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      refreshTokenRequest = refreshTokenRequest
        ? refreshTokenRequest
        : refreshToken(originalRequest);
      const newToken = await refreshTokenRequest;
      refreshTokenRequest = null;
      if (newToken === null) {
        return Promise.reject(error);
      }
      // dispatch(onSetToken(newToken));
      originalRequest.headers[TOKEN_KEY_HEADER] = newToken;
      return AxiosInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

// refresh token
async function refreshToken(originalRequest: any) {
  return AxiosInstance.get(ApiConstants.REFRESH_TOKEN, originalRequest)
    .then((res: AxiosResponse) => res.data)
    .catch(() => null);
}

// base
function Request<T = unknown>(config: AxiosRequestConfig, isCheckOut = true) {
  // const token = window.sessionStorage.getItem('refresh-token');
  // const token = useSelector(x => x.app.token);
  const token = cookies.get(R.strings.TOKEN_INFORMATION);
  const { registrationAddress, addressAccount, chatSelectedAccount } = getState('app');
  // console.log('token --------------', token);

  const defaultConfig: AxiosRequestConfig = {
    baseURL: ENVConfig.API_URL,
    timeout: TIME_OUT,
    headers: {
      'Content-Type': CONTENT_TYPE,
      [TOKEN_KEY_HEADER]: token || config.headers?.[TOKEN_KEY_HEADER] || '',
      [ADDRESS_KEY_HEADER]:
        registrationAddress ||
        addressAccount ||
        chatSelectedAccount ||
        config.headers?.[ADDRESS_KEY_HEADER] ||
        '',
    },
  };

  return new Promise<ResponseBase<T> | null>(rs => {
    AxiosInstance.request({ ...defaultConfig, ...config })
      .then((res: AxiosResponse<T>) => {
        const result = handleResponseAxios(res);
        rs(result);
      })
      .catch((error: AxiosError) => {
        console.log({ error });
        const result = handleErrorAxios(error);
        if (result.code === RESULT_CODE_PUSH_OUT) {
          dispatch(appActions.onShowSessionExpired(true));
        }
        if (!isCheckOut) {
          rs(result);
        }
        if (result.code === RESULT_CODE_PUSH_OUT && isCheckOut) {
          rs(null);
        } else {
          rs(result);
        }
      });
  });
}

// get
async function Get<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'GET'));
}

// post
async function Post<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'POST'));
}
// patch
async function Patch<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'PATCH'));
}

type ParameterPostFormData = AxiosRequestConfig & ParamsNetwork;
// post FormData
async function PostFormData<T>(params: ParamsNetwork) {
  const { token, addressAccount, chatSelectedAccount }: AppState = getState('app');
  const headers: AxiosRequestConfig['headers'] = {
    [TOKEN_KEY_HEADER]: token ?? '',
    'Content-Type': 'multipart/form-data',
    [ADDRESS_KEY_HEADER]:
      addressAccount || chatSelectedAccount || params.headers?.[ADDRESS_KEY_HEADER] || '',
  };
  return Request<T>(handleParameter<ParameterPostFormData>({ ...params, headers }, 'POST'));
}

// put
async function Put<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'PUT'));
}

// delete
async function Delete<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'DELETE'));
}

export type NetWorkResponseType<T> = (params: ParamsNetwork) => Promise<ResponseBase<T> | null>;

export const NetWorkService = {
  Get,
  Put,
  Post,
  Patch,
  Delete,
  Request,
  PostFormData,
};
