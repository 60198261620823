import { call, execFunc, put, showErrorApi, takeLatest } from 'common';
import { ApiConstants, NetWorkResponseType, NetWorkService } from 'library/networking';
import { CrmMessages } from 'model/messages';
import { UserStats } from 'model/user.interface';
import { Action } from 'redux';
import { appActions } from 'redux/action-slice';

import { crmActions } from '../action-slice/crm';

export function* crmSaga() {
  yield* takeLatest(crmActions.onGetCrmMessages.type, onGetCrmMessages);
  yield* takeLatest(crmActions.onCreateCrmMessages.type, onCreateCrmMessages);
  yield* takeLatest(crmActions.onUpdateCrmMessageRead.type, onUpdateCrmMessageRead);
  yield* takeLatest(crmActions.onGetTransitionOfFriends.type, onGetTransitionOfFriends);
}

function* onGetCrmMessages(action: Action) {
  if (crmActions.onGetCrmMessages.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield put(appActions.onStartProcess());

    const response = yield* call<NetWorkResponseType<Array<CrmMessages>>>(NetWorkService.Get, {
      url: ApiConstants.GET_CRM_MESSAGES,
    });

    if (!response || !response.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? '');
      return;
    }
    console.log('API Response:', response);

    execFunc(onSucceeded, response.data as Array<CrmMessages>);
  } else {
    console.log('error');
  }
}

function* onCreateCrmMessages(action: Action) {
  if (crmActions.onCreateCrmMessages.match(action)) {
    const { onSucceeded, onFailed, body } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<CrmMessages>>(NetWorkService.Post, {
      url: ApiConstants.CREATE_CRM_MESSAGES,
      body,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as CrmMessages);
  }
}

function* onUpdateCrmMessageRead(action: Action) {
  if (crmActions.onUpdateCrmMessageRead.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield* put(appActions.onStartProcess());
    const response = yield* call<NetWorkResponseType<CrmMessages>>(NetWorkService.Post, {
      url: ApiConstants.UPDATE_CRM_MESSAGE_READ,
    });
    yield* put(appActions.onEndProcess());
    if (!response) {
      return;
    }
    if (!response?.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? response?.errorTx ?? '');
      return;
    }
    execFunc(onSucceeded, response.data as CrmMessages);
  }
}

function* onGetTransitionOfFriends(action: Action) {
  if (crmActions.onGetTransitionOfFriends.match(action)) {
    const { onSucceeded, onFailed } = action.payload;
    yield put(appActions.onStartProcess());

    const response = yield* call<NetWorkResponseType<Array<UserStats>>>(NetWorkService.Get, {
      url: ApiConstants.GET_TRANSITION_OF_FRIENDS,
    });

    if (!response || !response.status) {
      onFailed?.(response as any);
      execFunc(showErrorApi, response?.errorTx ?? '');
      return;
    }
    console.log('API Response:', response);

    execFunc(onSucceeded, response.data as Array<UserStats>);
  } else {
    console.log('error');
  }
}
