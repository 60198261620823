/* LAYOUT NAVBAR COMPONENT STYLES
   ========================================================================== */

import styled from 'styled-components';
import { ColorDefault } from 'theme/color';

const Styled = {
  Container: styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    padding: 0 20px;
  `,
  Left: styled.div`
    display: flex;
    align-items: center;
  `,
  Right: styled.div`
    display: flex;
    // align-items: center;
  `,
  ThemeSwitcher: styled.div`
    margin-left: 20px;
    padding: 10px 12px;
    border-radius: 50%;
    background-color: #fff;
  `,
  NFT: styled.div`
    margin-left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  Avatar: styled.div``,
  BadgeStatus: styled.div`
    min-width: 17px;
    min-height: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: ${ColorDefault.danger};
    border-radius: 24px;
    padding: 1px 3px 0px;
    transform: translate(0%, 0%);

    &.ant-scroll-number-custom-component {
      transform: translate(0%, 0%);
    }
  `,
  BadgeStatusText: styled.span`
    font-family: 'Roboto';
    font-weight: 700;
    line-height: 12px;
    color: ${ColorDefault.generalG8};
    font-size: 10px;
  `,
};

export default Styled;
