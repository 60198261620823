import React from 'react';

import { useColorMode } from '@chakra-ui/react';
import { ConfigProvider, theme } from 'antd';

interface AntdConfigProps {
  children: React.ReactNode;
}

export const AntdConfigProvider = ({ children }: AntdConfigProps) => {
  const { darkAlgorithm, defaultAlgorithm } = theme;
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <ConfigProvider
      theme={{
        algorithm: isDark ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorBgLayout: isDark ? '#001529' : '#fff',
          colorText: isDark ? '#fff' : '#262626',
          colorPrimary: '#6559f5',
          colorLink: '#6559f5',
          fontFamily: 'Segoe UI',
          fontSize: 16,
        },
        components: {
          Tag: {
            fontSizeSM: 14,
          },
          Tabs: {
            fontSize: 16,
          },
          Table: {
            colorText: isDark ? '#fff' : '#595959',
          },
          Pagination: {
            borderRadius: 2,
            fontSize: 14,
            controlHeight: 32,
            colorText: isDark ? '#fff' : '#262626',
            colorTextDisabled: '#BFBFBF',
            colorBgContainerDisabled: '#6559F5',
            colorFillAlter: '#6559F5',
          },
          Menu: {
            radiusItem: 0,
            itemMarginInline: 0,
            colorItemTextSelected: '#6559f5',
          },
          // Modal: {
          //   contentBg: isDark ? '#001529' : '#fff',
          //   headerBg: isDark ? '#001529' : '#fff',
          //   footerBg: isDark ? '#001529' : '#fff',
          // },
        },
      }}>
      <div className={isDark ? 'dark' : 'light'}>{children}</div>
    </ConfigProvider>
  );
};
