import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CheckIcon, ChevronDownIcon, CloseIcon, EmailIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
/* eslint-disable import/order */
import { dispatch, MAX_ACCEPT_FILE_SIZE, useSelector } from 'common';
/* eslint-enable import/order */
import { encryptMessage, resizeImageAsync } from 'common/utils/core';
import { TOKEN_KEY_HEADER } from 'config/api';
import { MSG_CRM_LENGTH, MSG_CRM_SHOW_ERROR_LENGTH } from 'config/field-length';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t, translate } from 'library/utils';
import { flatten, isEqual, filter as uniqBy } from 'lodash';
import {
  Conditiontypes,
  Content,
  CrmConditions,
  CrmMessages,
  ErrorType,
  MessageRoom,
} from 'model/messages';
import WithSubnavigation from 'pages/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937/navbar';
import { MdAttachFile } from 'react-icons/md';
import { crmActions, roomActions, userActions } from 'redux/action-slice';
import ROUTES from 'routes/constant';
import { getSharedKey } from 'services/crypto.service';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import {
  DefaultLeaf,
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { useAccount } from 'wagmi';

import { chainIdToChainName } from '../../common/utils/web3';
import {
  clearEditor,
  decorator,
  imageSrcRegex,
  removeImageTag,
} from '../messages/message-content/editor';
import { replaceBrAtEndOfString, serialize } from '../messages/message-content/handler';
import { insertImage, withImages } from '../messages/message-content/images';

type CustomUploadProps = UploadProps & {
  onSuccess: (result: { url: string; file: File }) => void;
};

interface FriendWithRoomAndKey {
  description: string;
  dmtp_pub_key: string;
  friend_status: number;
  name: string;
  room_id: string;
  wallet_address: string;
  _id: string;
  blockchain: any;
}

interface FriendsWithRoomAndSharedKey extends FriendWithRoomAndKey {
  sharedKey: string;
}

const NewMessage = () => {
  const INIT_EDITOR_CONTENT: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }];

  const [fileList, setFileList] = useState<File[]>([]);
  const { address } = useAccount();
  const [friends, setFriends] = useState<FriendWithRoomAndKey[]>([]);
  const [filteredFriends, setFilteredFriends] = useState<string[]>([]);
  const [destination, setDestination] = useState('all');
  const [messageTag, setMessageTag] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [editor] = useState(() => withImages(withReact(withHistory(createEditor()))));
  const [messages, setMessages] = useState<Array<Descendant[]>>([INIT_EDITOR_CONTENT]);
  const [friendsWithKeys, setFriendsWithKeys] = useState<FriendsWithRoomAndSharedKey[]>([]);
  const [conditions, setConditions] = useState<CrmConditions[]>([
    { type: Conditiontypes.ALL, value: '', chain: 'all', range: 0 },
  ]);
  const editors = useMemo(() => messages.map(() => withReact(createEditor())), [messages.length]);
  const navigate = useNavigate();
  const location = useLocation();
  const { privateKeys, token } = useSelector(x => x.app);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeModal, setActiveModal] = useState<number>(1);
  const [errorType, setErrorType] = useState<ErrorType>(ErrorType.MESSAGE_TAG);
  const [isValidateError, setIsValidateError] = useState<boolean>(false);
  const [indexError, setIndexError] = useState<number[]>([]);

  const onGetUserFriendsSuccess = useCallback((data: any) => {
    console.log(data, 'data=>onGetUserFriendsSuccess');
    setFriends(data);
  }, []);
  useEffect(() => {
    if (address) {
      dispatch(userActions.onGetFriends({ crm: true }, data => onGetUserFriendsSuccess(data)));
    }
  }, [address, onGetUserFriendsSuccess, location.pathname]);

  useEffect(() => {
    if (friends.length > 0) {
      if (!address) return;
      const walletPrivateKey = privateKeys.find(p =>
        isEqual(p.wallet_address, address.toLowerCase()),
      );
      if (!walletPrivateKey) {
        return;
      }
      const data: FriendsWithRoomAndSharedKey[] = [];
      friends.forEach(friend => {
        const sharedKey = getSharedKey(walletPrivateKey.privateKey, friend.dmtp_pub_key);
        data.push({ ...friend, sharedKey });
      });
      setFriendsWithKeys(data);
    }
  }, [friends, address, privateKeys]);

  const handleMessageChange = (index: number, newValue: Descendant[]) => {
    const newMessages = [...messages];
    newMessages[index] = newValue;
    setMessages(newMessages);
  };

  const validateMessage = () => {
    setIsValidateError(false);
    setIndexError([]);
    if (!messageTag) {
      setErrorType(ErrorType.MESSAGE_TAG);
      setIsValidateError(true);
      return;
    }
    const texts = messages.map(m => {
      const message = serializeNode(m);
      return replaceBrAtEndOfString(message);
    });
    const tmp_arr: number[] = [];
    for (let i = 0; i < texts.length; i++) {
      if (!texts[i]) {
        tmp_arr.push(i);
      }
    }
    if (tmp_arr?.length) {
      setErrorType(ErrorType.MESSAGE);
      setIsValidateError(true);
      setIndexError(tmp_arr);
      return;
    }

    for (let i = 0; i < texts.length; i++) {
      const text = texts[i];
      if (text && text.length > MSG_CRM_LENGTH) {
        tmp_arr.push(i);
      }
    }
    if (tmp_arr?.length) {
      setErrorType(ErrorType.MESSAGE_LONG);
      setIsValidateError(true);
      setIndexError(tmp_arr);
      return;
    }

    if (destination != 'all' && filteredFriends.length == 0) {
      setErrorType(ErrorType.DESTINATION);
      setIsValidateError(true);
      return;
    }
    onOpen();
  };

  const handleSendMessage = async () => {
    setActiveModal(2);
    try {
      if (destination === 'all') {
        onSendToAllFriends();
      } else {
        onSendToCheckedAddress();
      }
    } catch (error) {
      setActiveModal(4);
    }
  };

  const getUploadProps = (message: Descendant[], index: number): CustomUploadProps => ({
    name: 'imageFiles',
    fileList: [],
    headers: {
      [TOKEN_KEY_HEADER]: token ?? '',
      address: '',
    },
    showUploadList: false,
    multiple: true,
    onSuccess: res => {
      const editorInstance = editors[index];
      insertImage(editorInstance, res.url, res.file.name);
      const newValue = editorInstance.children;
      const newMessages = [...messages];
      newMessages[index] = newValue;
      setMessages(newMessages);
      setFileList((fileList: any) => uniqBy(fileList.concat(res.file), f => f.name));
    },
    customRequest: async ({ onSuccess, file }) => {
      const formData = new FormData();
      let fileHandle = file as File;
      if (fileHandle.size > MAX_ACCEPT_FILE_SIZE) {
        fileHandle = await resizeImageAsync(fileHandle);
      }
      formData.append('imageFiles', fileHandle);
      const response = await NetWorkService.PostFormData<string[]>({
        url: ApiConstants.UPLOAD_IMAGES,
        body: formData,
      });

      if (response?.data) {
        onSuccess?.({
          url: response.data[0],
          file,
        });
      }
    },
    accept: 'image/*',
    beforeUpload: file => {
      const serializedMessage = serializeNode(message);
      return !serializedMessage.includes(file.name);
    },
  });

  const handleMessageTagChange = (e: any) => {
    const inputValue = e.target.value;
    setMessageTag(inputValue);
  };

  const addMessage = () => {
    setMessages([...messages, INIT_EDITOR_CONTENT]);
  };

  const removeMessage = (index: number) => {
    if (messages.length > 1) {
      const newMessages = [...messages];
      newMessages.splice(index, 1);
      setMessages(newMessages);
    } else {
      setMessages([INIT_EDITOR_CONTENT]);
    }
  };
  const filterUsersByGrossValue = useCallback(
    (friends: FriendWithRoomAndKey[], value: string, chain: string) => {
      if (!friends || !value || !chain) {
        alert(translate('app:conditions_alert').toString());
        return;
      }
      setIsNotFound(false);
      let filtered: string[] = [];
      switch (chain) {
        case 'all':
          filtered = friends
            .filter(
              user =>
                user.blockchain.ethereum.transactions.gross +
                  user.blockchain.polygon.transactions.gross >=
                Number(value),
            )
            .map(user => user.wallet_address);
          break;
        case '0x1':
          filtered = friends
            .filter(user => user.blockchain.ethereum.transactions.gross >= Number(value))
            .map(user => user.wallet_address);
          break;
        case '0x89':
          filtered = friends
            .filter(user => user.blockchain.polygon.transactions.gross >= Number(value))
            .map(user => user.wallet_address);
      }
      if (filtered?.length == 0) {
        console.log('hey');
        setIsFiltering(false);
        setIsNotFound(true);
        if (filteredFriends) setFilteredFriends([]);
        return;
      }
      setFilteredFriends(filtered);
      setIsFiltering(false);
    },
    [filteredFriends],
  );

  const filterUsersByBalance = useCallback(
    (friends: FriendWithRoomAndKey[], value: string, chain: string) => {
      if (!friends || !value || !chain) {
        alert(translate('app:conditions_alert').toString());
        return;
      }
      setIsNotFound(false);
      let filtered: string[] = [];
      switch (chain) {
        case 'all':
          filtered = friends
            .filter(user => user.blockchain.values.value >= Number(value))
            .map(user => user.wallet_address);
          break;
        case '0x1':
          filtered = friends
            .filter(user => user.blockchain.ethereum.values >= Number(value))
            .map(user => user.wallet_address);
          break;
        case '0x89':
          filtered = friends
            .filter(user => user.blockchain.polygon.values >= Number(value))
            .map(user => user.wallet_address);
      }
      if (filtered?.length == 0) {
        console.log('hey');
        setIsFiltering(false);
        setIsNotFound(true);
        if (filteredFriends) setFilteredFriends([]);
        return;
      }
      setFilteredFriends(filtered);
      setIsFiltering(false);
    },
    [filteredFriends],
  );

  const filterUsesByNFT = useCallback(
    async (friends: FriendWithRoomAndKey[], value: string, chain: string) => {
      if (!friends || !value || !chain) {
        alert(translate('app:conditions_alert').toString());
        return;
      }
      setIsNotFound(true);
      const response = await NetWorkService.Get<string[]>({
        url: ApiConstants.GET_NFT_OWNERS,
        params: {
          contractAddress: value,
          chainId: chain,
        },
      });
      if (!response?.data) {
        setIsFiltering(false);
        setIsNotFound(true);
        if (filteredFriends) setFilteredFriends([]);
        return;
      }
      const owners = response.data;
      const filtered = friends
        .filter(friend => owners.includes(friend.wallet_address))
        .map(friend => friend.wallet_address);
      setFilteredFriends(filtered);
      setIsFiltering(false);
    },
    [filteredFriends],
  );
  const filterFriends = useCallback(() => {
    if (!conditions[0].value || !conditions[0].chain) {
      alert(translate('app:conditions_alert').toString());
      return;
    }
    setIsFiltering(true);
    console.log('========>conditions', conditions);
    switch (conditions[0].type) {
      case Conditiontypes.NFT:
        filterUsesByNFT(friends, conditions[0].value, conditions[0].chain);
        break;
      case Conditiontypes.TRANSACTION:
        filterUsersByGrossValue(friends, conditions[0].value, conditions[0].chain);
        break;
      case Conditiontypes.ASSET:
        filterUsersByBalance(friends, conditions[0].value, conditions[0].chain);
        break;
      default:
        alert(translate('app:conditions_alert').toString());
        setIsFiltering(false);
        return;
    }
  }, [conditions, filterUsersByBalance, filterUsersByGrossValue, filterUsesByNFT, friends]);

  const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const { files } = event.clipboardData;

    const newFiles: File[] = [];

    if (files && files.length > 0) {
      for (const file of files) {
        newFiles.push(file);
      }

      setFileList((files: any) => uniqBy(files.concat(newFiles), f => f.name));
    }
  };

  const renderElement = (props: RenderElementProps) => {
    switch (props.element.type) {
      case 'image':
        return props.element.url.length > 0 ? (
          <div {...props.attributes}>
            <div>
              <div>
                <img
                  width={300}
                  height={300}
                  {...props.attributes}
                  src={(props.element as any).url}
                  alt={props.element.name}
                  style={{ height: 100, objectFit: 'contain' }}
                />
              </div>
              {props.children}
            </div>
          </div>
        ) : (
          <></>
        );
      default:
        return (
          <div className="break-all" {...props.attributes}>
            {props.children}
          </div>
        );
    }
  };

  const hasImageElement = (nodes: Descendant[]): boolean => {
    return nodes.some(node => (node as any).type === 'image');
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    console.log(event);
    return false;
  };

  const serializeNode = (nodes: Descendant[]) => {
    return nodes.map(n => serialize(n)).join('');
  };

  const renderLeaf = (props: RenderLeafProps) => {
    const { leaf, children, attributes } = props;
    let childrenLeaf = children;
    if (leaf.decoration === 'link') {
      childrenLeaf = (
        <a
          {...attributes}
          className="text-blue-400 cursor-pointer"
          href={leaf.text}
          onClick={() => {
            window.open(leaf.text, '_blank', 'noopener,noreferrer');
          }}>
          {children}
        </a>
      );
    }
    return <DefaultLeaf {...props}>{childrenLeaf}</DefaultLeaf>;
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    ReactEditor.focus(editor);
    for (const file of event.dataTransfer.files) {
      setFileList((fileList: any) => uniqBy(fileList.concat(file), f => f.name));
    }
    return false;
  };

  const resetConditions = () => {
    setConditions([{ type: Conditiontypes.ALL, value: '', chain: 'all', range: 0 }]);
  };

  const onSendToAllFriends = async () => {
    messages.forEach((value, msgIndex) => {
      let message = serializeNode(value);
      message = replaceBrAtEndOfString(message);
      let urls: string[] = [];

      const associatedFile = fileList[msgIndex];
      if (associatedFile) {
        const srcs = message.match(imageSrcRegex) || [];
        const regexSrc = /"(.*?)"/gim;

        const maps = srcs.map(str => str.match(regexSrc));

        urls = (flatten(maps) as string[]).map(url => url.replace(/"/g, ''));
      }
      message = removeImageTag(message);
      if (friendsWithKeys.length === 0) {
        return;
      }

      const roomIds = friendsWithKeys.map(friend => friend.room_id);
      const messageDatas = friendsWithKeys.map(friend =>
        encryptMessage(
          {
            content: message,
            images: urls,
          },
          friend.sharedKey,
        ),
      );
      const batchMessageSend = {
        is_forwarded: false,
        message_id_reply: '',
        room_ids: roomIds,
        message_datas: messageDatas,
      };

      dispatch(
        roomActions.onSendBatchMessage(
          { ...batchMessageSend, is_promotion: false, crm: true },
          onSendBatchSuccess,
          onSendFailed,
        ),
      );
    });
  };

  const onSendToCheckedAddress = async () => {
    messages.forEach((value, msgIndex) => {
      let message = serializeNode(value);
      message = replaceBrAtEndOfString(message);
      let urls: string[] = [];

      const associatedFile = fileList[msgIndex];
      if (associatedFile) {
        const srcs = message.match(imageSrcRegex) || [];
        const regexSrc = /"(.*?)"/gim;

        const maps = srcs.map(str => str.match(regexSrc));

        urls = (flatten(maps) as string[]).map(url => url.replace(/"/g, ''));
      }

      if (filteredFriends.length == 0) {
        console.log('return');
        return;
      }
      if (friendsWithKeys.length == 0) {
        console.log('return');
        return;
      }

      const selectedFriends: FriendsWithRoomAndSharedKey[] = friendsWithKeys.filter(friend =>
        filteredFriends.includes(friend.wallet_address),
      );

      message = removeImageTag(message);

      const roomIds = selectedFriends.map(friend => friend.room_id);
      const messageDatas = selectedFriends.map(friend =>
        encryptMessage(
          {
            content: message,
            images: urls,
          },
          friend.sharedKey,
        ),
      );
      const batchMessageSend = {
        is_forwarded: false,
        message_id_reply: '',
        room_ids: roomIds,
        message_datas: messageDatas,
      };
      dispatch(
        roomActions.onSendBatchMessage(
          { ...batchMessageSend, is_promotion: false, crm: true },
          onSendBatchSuccess,
          onSendFailed,
        ),
      );
    });
  };

  const onSendFailed = (error: unknown) => {
    setActiveModal(4);
    console.log(error);
  };

  const onSendBatchSuccess = (data: MessageRoom[]) => {
    const messageIds = data.map(d => d._id);
    const isFiltered = destination != 'all';
    setActiveModal(3);
    onCreateCRMMessage(isFiltered, messageIds);
    return data;
  };

  const onCreateSuccess = (data: CrmMessages) => {
    try {
      resetConditions;
      setFileList([]);
      clearEditor(editor);
      setMessages([INIT_EDITOR_CONTENT]);
    } catch (error) {
      console.log(error, 'error=>onCreateSuccess');
    }
  };

  const onCreateFailed = (error: unknown) => {
    console.log(error);
  };

  const onCreateCRMMessage = async (isFiltered: boolean, messageIds: string[]) => {
    const contents = messages.map((value, msgIndex): Content => {
      let message = serializeNode(value);
      message = replaceBrAtEndOfString(message);

      let urls: string[] = [];
      const associatedFile = fileList[msgIndex];
      if (associatedFile) {
        const srcs = message.match(imageSrcRegex) || [];
        const regexSrc = /"(.*?)"/gim;

        const maps = srcs.map(str => str.match(regexSrc));

        urls = (flatten(maps) as string[]).map(url => url.replace(/"/g, ''));
      }

      message = removeImageTag(message);
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const text = doc.body.textContent || '';

      return {
        text,
        images: urls,
      };
    });
    let addressList;
    if (isFiltered) {
      if (filteredFriends.length === 0) {
        return;
      }
      addressList = filteredFriends;
    } else {
      if (friendsWithKeys.length === 0) {
        return;
      }
      const mockAddressList: any[] = [];
      friendsWithKeys.forEach(friend => {
        mockAddressList.push(friend.wallet_address);
      });
      if (mockAddressList.length === 0) {
        return;
      }
      addressList = mockAddressList;
    }
    if (!addressList) {
      return;
    }
    resetConditions();
    const createCRMMessage = {
      tag: messageTag,
      content: contents,
      conditions: conditions,
      destination_address_list: addressList,
      message_ids: messageIds,
    };
    dispatch(
      crmActions.onCreateCrmMessages({ ...createCRMMessage }, onCreateSuccess, onCreateFailed),
    );
  };

  const convertErrorMessage = () => {
    switch (errorType) {
      case ErrorType.MESSAGE_TAG:
        return translate('app:message_tag_alert').toString();
      case ErrorType.MESSAGE:
        return translate('app:message_alert').toString();
      case ErrorType.DESTINATION:
        return translate('app:destination_alert').toString();
      case ErrorType.MESSAGE_LONG:
        return translate('app:message_too_long_alert', {
          number: MSG_CRM_SHOW_ERROR_LENGTH,
        }).toString();
    }
  };

  const ConditionBox: React.FC<{
    condition: CrmConditions;
    onRemove?: () => void;
    onChange: (condition: CrmConditions) => void;
  }> = ({ condition, onRemove, onChange }) => {
    const [inputValue, setInputValue] = useState(condition.value);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleBlur = () => {
      onChange({ ...condition, value: inputValue });
    };

    const handleChainChange = (condition: any, newChain: string) => {
      onChange({ ...condition, chain: newChain });
    };

    const handleConditionChange = (condition: any, conditionType: Conditiontypes) => {
      onChange({ ...condition, type: conditionType });
    };

    const convertConditionTypes = (conditionType: Conditiontypes) => {
      switch (conditionType) {
        case Conditiontypes.ASSET:
          return translate('app:total_assets').toString();
        case Conditiontypes.FT:
          return translate('app:possess_ft').toString();
        case Conditiontypes.NFT:
          return translate('app:possess_nft').toString();
        case Conditiontypes.TRANSACTION:
          return translate('app:total_tx_count').toString();
      }
      return '';
    };

    const selectedBgColor = 'white';

    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={5}
        position="relative"
        my="4"
        mx="2"
        backgroundColor="white"
        boxShadow="sm">
        {onRemove && (
          <IconButton
            aria-label="Remove condition"
            icon={<CloseIcon />}
            position="absolute"
            top="5px"
            right="5px"
            color="gray.500"
            _hover={{ color: 'red.500' }}
            onClick={onRemove}
          />
        )}
        <Text mb={4} fontSize="lg" fontWeight="semibold">
          {translate('app:conditions').toString()}
        </Text>
        <Flex align="center">
          <Menu>
            <MenuButton
              borderWidth="1px"
              rightIcon={<ChevronDownIcon />}
              as={Button}
              w="200px"
              backgroundColor="white"
              boxShadow="sm"
              borderRadius="sm"
              m={2}>
              {condition.type === Conditiontypes.ALL
                ? translate('app:select_conditions').toString()
                : convertConditionTypes(condition.type)}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleConditionChange(condition, Conditiontypes.NFT)}>
                {translate('app:possess_nft').toString()}
              </MenuItem>
              <MenuItem
                onClick={() => handleConditionChange(condition, Conditiontypes.TRANSACTION)}>
                {translate('app:total_tx_count').toString()}
              </MenuItem>
              <MenuItem onClick={() => handleConditionChange(condition, Conditiontypes.ASSET)}>
                {translate('app:total_assets').toString()}
              </MenuItem>
              <MenuItem
                onClick={() => handleConditionChange(condition, Conditiontypes.FT)}
                isDisabled>
                {translate('app:possess_ft').toString()}
              </MenuItem>
            </MenuList>
          </Menu>
          <InputGroup w={'300px'} m={2}>
            {condition.type == Conditiontypes.ASSET && (
              <InputLeftAddon pointerEvents="none" children="More than $" />
            )}
            {condition.type == Conditiontypes.TRANSACTION && (
              <InputLeftAddon pointerEvents="none" children="More than" />
            )}
            <Input
              colorScheme="purple"
              value={inputValue}
              onBlur={handleBlur}
              backgroundColor="white"
              placeholder={
                condition.type === Conditiontypes.NFT ? 'Contract Address' : 'Number of TX'
              }
              onChange={handleInputChange}
              boxShadow="sm"
              borderRadius="sm"
              _focusVisible={{
                borderColor: 'purple.800',
              }}
            />
          </InputGroup>

          <Menu>
            <MenuButton
              w="200px"
              borderWidth="1px"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              backgroundColor={selectedBgColor}
              boxShadow="sm"
              borderRadius="sm"
              _hover={{
                bg: 'gray.200',
              }}
              _expanded={{
                bg: 'gray.200',
              }}
              _focus={{
                outline: 'none',
              }}>
              {condition.chain && chainIdToChainName(condition.chain)
                ? chainIdToChainName(condition.chain)
                : translate('app:select_chains').toString()}
            </MenuButton>
            <MenuList>
              {condition.type == Conditiontypes.ASSET && (
                <MenuItem minH="40px" onClick={() => handleChainChange(condition, 'all')}>
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src="images/white.png"
                    alt="All"
                    mr="12px"
                  />
                  <span>All Chains</span>
                </MenuItem>
              )}
              {condition.type == Conditiontypes.TRANSACTION && (
                <MenuItem minH="40px" onClick={() => handleChainChange(condition, 'all')}>
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src="images/white.png"
                    alt="All"
                    mr="12px"
                  />
                  <span>All Chains</span>
                </MenuItem>
              )}
              <MenuItem minH="40px" onClick={() => handleChainChange(condition, '0x1')}>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="images/ethereum.png"
                  alt="Ethereum"
                  mr="12px"
                />
                <span>Ethereum</span>
              </MenuItem>
              <MenuItem minH="40px" onClick={() => handleChainChange(condition, '0x89')}>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="images/polygon.jpeg"
                  alt="Polygon"
                  mr="12px"
                />
                <span>Polygon</span>
              </MenuItem>
              <MenuItem minH="40px" isDisabled>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="images/bsc.svg"
                  alt="BSC"
                  mr="12px"
                />
                <span>BSC</span>
              </MenuItem>
              <MenuItem minH="40px" isDisabled>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="images/astar.svg"
                  alt="BSC"
                  mr="12px"
                />
                <span>Astar</span>
              </MenuItem>
              <MenuItem minH="40px" isDisabled>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="images/oasys.svg"
                  alt="BSC"
                  mr="12px"
                />
                <span>Oasys</span>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {isFiltering ? (
          <Button w={'100px'} variant="outline" mt={5} borderColor="purple.500" color="purple.500">
            <Spinner thickness="2px" speed="0.65s" emptyColor="gray.200" color="purple" size="md" />
          </Button>
        ) : (
          <Button
            w={'100px'}
            variant="outline"
            mt={5}
            onClick={filterFriends}
            borderColor="purple.500"
            color="purple.500"
            _hover={{
              backgroundColor: 'purple.500',
              color: 'white',
            }}>
            {translate('app:filtering').toString()}
          </Button>
        )}
      </Box>
    );
  };
  const neonStyleText = {
    bgClip: 'text',
    bgGradient: 'linear(to-l, #7c4998, #5497cc)',
    color: 'transparent',
    fontWeight: 'bold',
    _hover: {
      bgGradient: 'linear(to-r, #5497cc, #7c4998)',
    },
  };

  const MessageEditor = ({ editor, message, index, onMessageChange, children }: any) => {
    return (
      <Box
        style={{ border: indexError?.includes(index) ? '3px solid #ff0000' : '' }}
        textAlign={'left'}
        mx={10}
        my={4}
        borderRadius="md"
        boxShadow="0 0 5px rgba(50, 20, 100, 0.8)"
        overflow="hidden">
        <Box
          bgGradient="linear(to-l, #5497cc, #7c4998)"
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          h={'40px'}>
          <Upload {...getUploadProps(message, index)}>
            <IconButton
              aria-label="Attach file"
              icon={<MdAttachFile size={20} />}
              variant="ghost"
              color="white"
              colorScheme="purple"
              _hover={{
                bg: 'purple.700',
              }}
            />
          </Upload>
          {children}
          <IconButton
            size={'sm'}
            aria-label="Remove message"
            icon={<CloseIcon />}
            variant="ghost"
            color="white"
            _hover={{
              bg: '#5497cc',
            }}
            onClick={() => removeMessage(index)}
          />
        </Box>

        <Divider orientation="horizontal" />

        <Slate
          editor={editor}
          initialValue={message}
          onChange={newValue => {
            const newMessages = [...messages];
            newMessages[index] = newValue;
            setMessages(newMessages);
          }}>
          <Editable
            readOnly={hasImageElement(message)}
            decorate={decorator}
            onPaste={e => onPaste(e)}
            renderLeaf={renderLeaf}
            onDragStart={onDragStart}
            renderElement={renderElement}
            onDrop={e => onDrop(e)}
            placeholder={hasImageElement(message) ? '' : $t('messages:placeholder_message')}
            className="border-0 w-full !min-h-[100px] text-black max-h-[500px] mt-4 pl-4 text-[13px] font-normal leading-5 tracking-[0.04em] overflow-y-auto overflow-x-hidden focus:outline-none"
          />
        </Slate>
      </Box>
    );
  };

  return (
    <section className={'w-full relative'}>
      <Box
        className={'w-full min-h-screen p-5'}
        bg="white
    ">
        <WithSubnavigation />
        <Center>
          <Stack textAlign={'center'} justifyContent={'center'} w="800px" spacing={10}>
            <Heading
              mt="40px"
              py={5}
              fontSize={['4xl', '5xl']}
              textTransform="uppercase"
              textAlign="center"
              {...neonStyleText}>
              {translate('app:batch_message_sending').toString()}
            </Heading>
            <Divider borderColor={'#7c4998'} />
            <Box p={5} borderRadius="md" boxShadow="0 0 5px rgba(80, 40, 150, 0.8)">
              <Heading size="md" textAlign="left" color="purple.800" fontWeight={'bold'}>
                {translate('app:destination').toString()}
              </Heading>
              <RadioGroup onChange={setDestination} value={destination} colorScheme="purple" mt={8}>
                <Stack>
                  <Radio value="all" _hover={{ color: 'purple.300' }}>
                    {translate('app:all_users').toString()}
                  </Radio>
                  <Radio value="condition" _hover={{ color: 'purple.300' }}>
                    {translate('app:filter_by_conditions').toString()}
                  </Radio>
                  <Radio value="csv" isDisabled _hover={{ color: 'gray.500' }}>
                    {translate('app:import_from_csv').toString()}
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>

            {destination === 'condition' && (
              <Box p={8} bg="gray.50" borderRadius="md" mt={4} shadow="sm">
                {conditions.map((condition, index) => (
                  <ConditionBox
                    key={`condition-${index}`}
                    condition={condition}
                    onRemove={
                      index !== 0
                        ? () => {
                            const newConditions = [...conditions];
                            newConditions.splice(index, 1);
                            setConditions(newConditions);
                          }
                        : undefined
                    }
                    onChange={newCondition => {
                      setConditions(currentConditions => {
                        const newConditions = [...currentConditions];
                        newConditions[index] = newCondition;
                        return newConditions;
                      });
                    }}
                  />
                ))}
                {filteredFriends.length > 0 && (
                  <Center>
                    <Heading size="sm" display="flex" alignItems="center">
                      {translate('app:conditions_message').toString()}
                      <Text as="span" mx={2} fontSize="md" fontWeight="bold" color="purple.600">
                        {filteredFriends.length}
                      </Text>
                    </Heading>
                  </Center>
                )}
                {isNotFound && (
                  <Center>
                    <Text mt={2} color="red" fontWeight={'bold'} textAlign={'left'}>
                      {translate('app:user_not_found').toString()}
                    </Text>
                  </Center>
                )}
              </Box>
            )}
            <Box mt={20} p={5} borderRadius="md">
              <Heading
                size={'md'}
                textAlign={'left'}
                mt={'20px'}
                color={'purple.800'}
                fontWeight={'bold'}>
                {translate('app:message').toString()}
              </Heading>
              <Text mt={4} textAlign={'left'}>
                {translate('app:message_tag').toString()} ※
                {translate('app:message_tag_asterisk').toString()}
              </Text>
              <Input
                mt={4}
                mb={10}
                placeholder={translate('app:message_tag_example').toString()}
                onChange={handleMessageTagChange}
                _focusVisible={{
                  borderColor: 'purple.800',
                }}></Input>
              <div>
                {messages.map((message, index) => (
                  <MessageEditor
                    key={index}
                    editor={editors[index]}
                    message={message}
                    index={index}
                    onMessageChange={handleMessageChange}
                  />
                ))}
                {messages.length < 3 && (
                  <Box textAlign={'left'} mt={10}>
                    <Button
                      mt={4}
                      color="purple.400"
                      backgroundColor="white"
                      border="1px"
                      borderColor="purple.500"
                      ml="40px"
                      w={150}
                      size="sm"
                      onClick={addMessage}
                      _hover={{
                        bg: 'purple.400',
                        color: 'white',
                      }}
                      _active={{
                        bg: 'purple.200',
                      }}
                      transition="all 0.3s ease-in-out">
                      {translate('app:add_message').toString()} +
                    </Button>
                  </Box>
                )}
              </div>
            </Box>
            {isValidateError && (
              <Center>
                <Text mt={2} color="red" fontWeight={'bold'} textAlign={'left'}>
                  {convertErrorMessage()}
                </Text>
              </Center>
            )}
            <Center>
              <Button
                onClick={validateMessage}
                bgGradient="linear(to-l, #7c4998, #5497cc)"
                color="white"
                _hover={{
                  bgGradient: 'linear(to-r, #5497cc, #7c4998)',
                  boxShadow: '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
                }}
                _active={{
                  bgGradient: 'linear(to-r, #7c4998, #5497cc)',
                  boxShadow:
                    'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
                }}
                boxShadow="0 0 8px rgba(124, 73, 152, 0.6), 0 0 12px rgba(84, 151, 204, 0.6)"
                borderRadius="md"
                fontWeight="bold"
                size="md"
                leftIcon={<EmailIcon />}
                transition="all 0.2s ease-in-out">
                {translate('app:send_message').toString()}
              </Button>
            </Center>
          </Stack>
        </Center>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />

            {activeModal === 1 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:confirmation').toString()}
                </Heading>
                <ModalBody>
                  <Text>
                    {/* <Text as="span" mx={2} fontSize="md" fontWeight="bold" color="purple.600">
                      {destination === 'all' ? friendsWithKeys.length : filteredFriends.length}
                    </Text> */}
                    {translate('app:sending_confirmation_message', {
                      number:
                        destination === 'all' ? friendsWithKeys.length : filteredFriends.length,
                    }).toString()}
                  </Text>
                  <Center mt={10}>
                    <Flex>
                      <Button mr={3} onClick={onClose}>
                        {translate('app:back').toString()}
                      </Button>
                      <Button
                        onClick={handleSendMessage}
                        bgGradient="linear(to-l, #7c4998, #5497cc)"
                        color="white"
                        _hover={{
                          bgGradient: 'linear(to-r, #5497cc, #7c4998)',
                          boxShadow:
                            '0 0 12px rgba(124, 73, 152, 0.8), 0 0 16px rgba(84, 151, 204, 0.8)',
                        }}
                        _active={{
                          bgGradient: 'linear(to-r, #7c4998, #5497cc)',
                          boxShadow:
                            'inset 0 0 10px rgba(124, 73, 152, 0.6), inset 0 0 10px rgba(84, 151, 204, 0.6)',
                        }}
                        borderRadius="md"
                        fontWeight="bold"
                        size="md"
                        transition="all 0.2s ease-in-out">
                        {translate('app:send').toString()}
                      </Button>
                    </Flex>
                  </Center>
                </ModalBody>
              </Center>
            )}

            {activeModal === 2 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending').toString()}
                </Heading>
                <EmailIcon color="purple" w={8} h={8} />
              </Center>
            )}

            {activeModal === 3 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending_complete').toString()}
                </Heading>
                <CheckIcon color="green" w={8} h={8} mb={4} />
                <Button
                  onClick={() => {
                    onClose();
                    setActiveModal(1);
                    navigate(ROUTES.crm);
                  }}>
                  {translate('app:close').toString()}
                </Button>
              </Center>
            )}

            {activeModal === 4 && (
              <Center flexDirection="column" p={5}>
                <Heading size="md" mb={3}>
                  {translate('app:sending_failed').toString()}
                </Heading>
                <WarningIcon color="red" w={8} h={8} mb={4} />
                <Button
                  onClick={() => {
                    onClose();
                    setActiveModal(1);
                  }}>
                  {translate('app:close').toString()}
                </Button>
              </Center>
            )}
          </ModalContent>
        </Modal>
      </Box>
    </section>
  );
};

export default NewMessage;
