import React, { forwardRef, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import ReactLoading from 'react-loading';
import { NavLink } from 'react-router-dom';

import { Link } from '@chakra-ui/react';
import { ListIcon } from 'containers/layouts/navbar/icons/list.icon';
import cssClass from 'containers/layouts/navbar/navbarV2.module.scss';
import { $t } from 'library/utils';
import { getCurrentLanguage } from 'library/utils/i18n/i18n';
import ROUTES from 'routes/constant';

import { ConnectWallet } from './connect-wallet';
import { LanguageSwitchingV2 } from './language-switching-v2';
type AccountRef = {
  handlePrivateKey: (cb: (key: string) => void) => Promise<void>;
};

interface NavbarProps {
  className?: string;
  primary?: boolean;
  label?: string;
  size?: string;
  openDrawer?: () => void;
}

const NavbarV2 = forwardRef(({ className, openDrawer }: NavbarProps, ref) => {
  /**
   * STATES
   */

  const accountRef = useRef<AccountRef>(null);
  //const isLoadingNavbar = useRef<boolean>(false);
  const [isLoadingNavbar, setIsLoadingNavbar] = useState<boolean>(false);
  const [backgroundChange, setBackgroundChange] = useState(false);
  const navRef = useRef(null);
  /**
   * HOOKS
   */
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  useEffect(() => {
    const handleScroll = () => {
      const shouldChangeBackground = window.scrollY > 56;
      setBackgroundChange(shouldChangeBackground);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  /**
   * RENDERS
   */
  return (
    <>
      {isLoadingNavbar ? (
        <div>
          <div className="bg-submit-loading"></div>
          <div className="bg-submit-loading !opacity-100 !bg-transparent w-full min-h-[100vh] flex flex-col items-center justify-center	">
            <ReactLoading type="bars" width={100} color="#7E4698" />
          </div>
        </div>
      ) : (
        <> </>
      )}

      <div className={`${cssClass.navbarV2} navbarV2`} ref={navRef}>
        <div className={`navbar-container ${backgroundChange ? 'scrolled' : ''}`}>
          <div className="flex flex-row items-center gap-x-10 nav-left">
            <div className="drawer-button">
              <span onClick={openDrawer}>
                <ListIcon className="list-icon" />
              </span>
            </div>
            <div className="nav-logo">
              <NavLink to="/">
                <img src={'/images/layoutV2/logo.png'} alt="logo" />
              </NavLink>
            </div>
            <div className="nav-links">
              <ul>
                <li>
                  <NavLink to={ROUTES.home}>{$t('homepage_navigation_links:link_home')}</NavLink>
                </li>
                <li>
                  <NavLink to={ROUTES.partners}>
                    {$t('homepage_navigation_links:link_partners')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ROUTES.tokens}>{$t('homepage_navigation_links:link_token')}</NavLink>
                </li>
                <li>
                  <Link href="https://dmtp.gitbook.io/dmtp-docs" isExternal>
                    {$t('homepage_navigation_links:link_docs')}
                  </Link>
                </li>
                <li>
                  <Link
                    isExternal
                    href={
                      currentLanguage === 'en'
                        ? 'https://dmtp-tech.zendesk.com/hc/en-us/sections/26856529070617-FAQ'
                        : 'https://dmtp-tech.zendesk.com/hc/ja/sections/26856529070617'
                    }>
                    {$t('homepage_navigation_links:link_faq')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="ml-auto gap-x-2 nav-right flex flex-row items-center">
            <LanguageSwitchingV2 />
            <div className="connect-button-content">
              <ConnectWallet is_watch_address={true} ref={accountRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default React.memo(NavbarV2, isEqual);
