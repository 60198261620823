import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'common';
import { isEmpty, isEqual } from 'lodash';
import { WHITELIST_TYPE } from 'model/room.interface';
import { useDisconnect } from 'wagmi';

import ROUTES from './constant';
interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectPath: string;
  role?: string;
}

export const ProtectedRoute = ({ children, redirectPath, role }: ProtectedRouteProps) => {
  const { userInfo, whitelistType } = useSelector(state => state.app);
  const { disconnect } = useDisconnect();
  const { pathname } = useLocation();
  if (!userInfo || isEmpty(userInfo)) {
    disconnect();
    if (isEqual(pathname, ROUTES.home)) {
      return <Navigate to={ROUTES.home} />;
    }
    return <Navigate to={redirectPath} replace />;
  }

  // check role for CRM page
  if (role === 'admin') {
    if (whitelistType === WHITELIST_TYPE.CRM || whitelistType === WHITELIST_TYPE.MASTER) {
      return <React.Fragment>{children}</React.Fragment>;
    }
    return <Navigate to={ROUTES.home} />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};
