/* eslint-disable @typescript-eslint/no-explicit-any */
import { walletConnectProvider } from '@web3modal/ethereum';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { configureChains } from 'wagmi';
import { arbitrum, polygon, polygonMumbai, sepolia } from 'wagmi/chains';
const projectId = process.env.REACT_APP_PROJECT_ID || 'b602800e0a4465c2192ee8f48909d7be';
const isPro = process.env.REACT_APP_ENVIRONMENT === 'production';
const _chain: Array<any> = isPro ? [arbitrum, polygon] : [sepolia, polygonMumbai];

const { chains } = configureChains(_chain, [walletConnectProvider({ projectId: projectId })]);

const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN || 'https://dmtp.tech';
const metadata = {
  name: 'DMTP',
  description: 'DMTP',
  url: REACT_APP_DOMAIN,
  icons: [
    'https://dev.dmtp.tech/file/download-file?cid=QmXxLYHWp1UuTYSoTE8wvi6G7FPUxyKh5k6k9pDPssn6hd',
  ],
};

export const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: 'light',
});
