export const ColorDefault = {
  //new
  shade10: '#1E1E1E',
  shade20: '#EFEFEF',
  shade50: '#7C7C7C',
  colorIcon: '#BFBFBF',
  primaryPurple: '#7E4698',
  primarySkyblue: '#529DD0',
  danger: '#FE5050',

  //old
  primary: 'rgb(0, 122, 255)',
  background: 'rgb(255, 255, 255)',
  backgroundDefault: '#E5E5E5',
  card: 'rgb(255, 255, 255)',
  text: 'rgb(28, 28, 30)',
  border: 'rgb(216, 216, 216)',
  notification: 'rgb(255, 59, 48)',
  error: 'rgb(255, 59, 48)',
  info: '#ffd700',
  textLv1: '#242424',
  textLv2: '#424242',
  textLv3: '#616161',
  textLv4: '#999999',
  strokeLv1: '#616161',
  strokeLv2: '#C8CFD7',
  strokeLv3: '#DFDFDF',
  generalG1: '#242424',
  generalG2: '#424242',
  generalG3: '#767676',
  generalG4: '#C7C7C7',
  generalG5: '#E0E0E0',
  generalG6: '#F3F3F3',
  generalG7: '#F5F5F5',
  generalG8: '#FFFFFF',
  primaryYellow1: '#BB8531',
  primaryYellow2: '#D0A85C',
  primaryYellow3: '#F4E2A8',
  primaryYellow4: '#E4CEAD',
  primaryYellow5: '#F9F4ED',
  brightRed1: '#C51B00',
  brightRed2: '#FF4B2E',
  brightRed3: '#FF7862',
  brightRed4: '#FFA597',
  brightRed5: '#FFD2CB',
  deepCarbon1: '#2B2D30',
  deepCarbon2: '#474B50',
  deepCarbon3: '#81868E',
  deepCarbon4: '#A0A4AA',
  deepCarbon5: '#C0C2C6',
  bigStone1: '#172C3B',
  bigStone2: '#284D67',
  bigStone3: '#4F8EBA',
  bigStone4: '#7BAACC',
  bigStone5: '#A7C6DD',
  gradientGoldYellow: {
    colors: ['#BB8531', '#E39925'],
    start: { x: 0, y: 1 },
    end: { x: 1, y: 0 },
  },
  statusSupportNeonRed: '#FC1817',
  statusSupportNeonGreen: '#00FF0A',
  statusSupportError: '#DE350B',
  statusSupportLink: '#0052CC',
  statusSupportSuccess: '#00875A',
  statusSupportWarning: '#FFC400',
  statusBookes: '#0052CC',
  statusProccessing: '#18A800',
  statusAccepted: '#307200',
  statusRejected: '#016443',
  statusCanceled: '#FC1817',
  statusExpired: '#424242',
  statusCompleted: '#BB8531',
  transparent: 'rgba(0, 0, 0, 0)',
  backgroundInformative: '#E6EEFA',
  backgroundError: '#FCEBE7',
  backgroundSuccess: '#E6F3EF',
  backgroundChange: '#E8EAEC',
  statusBackgroundBlue: '#E6EEFA',
  statusBackgroundGreen1: '#E2FADE',
  statusBackgroundGreen2: '#D0E4C0',
  statusBackgroundGreen3: '#B7E3D5',
  statusBackgroundRed: '#FCEBE7',
  statusBackgroundGray: '#E0E0E0',
  statusBackgroundYellow: '#F9F4ED',
  backgroundSearch: 'rgba(1, 18, 34, 0.05)',
};
