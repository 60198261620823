/* eslint-disable react-hooks/exhaustive-deps */
/**
 * remove this line when use
 */
// export {};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useSelector } from 'common/hooks';
import { io, Socket } from 'socket.io-client';

export const useSocket = () => {
  // state
  const [socket, setSocket] = useState<Socket | undefined>(undefined);
  const { token, userInfo } = useSelector(state => state.app);

  // function
  const socketDisconnect = useCallback(() => {
    if (socket) {
      socket.offAny();
      socket.disconnect();
      setSocket(undefined);
    }
  }, [socket]);

  const socketInit = useCallback(() => {
    const client = io(process.env.REACT_APP_API_URL_SOCKET_PORT || '', {
      transports: ['websocket'],
      autoConnect: false,
      reconnectionAttempts: 0,
      reconnection: true,
      auth: {
        authorize: token,
      },
      // extraHeaders: {
      //   authorize: token as string,
      // },
      path: '/socket.io',
    });
    client.connect();

    client.on('connect', () => {
      console.log('Connected', client.id);
    });
    client.on('connect_error', err => {
      console.log(err);
      console.log(`connect_error due to ${err}`);
    });

    client.on('reconnect', () => {
      console.log('reconnect');
    });

    client.on('disconnect', reason => {
      console.log('🚀 ~ socketInit ~ userInfo:', userInfo);
      if (!userInfo?.wallet_address) {
        window.location.reload(); // reload page when logout
      }
      console.log('disconnect', reason);
    });

    setSocket(client);
  }, [token]);

  useEffect(() => {
    if (token) {
      socketInit();
    } else {
      socketDisconnect();
    }

    window.addEventListener('online', () => {
      if (token) {
        socketInit();
      }
    });

    window.addEventListener('offline', () => {
      socket?.disconnect();
    });

    return () => {
      socketDisconnect();
    };
  }, [socketInit, token]);

  const socketOff = useCallback(
    (event?: string, listener?: any) => {
      if (socket) {
        socket.off(event, listener);
      }
    },
    [socket],
  );

  const socketListen = useCallback(
    (event: string, listener: (...args: any[]) => void) => {
      if (socket) {
        socket.on(event, listener);
      }
    },
    [socket],
  );

  const removeAllListeners = useCallback(
    (eventName: string) => {
      if (socket) {
        socket.removeAllListeners(eventName);
      }
    },
    [socket],
  );

  // result
  return {
    socket,
    socketInit,
    socketOff,
    socketListen,
    socketDisconnect,
    removeAllListeners,
  };
};

type SocketContext = {
  socket: Socket | undefined;
  socketOff: (event?: string, listener?: any) => void;
  socketListen: (event: string, listener: (...args: any[]) => void) => void;
  socketInit: () => void;
  socketDisconnect: () => void;
  removeAllListeners: (eventName: string) => void;
};

export const SocketIoContext = createContext<SocketContext>({} as SocketContext);
export const SocketProvider = SocketIoContext.Provider;
export const useSocketContext = () => useContext(SocketIoContext);
