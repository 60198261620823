import { NFTContract } from './chain.interface';
import { E_ROOM_TYPE, MessageData } from './messages';

export interface CreateRoom {
  users: string[];
  name: string;
  room_type: E_ROOM_TYPE;
  description: string;
  avatar: string;
  nft_contract?: NFTContract;
  only_view: boolean;
}

export interface JoinRoom {
  roomId: string;
}

export type LeaveRoom = {
  address: string;
  room_id: string;
};
export type RoleRoom = JoinRoom & { address: string; role: string };
export type UnsetAdminRoom = JoinRoom;
export interface BaseRoom {
  roomId: string;
}

export type UpdateRoom = {
  description?: string;
  avatar?: string;
  name?: string;
  roomId: string;
};

export type RemoveRoom = BaseRoom;

export interface RenameRoom extends BaseRoom {
  newName: string;
}

export interface InteractRoomMessage {
  message_id: string;
  room_id: string;
}

export type ReceiveRoomMessage = InteractRoomMessage;

export interface MessageInRoomPara {
  room_id: string;
  page: number;
  limit: number;
  isDescending: boolean;
}

export interface ReactMessage {
  emoji: string;
  message_id: string;
}

export interface UpdateMessage {
  message_id: string;
  message_data: string;
}

export interface UpdateMessageGroupUnlimit {
  message_id: string;
  message_data: MessageData;
}

export interface DeleteMessage {
  message_id: string;
}

export interface IFormUpdateRoom {
  name: string;
  description: string;
  avatar: string;
  only_view: boolean;
}

export interface RoomSharedKey {
  _id: string;
  key_data: string;
  room_id: string;
  created_at: string;
}

export enum WHITELIST_TYPE {
  ACCESS = 'ACCESS',
  GROUP_CREATOR = 'GROUP_CREATOR',
  CRM = 'CRM',
  MASTER = 'MASTER',
}

export interface WHITELIST_PERMISSION {
  is_exist: boolean;
  type: WHITELIST_TYPE;
}
