import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { Avatar, Badge, Button, Form, Input, Modal } from 'antd';
import { dispatch } from 'common';
import { ApiConstants, NetWorkService } from 'library/networking';
import { $t, translate } from 'library/utils';
import { last } from 'lodash';
import { UserInfo } from 'model/home';
import { IUpdateUserInfo } from 'model/user.interface';
import { AiOutlineEdit } from 'react-icons/ai';
import { appActions, homeActions } from 'redux/action-slice';

interface UserEditProps {
  open: boolean;
  onClose: () => void;
  userInfo: UserInfo;
  updateUserInfo: ({ name, description, avatar }: IUpdateUserInfo, cbSuccess?: () => void) => void;
}

export const UserEdit = ({
  open,
  onClose,
  userInfo: userInfoRx,
  updateUserInfo,
}: UserEditProps) => {
  const [form] = Form.useForm();
  const [url, setUrl] = useState<string>('');
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    getUserInfo();
  }, []);

  const renderAvatar = useCallback(() => {
    if (url)
      return (
        <Avatar
          src={
            url.startsWith('http') ? url : `${process.env.REACT_APP_API_URL + url}&t=${Date.now()}`
          }
          size={100}
          className="relative"
        />
      );
    if (userInfo) {
      return (
        <Avatar size={100} className="bg-transparent border-none">
          <Jazzicon
            paperStyles={{ width: 100, height: 100 }}
            seed={jsNumberForAddress(userInfo.wallet_address)}
          />
        </Avatar>
      );
    }

    return null;
  }, [url, userInfo]);
  const getUserInfo = () => {
    dispatch(
      homeActions.onGetUserInfo(data => {
        setUserInfo(data);
        setUrl(data.avatar);
        dispatch(appActions.onSetUserInfo(data));
      }),
    );
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append('imageFiles', e.target.files[0]);
      try {
        const response = await NetWorkService.PostFormData<string[]>({
          url: ApiConstants.UPLOAD_IMAGES,
          body: formData,
        });

        if (response && response.data) {
          const [url] = response.data;
          const arr = url.split('=');
          setUrl(url);
          form.setFieldValue('avatar', last(arr) as string);
        }
      } catch (e) {
        console.log(e, 'upload');
      }
    }
  };

  return (
    <>
      {userInfo && (
        <Modal footer={null} centered open={open} onCancel={onClose}>
          <Form
            form={form}
            layout="horizontal"
            initialValues={{
              name: userInfo?.name,
              description: userInfo?.description,
            }}
            onFinish={value => {
              updateUserInfo(
                {
                  ...value,
                  description: value.description.trim(),
                  name: value.name.trim(),
                },
                () => getUserInfo(),
              );
            }}>
            <div className="gap-y-10 user-avatar">
              <div className="gap-y-2 user-avatar">
                <div className=" gap-x-4 pt-4 user-avatar">
                  <Form.Item name="avatar">
                    <Badge
                      count={
                        <div className="w-6 h-6 rounded-full bg-main-color cursor-pointer text-white hover:opacity-90 hover:text-slate-50  focus:text-white">
                          <Input
                            id="upload"
                            type="file"
                            accept="image/*"
                            multiple={false}
                            className="hidden user-avatar"
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                          />

                          <label
                            className="cursor-pointer w-full h-full flex justify-center"
                            htmlFor="upload">
                            <AiOutlineEdit size={18} className="self-center" />
                          </label>
                        </div>
                      }
                      offset={[-18, 80]}>
                      {renderAvatar()}
                    </Badge>
                  </Form.Item>
                  <div className="line" />
                  <div>
                    <div className="username-section">
                      <Form.Item
                        name="name"
                        label={$t('messages:user_form:label_name')}
                        rules={[
                          {
                            max: 50,
                            message: translate('msg:MSG_009', {
                              fieldName: $t('messages:user_form:field_user_name'),
                              maximumLength: 50,
                            }).toString(),
                          },
                          {
                            message: translate('msg:MSG_001', {
                              fieldName: $t('messages:user_form:field_user_name'),
                            }).toString(),
                            whitespace: true,
                          },
                        ]}>
                        <Input placeholder={userInfo?.name} />
                      </Form.Item>
                    </div>
                    <div className="line" />
                    <div className="username-section">
                      <Form.Item
                        name="description"
                        label={$t('messages:user_form:label_bio')}
                        className="flex-[2]">
                        <Input.TextArea placeholder={userInfo?.description} />
                      </Form.Item>
                    </div>
                    <div className="line bottom" />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-2">
                <Button
                  shape="round"
                  htmlType="submit"
                  className="px-10 bg-main-color text-white hover:scale-105 hover:!text-white hover:opacity-90 font-semibold save-button">
                  {$t('app:save')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};
