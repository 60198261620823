export const SOCKET_EVENT_NAME = {
  totalUnreadCount: 'total unread',
  friendRequest: 'friend request',
  friendAccept: 'friend accept',
  unfriend: 'unfriend',
  notification: 'notification',
  roomCreate: 'room create',
  friendRequestDeleted: 'friend request deleted',
  reactionAdded: 'reaction added',
  reactionRemoved: 'reaction removed',
  messageSent: 'message sent',
  messageDeleted: 'message deleted',
  messageUpdated: 'message updated',
  messageStatus: 'message status',
  userJoined: 'user joined',
  userLeave: 'user left',
  userRole: 'user set role',
  roomUpdated: 'room updated',
  groupCreated: 'room create group',
};
