import { NetWorkService } from 'library/networking/service-key';
const URL = process.env.REACT_APP_KEY_API_URL || 'https://dmtp-api.vinaweb.app';

const handleResponse = response => {
  if (response?.code === 200) {
    return response?.data?.data || response?.data || response;
  } else {
    throw response;
  }
};

// authentication - start
const authToken = async data => {
  const res = await NetWorkService.Post({ url: `${URL}/api/v1/authentication/token`, body: data });
  return handleResponse(res);
};
// authentication - end

// key - start
const generateSignature = async () => {
  const res = await NetWorkService.Post({ url: `${URL}/api/v1/key/generate/signature` });
  return handleResponse(res);
};
const createWatchlist = async data => {
  const res = await NetWorkService.Post({ url: `${URL}/api/v1/key/watchlist`, body: data });
  return handleResponse(res);
};
const removeWatchlist = async address => {
  const res = await NetWorkService.Delete({ url: `${URL}/api/v1/key/watchlist/${address}` });
  return handleResponse(res);
};
const checkWatchlist = async address => {
  const res = await NetWorkService.Get({ url: `${URL}/api/v1/key/watchlist/${address}/check` });
  return handleResponse(res);
};
const getWatchlist = async params => {
  const res = await NetWorkService.Get({ url: `${URL}/api/v1/key/watchlist`, params });
  return handleResponse(res);
};
// key - end

// explore - start
const getTrending = async data => {
  const res = await NetWorkService.Post({ url: `${URL}/api/v1/explorer/trending`, body: data });
  return handleResponse(res);
};
const getRanking = async data => {
  const res = await NetWorkService.Post({ url: `${URL}/api/v1/explorer/ranking`, body: data });
  return handleResponse(res);
};
// explore - end

const func = {
  authToken,
  generateSignature,
  createWatchlist,
  removeWatchlist,
  checkWatchlist,
  getWatchlist,
  getTrending,
  getRanking,
};

export default func;
