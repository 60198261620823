import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

import { ColorDefault } from './color';
type ColorDefault = typeof ColorDefault;

export type Colors = ColorDefault;

// theme.ts

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({ config });

export default theme;
