import { createAction } from '@reduxjs/toolkit';
import { UserInfo, UserInfoExtend } from 'model/home';
import {
  BatchMessageSender,
  Group,
  MessageGroupSender,
  MessageRoom,
  MessageRoomWithFriendStatus,
  MessageSender,
  Room,
} from 'model/messages';
import { IErrorResponse } from 'model/response';
import {
  CreateRoom,
  DeleteMessage,
  InteractRoomMessage,
  JoinRoom,
  LeaveRoom,
  MessageInRoomPara,
  ReactMessage,
  ReceiveRoomMessage,
  RemoveRoom,
  RenameRoom,
  RoleRoom,
  RoomSharedKey,
  UnsetAdminRoom,
  UpdateMessage,
  UpdateRoom,
} from 'model/room.interface';

import * as Actions from '../action-type';

const onGetRoomList = createAction(
  Actions.GET_ROOM_LIST,
  (
    params: { page: number; limit: number },
    onSucceeded: (data: Array<Room>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetPublicRoomList = createAction(
  Actions.GET_PUBLIC_ROOM_LIST,
  (
    params: { page: number; limit: number; filter: string },
    onSucceeded: (data: Array<Group>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      params,
      onSucceeded,
      onFailed,
    },
  }),
);

const onGetMessageInRoom = createAction(
  Actions.GET_MESSAGE_IN_ROOM,
  (
    params: MessageInRoomPara,
    onSucceeded: (data: MessageRoomWithFriendStatus) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onGetUserByAddress = createAction(
  Actions.GET_USER_BY_ADDRESS,
  (
    params: { address: string },
    onSucceeded: (data: UserInfoExtend[]) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onGetRoomId = createAction(
  Actions.GET_ROOM_ID,
  (
    params: { opposite_user: string },
    onSucceeded: (data: string) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onGetUserByName = createAction(
  Actions.GET_USER_BY_NAME,
  (
    params: { name: string },
    onSucceeded: (data: UserInfo[]) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onCreateRoom = createAction(
  Actions.CREATE_ROOM,
  (
    body: CreateRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onJoinRoom = createAction(
  Actions.ADD_USER_TO_ROOM,
  (
    body: JoinRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onJoinPublicGroup = createAction(
  Actions.ADD_USER_TO_PUBLIC_ROOM,
  (
    body: JoinRoom,
    onSucceeded: (data: Group) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onLeaveRoom = createAction(
  Actions.REMOVE_USER_OF_ROOM,
  (
    body: LeaveRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onSetAdminRoom = createAction(
  Actions.SET_ADMIN_OF_ROOM,
  (
    body: RoleRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUnsetAdminRoom = createAction(
  Actions.UNSET_ADMIN_OF_ROOM,
  (
    body: UnsetAdminRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onReactMessage = createAction(
  Actions.USER_REACT_MESSAGE,
  (
    body: ReactMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onRemoveReactMessage = createAction(
  Actions.USER_REMOVE_REACT_MESSAGE,
  (
    body: ReactMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUpdateMessage = createAction(
  Actions.USER_UPDATE_MESSAGE,
  (
    body: UpdateMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onDeleteMessage = createAction(
  Actions.USER_DELETE_MESSAGE,
  (
    body: DeleteMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onRemoveRoom = createAction(
  Actions.REMOVE_ROOM,
  (
    body: RemoveRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onRenameRoom = createAction(
  Actions.RENAME_ROOM,
  (
    body: RenameRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onReadMessageRoom = createAction(
  Actions.READ_MESSAGE_ROOM,
  (
    body: InteractRoomMessage,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onReceiveMessageRoom = createAction(
  Actions.READ_MESSAGE_ROOM,
  (
    body: ReceiveRoomMessage,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onSendMessage = createAction(
  Actions.SEND_MESSAGE,
  (
    body: MessageSender,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onSendBatchMessage = createAction(
  Actions.SEND_BATCH_MESSAGE,
  (
    body: BatchMessageSender,
    onSucceeded: (data: MessageRoom[]) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

// group limit
const onGetMessageInRoomLimit = createAction(
  Actions.GET_MESSAGE_GROUP_LIMIT,
  (
    params: MessageInRoomPara,
    onSucceeded: (data: Array<MessageRoom>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onSendMessageGroupLimit = createAction(
  Actions.SEND_MESSAGE_GROUP_LIMIT,
  (
    body: MessageGroupSender & { shared_key_id: string },
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUpdateMessageGroupLimit = createAction(
  Actions.UPDATE_MESSAGE_GROUP_LIMIT,
  (
    body: UpdateMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

// group unlimit
const onGetMessageInRoomUnlimit = createAction(
  Actions.GET_MESSAGE_GROUP_UNLIMIT,
  (
    params: MessageInRoomPara,
    onSucceeded: (data: Array<MessageRoom>) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const onSendMessageGroupUnlimit = createAction(
  Actions.SEND_MESSAGE_GROUP_UNLIMIT,
  (
    body: MessageGroupSender,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUpdateMessageGroupUnlimit = createAction(
  Actions.UPDATE_MESSAGE_GROUP_UNLIMIT,
  (
    body: UpdateMessage,
    onSucceeded: (data: MessageRoom) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onSetRoleOfRoom = createAction(
  Actions.SET_ROLE_OF_ROOM,
  (
    body: RoleRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onUpdateRoom = createAction(
  Actions.UPDATE_ROOM,
  (
    body: UpdateRoom,
    onSucceeded: (data: Room) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      body,
    },
  }),
);

const onGetRoomInfo = createAction(
  Actions.GET_ROOM_INFO,
  (
    params: { room_id: string },
    onSucceeded: (data: Room | { data: Room }) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const getSharedKeys = createAction(
  Actions.GET_GROUP_SHARED_KEYS,
  (
    params: { room_id: string; key_ids: string[] },
    onSucceeded: (data: { data: RoomSharedKey[] }) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

const getLatestSharedKeys = createAction(
  Actions.GET_GROUP_LATEST_SHARED_KEYS,
  (
    params: { room_id: string },
    onSucceeded: (data: { data: RoomSharedKey }) => void,
    onFailed?: (response: IErrorResponse) => void,
  ) => ({
    payload: {
      onSucceeded,
      onFailed,
      params,
    },
  }),
);

export const roomActions = {
  onGetRoomList,
  onGetPublicRoomList,
  onCreateRoom,
  onJoinRoom,
  onJoinPublicGroup,
  onLeaveRoom,
  onSetAdminRoom,
  onUnsetAdminRoom,
  onRemoveRoom,
  onRenameRoom,
  onReadMessageRoom,
  onReceiveMessageRoom,
  onGetMessageInRoom,
  onSendMessage,
  onSendBatchMessage,
  onGetUserByName,
  onGetUserByAddress,
  onGetRoomId,
  onReactMessage,
  onUpdateMessage,
  onDeleteMessage,
  onRemoveReactMessage,
  onGetMessageInRoomLimit,
  onSendMessageGroupLimit,
  onUpdateMessageGroupLimit,
  onGetMessageInRoomUnlimit,
  onSendMessageGroupUnlimit,
  onUpdateMessageGroupUnlimit,
  onSetRoleOfRoom,
  onUpdateRoom,
  onGetRoomInfo,
  getSharedKeys,
  getLatestSharedKeys,
};
