import { configureStore } from '@reduxjs/toolkit';
import { subscribeActionMiddleware } from 'common/redux/redux-subscribe-action';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { allReducer } from './all-reducers';
import { rootSaga } from './root-sagas';
/**
 * Use this instead storage of reduxPersist
 *
 */
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, allReducer);
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, subscribeActionMiddleware];

const storeConfig = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};
export const store = storeConfig();
export const persistor = persistStore(store);
