export const XSocialIcon = ({ className }: { className: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1734 0.857422C11.2904 0.857422 0.84668 11.3012 0.84668 24.1842C0.84668 37.0672 11.2904 47.5109 24.1734 47.5109C37.0564 47.5109 47.5002 37.0672 47.5002 24.1842C47.5002 11.3012 37.0564 0.857422 24.1734 0.857422ZM35.0402 13.1807H31.2608L25.0331 20.2126L19.6485 13.1807H11.8499L21.168 25.2164L12.3365 35.187H16.1181L22.9343 27.4937L28.8913 35.1869H36.4969L26.7833 22.5023L35.0402 13.1807ZM32.0286 32.9525H29.9344L16.2633 15.2978H18.5106L32.0286 32.9525Z"
        fill="currentColor"
      />
    </svg>
  );
};
