import ParseHtmlToReactElement from 'react-html-parser';
import Resizer from 'react-image-file-resizer';

import { AES, enc } from 'crypto-js';
import keccak256 from 'keccak256';
import { last } from 'lodash';
import Web3 from 'web3';

const urlInline =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:(https|http)?|ftp|file|blob):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:;,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;

export const decryptMessage = (message: string, sharedKey: string) => {
  try {
    const decryptMessage = AES.decrypt(message, keccak256(sharedKey).toString('hex')).toString(
      enc.Utf8,
    );
    return JSON.parse(Buffer.from(decryptMessage, 'hex').toString());
  } catch (e) {
    return {
      content: '',
    };
  }
};

export const decryptTempMessage = (message: string, sharedKey: string) => {
  try {
    const decryptMessage = AES.decrypt(message, keccak256(sharedKey).toString('hex')).toString(
      enc.Utf8,
    );

    return decryptMessage;
  } catch (e) {
    return '';
  }
};

export const encryptMessage = (message: Object, sharedKey: string) => {
  return AES.encrypt(
    Buffer.from(JSON.stringify(message)).toString('hex'),
    keccak256(sharedKey).toString('hex'),
  ).toString();
};

export const decryptKey = (key: string, secretKey: string) => {
  try {
    return AES.decrypt(key, keccak256(secretKey).toString('hex')).toString(enc.Utf8);
  } catch (e) {
    return '';
  }
};

export const isArrayString = (arr: unknown[]) => {
  return arr.every(it => typeof it === 'string');
};

export function formatDotAddress(address: string) {
  try {
    return [address.slice(0, 5), address.slice(address.length - 3, address.length)].join('...');
  } catch (error) {
    return address;
  }
}

export function formatChatDotAddress(address: string) {
  try {
    return [address.slice(0, 9), address.slice(address.length - 3, address.length)].join('...');
  } catch (error) {
    return address;
  }
}

export const getRedirectUri = (path: string) => {
  if (path) {
    return `${process.env.REACT_APP_DOMAIN}/messages`;
  }
  return `${process.env.REACT_APP_DOMAIN}`;
};

export const unsecureCopyHanlder = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
};

export const copyHandler = (text: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    unsecureCopyHanlder(text);
  }
};

export const calculatePage = (page: number, numberPerPage: number) => {
  if (numberPerPage > 20 && page === 0) {
    return numberPerPage / 20 - 1;
  }

  return page + 1;
};

export const getLimitByReload = (page: number, limit: number) => {
  return (page + 1) * limit;
};

export const isAdress = (inputText: string) => {
  const web3 = new Web3();

  return web3.utils.isAddress(inputText);
};

export const parseHtmlToReactElement = (html: string) => {
  const replaceLink = html
    .replace(
      /(https?:\/\/[^\s,]+[a-zA-Z0-9])/g,
      "<a href='$1' class='text-blue-400 hover:underline' target='_blank' rel='noopener,noreferrer'>$1</a>",
    )
    .replace(/\n/g, '<br />');
  return ParseHtmlToReactElement(replaceLink, {});
};

export const getFileExtension = (file: File) => {
  const arr = file.type.split('/');
  return last(arr) as string;
};

export const resizeImageAsync = (file: File): Promise<File> =>
  new Promise(resolve => {
    const ext = getFileExtension(file);
    let compressFormat = ext.toUpperCase();
    if (ext === 'jpg') {
      compressFormat = 'JPEG';
    }
    Resizer.imageFileResizer(
      file,
      300,
      300,
      compressFormat,
      80,
      0,
      uri => {
        resolve(uri as File);
      },
      'file',
    );
  });

export const resizeImageSync = (file: File, callback: (file: File) => void) => {
  const ext = getFileExtension(file);
  console.log('ext', ext);
  let compressFormat = ext.toUpperCase();
  if (ext === 'jpg') {
    compressFormat = 'JPEG';
  }
  Resizer.imageFileResizer(
    file,
    300,
    300,
    compressFormat,
    80,
    0,
    uri => {
      callback(uri as File);
    },
    'file',
  );
};

type ParsedData = {
  content: string;
  images: string[];
};

export const parseStringData = (input: string): ParsedData => {
  const contentMatch = input.match(/"content":"(.*?)",/);
  const imagesMatch = input.match(/"images":\[(.*?)\]/);

  if (!contentMatch || !imagesMatch) {
    return {
      content: '',
      images: [],
    };
  }

  const [, contentStr] = contentMatch;
  const [, imagesStr] = imagesMatch;

  const images = imagesStr ? imagesStr.replace(/"/g, '').split(',') : [];

  return { content: contentStr.replace(/\\"/g, '"'), images };
};
