import AVVY from '@avvy/client';
import { LookupAddress } from '@edns/sdk';
import SID, { getSidAddress } from '@siddomains/sidjs';
import { createInstance } from 'dotbit';
import namehash from 'eth-ens-namehash';
import { ethers } from 'ethers';
import { ApiConstants, NetWorkService } from 'library/networking';
import Web3 from 'web3';
import { Web3Resolver } from 'web3-domain-resolver';

export const changeDomainToAddress = async (domain: string) => {
  const ednsDomainTypes = ['meta', 'music', 'ass', '404', 'sandbox', 'web3', 'gamefi', 'iotex'];
  const web3resolver = new Web3Resolver();
  const dotbit = createInstance();
  const unifiedDomain = domain.toLowerCase();
  const check = unifiedDomain.indexOf('.');
  if (check !== -1) {
    //Unstoppable Domains
    const afterDot = unifiedDomain.substring(check + 1);
    const [nextWord] = afterDot.split(' ');
    let unstoppableDomainsTypes: string[] = [];
    await fetch('https://resolve.unstoppabledomains.com/supported_tlds')
      .then(response => response.json())
      .then(data => {
        unstoppableDomainsTypes = data.tlds;
      })
      .catch(error => console.error(error));
    if (unstoppableDomainsTypes.includes(nextWord)) {
      const res: any = await NetWorkService.Get({
        url: ApiConstants.GET_ADDRESS_FROM_UNSTOPPABLE_DOMAIN,
        params: {
          domain: unifiedDomain,
        },
      });
      if (res?.data) {
        return res.data;
      }
    } else if (nextWord == 'eth') {
      //ENS
      const provider = new ethers.providers.InfuraProvider('homestead', [process.env.INFURA_ID]);
      return await provider.resolveName(unifiedDomain);
    } else if (ednsDomainTypes.includes(nextWord)) {
      //EDNS
      return await LookupAddress(unifiedDomain, 'ETH');
    } else if (nextWord == 'bit') {
      //dotbit
      return (await dotbit.accountInfo(unifiedDomain)).owner_key;
    } else if (nextWord == 'bnb') {
      //Space ID
      const rpc = 'https://bsc-mainnet.nodereal.io/v1/d0c3ef1cdb0247f4b6fae228aa76c8b8';
      const provider = new Web3.providers.HttpProvider(rpc);
      const chainId = '56';
      const sid = new SID({ provider, sidAddress: getSidAddress(chainId) });

      const address: string = await sid.name(unifiedDomain).getAddress();
      return address;
    } else if (nextWord == 'ftm') {
      //Ftmname
      const fantomNetwork = {
        name: 'fantom',
        chainId: 250,
        ensAddress: '0xA6770E2036feA13045Aec56bEF7e85f45938E428',
      };
      const fantomProvider = new ethers.providers.JsonRpcProvider(
        'https://rpc2.fantom.network/',
        fantomNetwork,
      );
      const deployerAddress = await fantomProvider.resolveName(unifiedDomain);
      return deployerAddress;
    } else if (nextWord == 'avax') {
      //Avvy Domains
      const PROVIDER_URL = 'https://api.avax.network/ext/bc/C/rpc';
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
      const avvy = new AVVY(provider, '');
      const address = await avvy.name(unifiedDomain).resolve((avvy.RECORDS as any).EVM);
      return address;
    } else if (nextWord == 'klay') {
      //Klayname
      const provider = new ethers.providers.JsonRpcProvider(
        'https://klaytn-mainnet-rpc-korea.allthatnode.com:8551',
      );
      const REGISTRY_ABI = ['function resolver(bytes32) view returns (address)'];
      const PUBLIC_RESOLVER_ABI = ['function addr(bytes32) view returns (address)'];
      const REGISTRY_ADDRESS = '0x0892ed3424851d2Bab4aC1091fA93C9851Eb5d7D';
      const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
      const registry = new ethers.Contract(REGISTRY_ADDRESS, REGISTRY_ABI, provider);
      const node = namehash.hash(unifiedDomain);
      const resolverAddress = await registry.resolver(node);
      if (parseInt(resolverAddress, 16) === 0) {
        return EMPTY_ADDRESS;
      }
      const resolver = new ethers.Contract(resolverAddress, PUBLIC_RESOLVER_ABI, provider);
      try {
        const address = await resolver.addr(node);
        return address;
      } catch (e) {
        console.error(e);
        return EMPTY_ADDRESS;
      }
    } else {
      //Freename
      try {
        const resolvedDomain = await web3resolver.resolve(unifiedDomain);
        return resolvedDomain?.ownerAddress;
      } catch {
        return 'invalid';
      }
      //Add new domain service API here
    }
  } else {
    return domain;
  }
};
