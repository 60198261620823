//Import components
import { useCallback, useRef, useState } from 'react';
// import default react-pdf entry

// import pdf worker as a url, see `next.config.js` and `pdf-worker.js`
import { Button, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CloseIcon } from 'components/icons/close.icon';
import cssClass from 'containers/layouts/navbar/disconnect-confirm--modal.module.scss';
import { $t } from 'library/utils';
//import css class module
import { twMerge } from 'tailwind-merge';

interface DisconnectConfirmModalProps {
  type: string;
  title: string;
  style?: string;
  className?: string;
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export const DisconnectConfirmModal = ({
  type,
  title,
  isOpenModal,
  handleOk,
  handleCancel,
}: DisconnectConfirmModalProps) => {
  /**
   * STATES
   */
  const [numPages, setNumPages] = useState<number>();

  const scrollableDivRef = useRef<HTMLDivElement | null>(null);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  /**
   * HOOKS
   */

  /**
   * FUNCTIONS
   */

  const onCancel = useCallback(() => {
    console.log('onCancel here');
    // for todo logic if have
    handleCancel();
  }, [handleCancel]);
  const handleSubmit = useCallback(() => {
    console.log('submitHere');
    // for todo logic if have
    handleOk();
  }, [handleOk]);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setIsScrollBottom(e.target.checked);
  };
  /**
   * RENDERS
   */
  const customCloseIcon = <CloseIcon />;
  const customFooter = (
    <div className="disconnect-confirm-modal-footer">
      <Button className="btn-primary-custom-outline w-full text-center" onClick={onCancel}>
        {$t('marketplace:review_agreement_modal:btn_cancel')}
      </Button>
      <Button className="btn-primary-custom w-full text-center" onClick={handleSubmit}>
        {$t('messages:btn_disconnect')}
      </Button>
    </div>
  );

  return (
    <Modal
      className="key-modal-custom"
      title={$t(title)}
      open={isOpenModal}
      onOk={handleOk}
      wrapClassName={'disconnect-confirm-modal'}
      onCancel={handleCancel}
      footer={customFooter}
      closeIcon={customCloseIcon}>
      <div className={twMerge(cssClass.disconnectConfirmModal)}>
        {type === 'x-social' ? $t('modal_disconnect_confirm:description_x_social') : ''}
      </div>
    </Modal>
  );
};
