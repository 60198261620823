const ROUTES = {
  notfound: '*',
  login: '/login',
  home: '/',
  partners: '/partners',
  tokens: '/tokens',
  about: '/about',
  posts: '/posts',
  messages: '/messages',
  community: 'community',
  community_for_marketplace: '/messages/community',
  shops: '/shops',
  postDetail: '/posts/:postId',
  createStickers: '/create-stickers',
  listMarketStickers: '/list-market-sticker',
  listOwnerStickers: '/list-owner-sticker',
  chatThread: ':cid',
  preRegistration: '/pre-registration',
  main: '/home',
  error: '/error',
  available: '/pc-available',
  maintenance: '/maintenance',
  wallet: '/wallet',
  linkTelegram: '/link-telegram',
  discordConnect: '/discord-connect',
  notification: 'notification',
  user: 'user',
  nftSale: '/nft-sale',
  crm: '/e795669e1e9e28f145a611abeaaf093aa6c031668dcb6578ba9565100f019937',
  newMessage: '/e90f5a9e4de9a157cba44b0b5f0a020a6fdc33982c0766b6b5bc546ebf3a2962',
  welcomeMessage:
    '/crm-welcome-message7300b177d466ae31945c6eada9bcd83418289e1689b052653264eb55a1da568a',
  subAccounts: '/sub-accounts',
  privacyPolicies: '/privacy-policies',
  tos: '/tos',
  messageList: '/message-list',
  friendList: '/friend-list',
  marketplace: '/market', //update - 2024-03-14
  keys: 'history', //update - 2024-03-14
  keysExplore: 'explore',
  keysMyPage: 'my-key', //update - 2024-03-14
  keysPoints: 'points',
  keysKOLProfile: 'profile',
  signupCrmPage: '/signup_cmp',
};

export default ROUTES;
