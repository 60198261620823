/* eslint-disable @typescript-eslint/no-explicit-any */
/* ERROR BOUNDARY COMPONENT
   ========================================================================== */

import { Component, ReactNode } from 'react';

import { Link as CKLink } from '@chakra-ui/react';
import { AiOutlineTwitter } from 'react-icons/ai';
import './index.scss';
import ROUTES from 'routes/constant';

interface IErrorBoundaryProps {
  onReset: () => void;
  children: ReactNode | null;
}

interface IErrorBoundaryState {
  error: any;
  errorInfo: any;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="h-screen section-gradient text-center pt-40 font-sans">
          <h2 className="mb-40">
            <p className="uppercase md:text-[80px] text-4xl font-bold text-[#9900ff]">Sorry</p>
            <p className="uppercase md:text-[64px] text-xl mt-4 md:mt-12">Something went wrong</p>
          </h2>
          <button
            className="section-button-outline md:text-[19px] text-sm"
            onClick={() => window.location.replace(ROUTES.home)}>
            Go to homepage
          </button>
          <p className="absolute inset-x-0 bottom-[30px] text-purple md:text-[24px] text-sm font-semibold">
            Please report the error you have to{' '}
            <CKLink
              href={'https://twitter.com/DMTPHQ'}
              title="DMTP Twitter"
              textDecoration={'none'}
              _hover={{ textDecoration: 'none' }}>
              <span className="text-[#3baff7] cursor-pointer">
                @DMTP <AiOutlineTwitter className="inline" size={30} color="#3baff7" />
              </span>{' '}
            </CKLink>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
