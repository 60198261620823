import { MAX_ACCEPT_FILE_SIZE } from 'common';
import { resizeImageAsync } from 'common/utils/core';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import { ApiConstants, NetWorkService } from 'library/networking';
import { remove } from 'lodash';
import { Descendant, Editor, Element, Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { serialize } from './handler';

import { CustomEditor, CustomElement, ImageElement } from '../../../@types/custom-type';

export const withImages = (editor: CustomEditor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element: CustomElement) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  const serializeNode = (nodes: Descendant[]) => {
    return nodes.map(n => serialize(n)).join('');
  };

  const uploadImages = async (fileList: File[]) => {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      if (file.size > MAX_ACCEPT_FILE_SIZE) {
        file = await resizeImageAsync(file);
      }

      formData.append('imageFiles', file);
    }
    const response = await NetWorkService.PostFormData<string[]>({
      url: ApiConstants.UPLOAD_IMAGES,
      body: formData,
    });

    return response?.data || [];
  };

  editor.insertData = async (data: DataTransfer) => {
    const text = data.getData('text/plain');
    const { files } = data;

    const message = serializeNode(editor.children);

    const fileArr = [...files];

    const fileListUpload = remove(fileArr, file => !message.includes(file.name));

    if (fileListUpload.length > 0) {
      const urls = await uploadImages(fileListUpload);
      if (urls.length > fileListUpload.length) {
        urls.pop();
      }
      for (let i = 0; i < urls.length; i++) {
        insertImage(editor, urls[i] as string, fileListUpload[i].name);
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const withLinks = (editor: CustomEditor) => {
  const { normalizeNode } = editor;
  editor.normalizeNode = entry => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (Element.isElement(child) && child.type === 'link' && !Node.string(node.children[0])) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, {
              type: 'paragraph',
              children: [{ text: '' }],
            });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

export const insertImage = (editor: CustomEditor, url: string, name: string) => {
  if (!url) return;

  ReactEditor.focus(editor);

  const { selection } = editor;

  const image = createImageNode('image', name, url);

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus.path);

    if (editor.isVoid(parentNode as CustomElement) || Node.string(parentNode).length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.insertNodes(editor, image, {
        at: parentPath,
        select: true,
      });
    }
  } else {
    Transforms.insertNodes(editor, image, { select: true });
  }
};

const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext as string);
};

export const createImageNode = (type: string, name: string, url: string) =>
  ({
    type,
    url,
    name,
    children: [{ text: '' }],
  } as ImageElement);
