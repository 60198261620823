export const ENVConfig = {
  APP_ENV: 'Dev',
  API_URL: process.env.REACT_APP_API_URL,
  APP_DISPLAY_NAME: 'EVE-dev',
  APP_PLACEHOLDER_NAME: 'EVE',
  VERSION_CODE: '1',
  VERSION_NAME: '1.0.0',
  BUNDLE_IDENTIFIER: '',
  DEFAULT_FALLBACK_LNG_I18n: 'en',
  TERM_URL: '',
  PRIVACY_URL: '',
  DEFAULT_LANG: 'en',
};

const NETWOKMB_TESTNET = {
  testnet: true,
  chainId: '0x13881',
  chainName: 'Mumbai',
  currencyName: 'MATIC',
  currencySymbol: 'MATIC',
  currencyDecimals: 18,
  currencyLogo: '/images/matic.png',
  // rpc: 'https://matic-mumbai.chainstacklabs.com',
  rpc: 'https://rpc-mumbai.maticvigil.com',
  rpcwss: 'wss://rpc-mumbai.matic.today',
  chainIdNumber: 80001,
  blockExplorer: 'https://mumbai.polygonscan.com/',
};

const NETWOKMB_MAINNET = {
  testnet: false,
  chainId: '0x89',
  chainName: 'Polygon',
  currencyName: 'MATIC',
  currencySymbol: 'MATIC',
  currencyDecimals: 18,
  currencyLogo: '/images/matic.png',
  rpc: 'https://polygon-rpc.com/',
  rpcwss: 'wss://rpc-mainnet.matic.network',
  chainIdNumber: 137,
  blockExplorer: 'https://polygonscan.com/',
};

export const NETWOKMB =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? NETWOKMB_MAINNET : NETWOKMB_TESTNET;

// const NETWOK_MK_TESTNET = {
//   testnet: true,
//   chainId: '0x66eee',
//   chainName: 'Arbitrum Sepolia (Testnet)',
//   currencyName: 'Ether',
//   currencySymbol: 'ETH',
//   currencyDecimals: 18,
//   currencyLogo: '/images/arbitrum.png',
//   rpc: 'https://arbitrum-sepolia.blockpi.network/v1/rpc/public',
//   rpcwss: '',
//   chainIdNumber: 421614,
//   blockExplorer: 'https://sepolia.arbiscan.io/',
// };
const NETWOK_MK_TESTNET = {
  testnet: true,
  chainId: '0xAA36A7',
  chainName: 'Sepolia',
  currencyName: 'Sepolia Ether',
  currencySymbol: 'ETH',
  currencyDecimals: 18,
  currencyLogo: '/images/arbitrum.png',
  rpc: 'https://rpc.sepolia.org',
  rpcwss: '',
  chainIdNumber: 11155111,
  blockExplorer: 'https://sepolia.etherscan.io',
};
const NETWOK_MK_MAINNET = {
  testnet: false,
  chainId: '0xA4B1',
  chainName: 'Arbitrum One',
  currencyName: 'Ether',
  currencySymbol: 'ETH',
  currencyDecimals: 18,
  currencyLogo: '/images/arbitrum.png',
  rpc: 'https://arb-mainnet-public.unifra.io',
  rpcwss: 'wss://arbitrum-one.publicnode.com',
  chainIdNumber: 42161,
  blockExplorer: 'https://arbiscan.io',
};

export const NETWOK_MK =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? NETWOK_MK_MAINNET : NETWOK_MK_TESTNET;

const TESTNET_CHAINS = [
  {
    chainId: '0x5',
    chainIdNumber: 5,
    chainName: 'Goerli Test Network',
    currencyName: 'ETH',
    currencySymbol: 'ETH',
    rpc: 'https://goerli.infura.io/v3/',
    blockExplorer: 'https://goerli.etherscan.io',
  },
  {
    chainId: '0x13881',
    chainName: 'Mumbai',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpc: 'https://matic-mumbai.chainstacklabs.com',
    rpcwss: 'wss://rpc-mumbai.matic.today',
    chainIdNumber: 80001,
    blockExplorer: 'https://mumbai.polygonscan.com/',
  },
  {
    chainId: '0x61',
    chainName: 'BSC Testnet',
    currencyName: 'TBNB',
    currencySymbol: 'TBNB',
    rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    chainIdNumber: 97,
    blockExplorer: 'https://testnet.bscscan.com',
  },
  {
    chainId: '0xa869',
    chainName: 'Avalanche Fuji Testnet',
    currencyName: 'AVAX',
    currencySymbol: 'AVAX',
    rpc: 'https://api.avax-test.network/ext/bc/C/rpc',
    chainIdNumber: 43113,
    blockExplorer: 'https://testnet.snowtrace.io/',
  },
  {
    chainId: '0x4e454153',
    chainName: 'Aurora Testnet',
    currencyName: 'ETH',
    currencySymbol: 'ETH',
    rpc: 'https://testnet.aurora.dev/',
    chainIdNumber: 1313161555,
    blockExplorer: 'https://testnet.aurorascan.dev/',
  },
];
const MAINNET_CHAINS = [
  {
    chainId: '0x1',
    chainIdNumber: 1,
    chainName: 'Ethereum Mainnet',
    currencyName: 'ETH',
    currencySymbol: 'ETH',
    rpc: 'https://eth-rpc.gateway.pokt.network',
    blockExplorer: 'https://etherscan.io',
  },
  {
    chainId: '0x89',
    chainName: 'Polygon',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpc: 'https://polygon-rpc.com/',
    rpcwss: 'wss://rpc-mainnet.matic.network',
    chainIdNumber: 137,
    blockExplorer: 'https://polygonscan.com/',
  },
  {
    chainId: '0x38',
    chainName: 'BSC Mainnet',
    currencyName: 'BNB',
    currencySymbol: 'BNB',
    rpc: 'https://bsc-dataseed.binance.org',
    chainIdNumber: 56,
    blockExplorer: 'https://testnet.bscscan.com',
  },
  {
    chainId: '0xa86a',
    chainName: 'Avalanche',
    currencyName: 'AVAX',
    currencySymbol: 'AVAX',
    rpc: 'https://rpc.ankr.com/avalanche',
    chainIdNumber: 43114,
    blockExplorer: 'https://snowtrace.io',
  },
  {
    chainId: '0x4e454152',
    chainName: 'Aurora Mainnet',
    currencyName: 'ETH',
    currencySymbol: 'ETH',
    rpc: 'https://mainnet.aurora.dev',
    chainIdNumber: 1313161554,
    blockExplorer: 'https://explorer.mainnet.aurora.dev/',
  },
];

export const CHAINS =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? MAINNET_CHAINS : TESTNET_CHAINS;
