export const GithubIcon = ({ className }: { className: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46" fill="none">
      <path
        d="M23.6504 0.908203C10.6754 0.908203 0.15332 11.2467 0.15332 24.0002C0.15332 34.2007 6.88607 42.8545 16.2273 45.9106C17.4023 46.1233 17.8312 45.4103 17.8312 44.798C17.8312 44.2488 17.8106 42.797 17.7989 40.8707C11.2629 42.2651 9.88232 37.7743 9.88232 37.7743C8.81601 35.1063 7.27382 34.3962 7.27382 34.3962C5.13826 32.9645 7.43245 32.9932 7.43245 32.9932C9.79126 33.1571 11.0309 35.3737 11.0309 35.3737C13.1283 38.9013 16.5328 37.8836 17.8723 37.2913C18.0838 35.7992 18.6919 34.7815 19.3646 34.2036C14.1476 33.62 8.66032 31.6391 8.66032 22.7927C8.66032 20.2713 9.57682 18.21 11.0808 16.5942C10.837 16.0106 10.0321 13.6617 11.3099 10.4848C11.3099 10.4848 13.2839 9.86383 17.7724 12.851C19.6466 12.3392 21.6558 12.0833 23.6563 12.0747C25.6508 12.0862 27.663 12.3392 29.5401 12.8538C34.0256 9.8667 35.9967 10.4877 35.9967 10.4877C37.2774 13.6675 36.4726 16.0135 36.2317 16.5971C37.7386 18.2128 38.6463 20.2742 38.6463 22.7956C38.6463 31.665 33.1532 33.6171 27.9186 34.1892C28.7616 34.9022 29.5136 36.311 29.5136 38.4643C29.5136 41.5521 29.4843 44.0418 29.4843 44.798C29.4843 45.4161 29.9073 46.1348 31.0999 45.9077C40.4264 42.8487 47.1533 34.1978 47.1533 24.0002C47.1533 11.2467 36.6312 0.908203 23.6504 0.908203Z"
        fill="currentColor"
      />
    </svg>
  );
};
