/* LAYOUT DEFAULT COMPONENT
   ========================================================================== */

import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { dispatch, useSelector } from 'common';
// eslint-disable-next-line sort-imports
import { ProgressDialog, hideLoading, showLoading } from 'library/components/progress-dialog';
import { Toast } from 'library/components/toast';
import { homeActions } from 'redux/action-slice/home';

import Styled from './default.style';
import Navbar from './navbar/navbar';

const Default = () => {
  const { showDialog, token } = useSelector(x => x.app);

  //effect
  useEffect(() => {
    if (showDialog) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [showDialog]);

  useEffect(() => {
    if (token) {
      dispatch(
        homeActions.onGetUserInfo(data => {
          // dispatch(appActions.onSetUserInfo(data));
          // dispatch(appActions.onSetAddress(data.wallet_address));
          // dispatch(appActions.onSetChatSelectedAccount(data.wallet_address));
        }),
      );
    }
  }, [token]);

  //render
  return (
    <>
      <Navbar className="left-0" />
      <Styled.Main className="w-full">
        <Outlet />
        <ProgressDialog />
        <Toast />
      </Styled.Main>
    </>
  );
};

export default Default;
