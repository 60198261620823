import { SLICE_NAME } from 'config/type';

export const CREATE_USER_ADDRESS = SLICE_NAME.USER + 'CREATE_USER_ADDRESS';
export const SEND_FRIEND_REQUEST = SLICE_NAME.USER + 'SEND_FRIEND_REQUEST';
export const REJECT_FRIEND_SUCCESS = SLICE_NAME.USER + 'REJECT_FRIEND_SUCCESS';
export const GET_FRIEND_REQUEST = SLICE_NAME.USER + 'GET_FRIEND_REQUEST';
export const ACCEPT_FRIEND_REQUEST = SLICE_NAME.USER + 'ACCEPT_FRIEND_REQUEST';
export const GET_FRIEND_LIST = SLICE_NAME.USER + 'GET_FRIEND_LIST';
export const UNFRIEND = SLICE_NAME.USER + 'UNFRIEND';
export const DELETE_FRIEND_REQUEST = SLICE_NAME.USER + 'DELETE_FRIEND_REQUEST';
export const GET_TOTAL_UNREAD = SLICE_NAME.USER + 'GET_TOTAL_UNREAD';
export const CREATE_OR_UPDATE_WELCOME_MESSAGES =
  SLICE_NAME.USER + 'CREATE_OR_UPDATE_WELCOME_MESSAGES';
export const GET_WELCOME_MESSAGES = SLICE_NAME.USER + 'GET_WELCOME_MESSAGES';
export const ADD_OFFICIAL_ACCOUNT = SLICE_NAME.USER + 'ADD_OFFICIAL_ACCOUNT';
export const UPDATE_USER_WALLET_BALANCES = SLICE_NAME.USER + 'UPDATE_USER_WALLET_BALANCES';
